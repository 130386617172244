import React, {Fragment} from "react";
import SezioneCopertina from "./CopertinaSezioni/SezioneCopertina";
import SezioneInformazioniAggiuntiveCopertina from "./CopertinaSezioni/SezioneInformazioniAggiuntiveCopertina";
import SezioneAlbumRidottoCopertina from "./CopertinaSezioni/SezioneAlbumRidottoCopertina";
import SezioneAlbumPocketCopertina from "./CopertinaSezioni/SezioneAlbumPocketCopertina";
import {StorageManagerStatus} from "../../Storage/StorageManager";
import {
    GenerateRiepilogoBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../Commons/RiepilogoDatoModel";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import SezioneGenerica from "../SezioneGenerica";
import {FotolibroModel} from "tici_commons";

export interface CopertinaProps {
    orientamentoAlbumPrincipale: string,
    formatoAlbumPrincipale: string,
    albumRidottoFlag: boolean,
    albumRidottoQuantita: number,
    albumPocketFlag: boolean,
    albumPocketQuantita: number
}

export default class Copertina extends SezioneGenerica<"copertina", CopertinaProps, {}> {
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] => {
        return GenerateRiepilogoBox(
            'Copertina',
            new SezioneCopertina(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
            new SezioneInformazioniAggiuntiveCopertina(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
            new SezioneAlbumRidottoCopertina(undefined, undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox,
            new SezioneAlbumPocketCopertina(undefined).generaRiepilogo(fotolibro, storage, modalitaConfiguratore) as RiepilogoInternalBox
        )
    }

    render() {
        return (
            <Fragment>
                <SezioneCopertina
                    SetParentState={this.props.SetParentState}
                    storage={this.props.storage}
                    data={this.props.data}
                    orientamentoAlbumPrincipale={this.props.orientamentoAlbumPrincipale}
                    formatoAlbumPrincipale={this.props.formatoAlbumPrincipale}/>
                <SezioneInformazioniAggiuntiveCopertina
                    SetParentState={this.props.SetParentState}
                    storage={this.props.storage}
                    data={this.props.data}
                    formatoAlbumPrincipale={this.props.formatoAlbumPrincipale}/>
                <IfContainer condition={this.props.albumRidottoFlag && this.props.albumRidottoQuantita > 0}>
                    <SezioneAlbumRidottoCopertina
                        SetParentState={this.props.SetParentState}
                        storage={this.props.storage}
                        data={this.props.data}
                        orientamentoAlbumPrincipale={this.props.orientamentoAlbumPrincipale}
                        formatoAlbumPrincipale={this.props.formatoAlbumPrincipale}/>
                </IfContainer>
                <IfContainer condition={this.props.albumPocketFlag && this.props.albumPocketQuantita > 0}>
                    <SezioneAlbumPocketCopertina
                        SetParentState={this.props.SetParentState}
                        storage={this.props.storage}
                        data={this.props.data}/>
                </IfContainer>
            </Fragment>
        );
    }
}
