//QG 30/06/2024 MD

import ConfiguraModelloTopLevel, {
    ConfiguraModelloTopLevelProps,
    ConfiguraModelloTopLevelState
} from "./ConfiguraModelloTopLevel";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import SezioneConfigurabile from "../../../../../Primary/GestioneModelli/SezioneConfigurabile";
import {Triple} from "tici_commons";
import {InputContainer, TripleLine} from "../ConfiguratoreModelliComponents";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import Button from "../../../../../Core/Buttons/Button";
import {CheckLine} from "../../../../../Widgets/Configuratore/CComponents";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import DefaultInput from "../../../../../Core/DefaultInput/DefaultInput";

export interface VistaSalvataModel{
    nomeVista: string,
    vistaNormale: Triple,
    vistaAlternativa: Triple
}

export interface ConfigurazioneVistaProps extends ConfiguraModelloTopLevelProps{
    modalitaAlternativaModello: boolean,
    sezioniConfigurabili: SezioneConfigurabile[]
}

export interface ConfigurazioneVistaState extends ConfiguraModelloTopLevelState{
    sezioneSelezionata: string,
    vistaAttuale: Triple,
    vistaAttualeAlternativa: Triple,
    primaVista: boolean,

    nomeVistaDefault: string,
    vistaDefaultSelezionata: string,
    listaVisteDefault: VistaSalvataModel[]
}

export default class ConfigurazioneVista extends ConfiguraModelloTopLevel<ConfigurazioneVistaProps, ConfigurazioneVistaState>{
    constructor(props: Readonly<ConfigurazioneVistaProps> | ConfigurazioneVistaProps) {
        super(props);
        this.state = {
            modelloConfigurabile: this.props.modelloConfigurabile,
            sezioneSelezionata: "",
            vistaAttuale: undefined,
            vistaAttualeAlternativa: undefined,
            primaVista: false,

            nomeVistaDefault: "",
            vistaDefaultSelezionata: "",
            listaVisteDefault: this.listaVisteDefault || []
        }
    }

    public componentDidUpdate(prevProps: Readonly<ConfigurazioneVistaProps>, prevState: Readonly<ConfigurazioneVistaState>, snapshot?: any) {
        if(prevProps.modalitaAlternativaModello !== this.props.modalitaAlternativaModello)
            this._aggiornaVista();
    }

    private _selezionaSezione(nomeSezione: string){
        const vista = this.props.modelloConfigurabile.configurazioneModello.visteSezioni.find(vista => vista.nomeSezione === nomeSezione);

        this.setState({
            sezioneSelezionata: nomeSezione,
            vistaAttuale: vista?.orientamento || {x: 0, y: 0, z: 0},
            vistaAttualeAlternativa: vista?.orientamentoAlternativo || {x: 0, y: 0, z: 0},
            primaVista: vista?.primaVista || false
        }, () => {
            this._aggiornaVista();
        });
    }

    private _impostaValoriVista(vista: Triple, modalitaAlternativa: boolean): Promise<void>{
        return new Promise(resolve => {
            if(modalitaAlternativa){
                this.setState(
                    {vistaAttualeAlternativa: vista},
                    () => {
                        this._aggiornaVista();
                        resolve();
                    }
                );
            }else{
                this.setState(
                    {vistaAttuale: vista},
                    () => {
                        this._aggiornaVista();
                        resolve();
                    }
                );
            }
        });
    }

    private _aggiornaVista(){
        if(this.props.modalitaAlternativaModello){
            const vista = this.state.vistaAttualeAlternativa || {x: 0,y: 0, z: 0};
            this.props.modelloConfigurabile.animationPivot.rotationVector.set(vista.x, vista.y, vista.z);
        }else{
            const vista = this.state.vistaAttuale || {x: 0,y: 0, z: 0};
            this.props.modelloConfigurabile.animationPivot.rotationVector.set(vista.x, vista.y, vista.z);
        }
        this.props.modelloConfigurabile.update(true);
    }

    private _applicaVista() {
        const nomeSezione = this.state.sezioneSelezionata;
        const viste = this.props.modelloConfigurabile.configurazioneModello.visteSezioni.filter(vista => vista.nomeSezione !== nomeSezione);
        if(this.state.primaVista)
            for(const vista of viste)
                vista.primaVista = false;
        viste.push({nomeSezione, primaVista: this.state.primaVista, orientamento: this.state.vistaAttuale, orientamentoAlternativo: this.state.vistaAttualeAlternativa});
        this.ImpostaValoreConfigurazioneModello('visteSezioni', viste);
        this.setState({sezioneSelezionata: '', vistaAttuale: undefined, vistaAttualeAlternativa: undefined, primaVista: false}, () => this._aggiornaVista());
    }

    private _pulisciVista(){
        const nomeSezione = this.state.sezioneSelezionata;
        this.setState({sezioneSelezionata: '', vistaAttuale: undefined, vistaAttualeAlternativa: undefined, primaVista: false}, () => this._aggiornaVista());
        const viste = this.props.modelloConfigurabile.configurazioneModello.visteSezioni.filter(vista => vista.nomeSezione !== nomeSezione);
        this.ImpostaValoreConfigurazioneModello('visteSezioni', viste);
    }

    private _salvaVistaDefault(){
        const nomeVista = this.state.nomeVistaDefault;
        const vistaNormale = this.state.vistaAttuale;
        const vistaAlternativa = this.state.vistaAttualeAlternativa;

        const nuovaVista: VistaSalvataModel = {
            nomeVista,
            vistaNormale,
            vistaAlternativa
        };

        const visteDefault = this.listaVisteDefault;
        visteDefault.push(nuovaVista);
        this.listaVisteDefault = visteDefault;
    }

    private _eliminaVistaDefault(){
        this.listaVisteDefault = this.listaVisteDefault.filter(vista => vista.nomeVista !== this.state.vistaDefaultSelezionata);
    }

    private async _selezionaVistaDefault(){
        const vistaSelezionata = this.listaVisteDefault.find(vista => vista.nomeVista === this.state.vistaDefaultSelezionata);
        if(vistaSelezionata){
            await this._impostaValoriVista(vistaSelezionata.vistaNormale, false);
            await this._impostaValoriVista(vistaSelezionata.vistaAlternativa, true);
        }
    }

    //region Beam

    public get listaSezioni(): string[]{
        return this.props.sezioniConfigurabili
            .filter(sezioneConfigurabile => !sezioneConfigurabile.configurazioneSezione.sezioneNonVisibileFlag)
            .map(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeReale);
    }

    public get listaVisteDefault(): VistaSalvataModel[]{
        let esito: VistaSalvataModel[] = [];

        const data = localStorage.getItem('ListaVisteDefault');
        if(data){
            esito = JSON.parse(data);
        }

        return esito;
    }

    public set listaVisteDefault(value: VistaSalvataModel[]){
        localStorage.setItem('ListaVisteDefault', JSON.stringify(value));
        this.setState({listaVisteDefault: value});
    }

    //endregion

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Configura vista"} size={"largeRelative"}>
                <InputContainer label={"Sezione"}>
                    <ElementSelect
                        label={"Seleziona"}
                        elements={this.listaSezioni.map(sezione => ({label: sezione}))}
                        value={this.state.sezioneSelezionata}
                        onChange={v => this._selezionaSezione(v)}/>
                </InputContainer>
                <IfContainer condition={!!this.state.sezioneSelezionata}>
                    <CheckLine
                        label={"Prima vista modello"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.primaVista}
                        onChange={v => this.setState({primaVista: v})}/>
                    <TripleLine
                        label={"Orientamento vista"}
                        xValue={this.state?.vistaAttuale?.x || 0}
                        yValue={this.state?.vistaAttuale?.y || 0}
                        zValue={this.state?.vistaAttuale?.z || 0}
                        onChangeValue={(x, y, z) => this._impostaValoriVista({x, y, z}, false)}
                        gradi={true}/>
                    <TripleLine
                        label={"Orientamento vista alternativa"}
                        xValue={this.state?.vistaAttualeAlternativa?.x || 0}
                        yValue={this.state?.vistaAttualeAlternativa?.y || 0}
                        zValue={this.state?.vistaAttualeAlternativa?.z || 0}
                        onChangeValue={(x, y, z) => this._impostaValoriVista({x, y, z}, true)}
                        gradi={true}/>
                    <CategorySectionLayout categoryLabel={"Viste di default"} size={"largeRelative"}>
                        <IfContainer condition={this.state.listaVisteDefault.length > 0}>
                            <ResponsiveLabel content={"Seleziona vista default"} type={"medium"} alignment={"left"}/>
                            <ElementSelect
                                label={"Seleziona"}
                                elements={this.state.listaVisteDefault.map(vista => ({label: vista.nomeVista}))}
                                value={this.state.vistaDefaultSelezionata}
                                onChange={vistaDefaultSelezionata => this.setState({vistaDefaultSelezionata})}/>
                            <IfContainer condition={!!this.state.vistaDefaultSelezionata}>
                                <Responsive2Col>
                                    <Button
                                        content={"Seleziona vista"}
                                        type={"medium"}
                                        buttonType={"full-normal"}
                                        onClick={() => this._selezionaVistaDefault()}/>
                                    <Button
                                        content={"Elimina vista"}
                                        type={"medium"}
                                        buttonType={"full-normal-negative"}
                                        onClick={() => this._eliminaVistaDefault()}/>
                                </Responsive2Col>
                            </IfContainer>
                        </IfContainer>
                        <ResponsiveLabel content={"Nome vista default"} type={"medium"} alignment={"left"}/>
                        <DefaultInput
                            type={"text"}
                            value={this.state.nomeVistaDefault}
                            onChange={nomeVistaDefault => this.setState({nomeVistaDefault})}/>
                        <Button
                            content={"Salva come vista di default"}
                            type={"medium"}
                            buttonType={'full-normal'}
                            onClick={() => this._salvaVistaDefault()}/>
                    </CategorySectionLayout>
                    <Responsive2Col>
                        <Button
                            content={"Salva vista"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            onClick={() => this._applicaVista()}/>
                        <Button
                            content={"Pulisci vista"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._pulisciVista()}/>
                    </Responsive2Col>
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
