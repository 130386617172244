import React from "react";
import {ConfigurazioneRenderizzatoreInterface} from "tici_commons";

export interface ConfigurazioneGestore3DBridgePayload {
    pathModello: string;
    nomeModello: string;
    nomeConfigurazioneJSON: string;
    configurazione: ConfigurazioneRenderizzatoreInterface;
}

export interface ConfigurazioneGestore3DBridgeState{
    timeStamp: number,
    payload: ConfigurazioneGestore3DBridgePayload,
    metaData: {[key: string]: any},
    hasData: () => boolean,
    setPayload: (payload: ConfigurazioneGestore3DBridgePayload) => void,
    setMetadata: (metadata: {[key: string]: any}) => void,
}

export const ConfigurazioneGestore3DBridgeContext = React.createContext<ConfigurazioneGestore3DBridgeState>(undefined);

export default class ConfigurazioneGestore3DBridge extends React.Component<{children: any}, ConfigurazioneGestore3DBridgeState>{
    constructor(props: Readonly<{ children: any }> | { children: any }) {
        super(props);
        this.state = {
            timeStamp: 0,
            payload: undefined,
            metaData: {},
            hasData: this._hasData.bind(this),
            setPayload: this._setPayload.bind(this),
            setMetadata: this._setMetadata.bind(this)
        }
    }

    private _hasData(): boolean{
        return Math.abs(Date.now() - this.state.timeStamp) < 300;
    }

    private _setPayload(payload: ConfigurazioneGestore3DBridgePayload) {
        if(payload)
            this.setState({payload: payload, timeStamp: Date.now()});
    }

    private _setMetadata(metadata: {[key: string]: any}){
        if(metadata)
            this.setState({metaData: metadata, timeStamp: Date.now()})
    }


    public render() {
        return (
            <ConfigurazioneGestore3DBridgeContext.Provider value={this.state}>
                {this.props.children}
            </ConfigurazioneGestore3DBridgeContext.Provider>
        );
    }
}
