import './CardAgente.scss';

import React, {Fragment} from "react";
import {AgenteContextResponseModel, AgenteResponseModel, FotografoBaseResponseModel} from "tici_commons";
import UserService from "../../Servicies/UserService";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import ContainerLayout from "../../Layout/ContainerLayout/ContainerLayout";
import ImageService from "../../Servicies/ImageService";
import AgenteService from "../../Servicies/AgenteService";
import Button from "../../Core/Buttons/Button";
import ElementSelect, {ItemElementInterface} from "../Selects/ElementSelect/ElementSelect";
import TopLevelComponentStorage from "../../Core/Arch/TopLevelComponentStorage";
import Pagination from "../Pagination/Pagination";

export interface CardAgenteProps{
    agente: AgenteResponseModel,
    listaFotografi: FotografoBaseResponseModel[],
    onDelete: () => void
}

export interface CardAgenteState{
    regioneSelezionata: string
    fotografoSelezionato: string,
    contestoAgente: AgenteContextResponseModel,
    isLoading: boolean
}

export default class CardAgente extends React.Component<CardAgenteProps, CardAgenteState>{
    constructor(props: Readonly<CardAgenteProps> | CardAgenteProps) {
        super(props);
        this.state = {
            regioneSelezionata: "",
            fotografoSelezionato: "",
            contestoAgente: undefined,
            isLoading: true
        }
    }

    public componentDidMount() {
        this._updateContestoAgente();
    }

    /**
     * Aggiorna il contesto dell'agente
     * @private
     */
    private async _updateContestoAgente(){
        this.setState({isLoading: true});
        const response = await AgenteService.AgenteContext(this.props.agente.idAgente);
        if(response)
            this.setState({contestoAgente: response, isLoading: false});
    }

    /**
     * Mappa la lista dei fotografi
     * @private
     */
    private _mappaListaFotografi(): ItemElementInterface[]{
        let esito: ItemElementInterface[] = [];

        if(this.props.listaFotografi && this.state.contestoAgente){
            const mappedFotografiContesto = this.state.contestoAgente.listaFotografi.map(fotografo => fotografo.idFotografo);

            esito = this.props.listaFotografi.filter(fotografo => fotografo.regioneAppartenenza === this.state.regioneSelezionata).filter(fotografo => !mappedFotografiContesto.includes(fotografo.idFotografo)).map(fotografo => (
                {
                    image: UserService.UserImageUrl(fotografo.email, fotografo.immagineProfilo),
                    label: `${fotografo.idFotografo}-${fotografo.nome} ${fotografo.cognome}`
                }
            ))
        }

        return esito;
    }

    /**
     * Mappa la lista delle regioni dei fotografi
     * @private
     */
    private _mappaListaRegioni(): ItemElementInterface[]{
        let esito: ItemElementInterface[] = [];

        if(this.props.listaFotografi)
            esito = Array.from(new Set(this.props.listaFotografi.filter(fotografo => fotografo.regioneAppartenenza).map(fotografo => fotografo.regioneAppartenenza))).map(regione => ({label: regione}));

        return esito;
    }

    /**
     * Aggiunge alla lista dei fotografi associati
     * @private
     */
    private async _aggiungiFotografo(){
        if(this.state.fotografoSelezionato.length !== 0){
            const id = parseInt(this.state.fotografoSelezionato.split('-')[0]);
            if(!isNaN(id)){
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Inserimento", "Associazione del fotografo all'agente");
                const esito = await AgenteService.AssociaFotografi(this.props.agente.idAgente, [...this.state.contestoAgente.listaFotografi.map(fotografo => fotografo.idFotografo), id]);
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                    esito ? "Inserimento avvenuto" : "Inserimento non avvenuto",
                    esito ? "L'inserimento del fotografo é avvenuto correttamente" : "Non é stato possibile inserire il fotografo"
                );

                if(esito) {
                    this._updateContestoAgente();
                    this.setState({fotografoSelezionato: "", regioneSelezionata: ""});
                }
            }else{
                TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow("Errore", "Non é possibile inserire il fotografo");
            }
        }
    }

    /**
     * Rimuove un fotografo associato
     * @param idFotografo Id del fotografo da rimuovere
     * @private
     */
    private async _rimuoviFotografo(idFotografo: number){
        const nuovaListaFotografi = this.state.contestoAgente.listaFotografi.filter(fotografo => fotografo.idFotografo !== idFotografo).map(fotografo => fotografo.idFotografo);

        TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Rimozione", "Rimozione del fotografo dall'agente");
        const esito = await AgenteService.AssociaFotografi(this.props.agente.idAgente, nuovaListaFotografi);
        TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            esito ? "Rimozione avvenuta" : "Rimozione non avvenuta",
            esito ? "La rimozione del fotografo é avvenuta correttamente" : "Non é stato possibile rimuovere il fotografo"
        );

        if(esito)
            this._updateContestoAgente();
    }

    public render(){
        return (
            <Fragment>{
                this.props.agente &&
                <div className={"CardAgenteContainer"}>
                    <SectionLayout size={"largeRelative"} lightBackground={true} addPadding={true}>
                        <span className={"CardAgenteProfilo"}>
                            <img
                                alt={"Immagine profilo"}
                                className={"CardAgenteImmagineProfilo"}
                                src={UserService.UserImageUrl(this.props.agente.email, this.props.agente.immagineProfilo)}/>
                            <ContainerLayout size={"largeRelative"} gap={false}>
                                <ResponsiveLabel content={this.props.agente.nome} type={"medium"} alignment={"left"} labelType={"cardAgenteInfo"}/>
                                <ResponsiveLabel content={this.props.agente.cognome} type={"medium"} alignment={"left"} labelType={"cardAgenteInfo"}/>
                                <ResponsiveLabel content={"agente"} type={"xsmall"} alignment={"left"} labelType={"cardAgenteInfo"}/>
                            </ContainerLayout>
                            <div
                                className={"CardAgentePulsanteProfilo"}
                                style={{backgroundImage: `url(${ImageService.getImage('trashIcon')})`}}
                                onClick={() => this.props.onDelete && this.props.onDelete()}/>
                        </span>
                        <ResponsiveLabel content={`Telefono: ${this.props.agente.numeroTelefono}`} type={"medium"} alignment={"left"} labelType={"cardAgenteInfo"}/>
                        <ResponsiveLabel content={`Codice fiscale: ${this.props.agente.codiceFiscale}`} type={"medium"} alignment={"left"} labelType={"cardAgenteInfo"}/>
                    </SectionLayout>
                    <div className={"CardAgenteInformazioniFotografi"}>
                        <SectionLayout size={"largeRelative"} lightBackground={true} addPadding={true}>
                            <span className={"RigaInserimento"}>
                                <ElementSelect
                                    label={"Locazione"}
                                    elements={this._mappaListaRegioni()}
                                    value={this.state.regioneSelezionata}
                                    onChange={v => this.setState({regioneSelezionata: v})}/>
                                <ElementSelect
                                    label={"Seleziona fotografo"}
                                    elements={this._mappaListaFotografi()}
                                    value={this.state.fotografoSelezionato}
                                    onChange={v => this.setState({fotografoSelezionato: v})}
                                    disabled={this.state.regioneSelezionata.length === 0}
                                    autocompleteMode={true}/>
                                <Button
                                    content={"Aggiungi"}
                                    type={"medium"}
                                    disabled={this.state.fotografoSelezionato.length === 0}
                                    onClick={() => this._aggiungiFotografo()}/>
                            </span>
                            {
                                this.state.contestoAgente && Pagination.OverflowStructure(
                                    <Pagination
                                        elements={this.state.contestoAgente.listaFotografi}
                                        paginationSize={10}>{
                                        props => (
                                            <div className={"ContainerFotografi"}>
                                                {
                                                    props.paginatedElements.map(fotografo => (
                                                        <span
                                                            className={"CardAgenteRigaFotografo"}
                                                            key={`Fotografo-${fotografo.idFotografo}`}>
                                                            <span
                                                                className={"RigaFotografoImage"}
                                                                style={{backgroundImage: `url(${UserService.UserImageUrl(fotografo.email, fotografo.immagineProfilo)})`}}/>
                                                            <ResponsiveLabel
                                                                content={`${fotografo.idFotografo}) ${fotografo.nome} ${fotografo.cognome}`}
                                                                type={"medium"}
                                                                alignment={"center"}
                                                                labelType={"whiteLabel"}/>
                                                            <span
                                                                className={"RigaFotografoDeleteButton"}
                                                                style={{backgroundImage: `url(${ImageService.getImage('xIcon')})`}}
                                                                onClick={() => this._rimuoviFotografo(fotografo.idFotografo)}/>
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }</Pagination>
                                )
                            }
                        </SectionLayout>
                    </div>
                </div>
            }</Fragment>
        )
    }
}
