import './SelettoreUv.scss';
import React, {Fragment} from "react";
import {
    ImageVisualizer,
    SelectLineProps,
    TextLine
} from "../../Widgets/Configuratore/CComponents";
import UV from "../../DatabaseData/UV";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import Loader from "../../Core/Loader/Loader";
import VerticalSpace from "../../Layout/VerticalSpace/VerticalSpace";
import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import {imageTypeSupported} from "../../Utils/CommonFunctions";

export interface SelettoreUvProps extends Omit<SelectLineProps, 'elements' | 'onChange' | 'value'>{
    currentImageName?: string,
    currentImage?: StorageData,
    onChange?: (imageName: string, file?: File, base64Encoding?: string) => void,
    addPaddingAndBorder?: boolean
}

type SelettoreUvMode = 'default' | 'custom';

export interface SelettoreUvState{
    isLoading: boolean
}

export default class SelettoreUv extends React.Component<SelettoreUvProps, SelettoreUvState>{
    constructor(props: Readonly<SelettoreUvProps> | SelettoreUvProps) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    private _sezioneLoader(){
        return (
            <Fragment>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
                <Loader/>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={30}/>
            </Fragment>
        );
    }

    /**
     * Effettua il controllo sul current name per determinare se il select degli uv contiene il nome passato
     * @param name Nome da ricercare all'interno della lista degli uv
     * @private
     */
    private _hasUvName(name: string): boolean{
        let esito = false;

        if(this.props.currentImageName)
            esito = UV.GetUv().filter(uv => uv[1].toUpperCase() === name.toUpperCase()).length === 1;

        return esito;
    }

    /**
     * Restituisce lo stato corrente del selettore
     * @private
     */
    private _currentSelectorMode(): SelettoreUvMode{
        let esito: SelettoreUvMode = 'default';

        if(this.props.currentImageName){
            if(this.props.currentImageName === '' || this._hasUvName(this.props.currentImageName)){
                esito = 'default';
            }else esito = 'custom';
        }

        return esito;
    }


    /**
     * Effettua l'eliminazione del dato salvato
     * @private
     */
    private _deleteData(){
        this.props.onChange && this.props.onChange("", undefined, "");
    }

    public render() {
        return (
            <SectionLayout size={'largeRelative'} showBorder={this.props.addPaddingAndBorder} addPadding={this.props.addPaddingAndBorder}>
                <IfContainer condition={!this.state.isLoading} elseComponent={this._sezioneLoader()}>
                    <IfContainer condition={!!this.props.currentImage}>
                        <ImageVisualizer
                            storageData={this.props.currentImage}
                            onDelete={this._deleteData.bind(this)}/>
                    </IfContainer>
                </IfContainer>
                <IfContainer condition={!this.state.isLoading}>
                    <TextLine
                        label={'Carica uv personalizzato'}
                        type={'file'}
                        value={this._currentSelectorMode() === 'custom' ? this.props.currentImageName : ''}
                        onFileUpload={(fileName, fileType, file, base64Encoding) => {
                            if(imageTypeSupported(fileType))
                                this.props.onChange && this.props.onChange(fileName, file, base64Encoding);
                        }}/>
                </IfContainer>
            </SectionLayout>
        );
    }
}
