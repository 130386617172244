//QG 30/06/2024 MD

import Pivot from "./Pivot";
import {Group, Mesh, Object3D, Vector3} from "three";

export default class CoreObject extends Group{
    private _movimentoPivot: Pivot;
    private _animationPivot: Pivot;
    private _centerRotationPivot: Pivot;

    private _modailtaSupportoVisivo= false;
    private _modalitaVisualizzazioneAlternativa= false;

    constructor(objectName: string) {
        super();
        this.name = objectName;
        this._movimentoPivot = new Pivot();
        this._animationPivot = new Pivot();
        this._centerRotationPivot = new Pivot();
        this._centerRotationPivot.visualizzaMeshPivot = false;

        this._movimentoPivot.name = `${objectName}-movimento-pivot`;
        this._animationPivot.name = `${objectName}-animation-pivot`;
        this._centerRotationPivot.name = `${objectName}-center-rotation-pivot`

        this._movimentoPivot.mainObject = this._animationPivot;
        this._animationPivot.mainObject = this._centerRotationPivot;
        this.add(this._movimentoPivot);
    }

    public update() {
        this._movimentoPivot.update();
        this._animationPivot.update();
        this._spostaCentroRotationPivot();
        this.updateMatrix();
    }

    private _spostaCentroRotationPivot(){
        if(this._centerRotationPivot.mainObject && this._centerRotationPivot.mainObject.geometry){
            const mesh = this._centerRotationPivot.mainObject as Mesh;
            mesh.geometry.computeBoundingBox();
            const center = mesh.geometry.boundingBox.getCenter(new Vector3());
            this._centerRotationPivot.objectOffset.set(
                -center.x,
                -center.y,
                -center.z
            );
            this._centerRotationPivot.positionVector.set(
                center.x,
                center.y,
                center.z
            );
        }
        this._centerRotationPivot.update();
    }

    //region Handlers

    public resetStatoAnimazione(updateElemento = true){
        this._animationPivot.rotationVector.set(0, 0, 0);
        this._animationPivot.positionVector.set(0, 0, 0);
        this._animationPivot.objectOffset.set(0, 0, 0);
        updateElemento && this.update();
    }

    public resetStatoVisualizzazioneMeshPivot(){
        this._movimentoPivot.visualizzaMeshPivot = false;
        this._animationPivot.visualizzaMeshPivot = false;
    }

    //endregion

    //region Utils

    private _stampaNodo(object: Object3D, data: { [key: string]: unknown } = {}){
        if(object.children.length === 0)
            data[object.name] = 'vuoto';
        else{
            const newData = object.name === 'ContenitorePivot' ? data : {};
            for(const child of object.children){
                this._stampaNodo(child, newData);
            }
            if(object.name !== 'ContenitorePivot')
                data[object.name] = newData;
        }
    }

    public stampaAlberatura(){
        const data = {}
        this._stampaNodo(this, data);
        console.log(JSON.stringify(data, undefined, 2));
        console.log(data);
    }

    //endregion

    //region Beam

    public get animationPivot(): Pivot {
        return this._animationPivot;
    }

    public get movimentoPivot(): Pivot {
        return this._movimentoPivot;
    }

    public get centerRotationPivot(): Pivot {
        return this._centerRotationPivot;
    }

    public get modalitaSupportoVisivo(): boolean {
        return this._modailtaSupportoVisivo;
    }

    public set modalitaSupportoVisivo(value: boolean) {
        this._modailtaSupportoVisivo = value;
        this.update();
    }

    public get modalitaVisualizzazioneAlternativa(): boolean {
        return this._modalitaVisualizzazioneAlternativa;
    }

    public set modalitaVisualizzazioneAlternativa(value: boolean) {
        this._modalitaVisualizzazioneAlternativa = value;
        this.update();
    }

    //endregion
}
