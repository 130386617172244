import { StorageData } from "../Storage/StorageManager"
import {orderFromImageName} from "../../../../Utils/CommonFunctions";
import { NomeSezioneInformazioneType } from "tici_commons";
import {GenerateRiepilogoData, RiepilogoData} from "./RiepilogoDatoModel";
import SezioneConfigurabile from "../../../../Primary/GestioneModelli/SezioneConfigurabile";

export const SortFogliWithStorageData = (a: StorageData | StorageData[], b: StorageData | StorageData[]): number => {
    const va = orderFromImageName(Array.isArray(a) ? a[0].name : a.name);
    const vb = orderFromImageName(Array.isArray(b) ? b[0].name : b.name);
    return va - vb;
}

export const SortFogliWithCartaExtra = (a: [string, string], b: [string, string]): number => {
    const va = orderFromImageName(a[0]);
    const vb = orderFromImageName(b[0]);
    return va - vb;
}

/**
 * Controlla che il formato passato possa utilizzare il formato principale
 * @param formatoAlbumPrincipale Formato dell'album principale
 * @private
 */
export const SupportaDorsoDritto = (formatoAlbumPrincipale: string): boolean => {
    let esito = false;

    if(formatoAlbumPrincipale){
        const splittedValue = formatoAlbumPrincipale.split('x');
        if(splittedValue.length === 2){
            const b = parseInt(splittedValue[0]);
            const h = parseInt(splittedValue[1]);
            esito = b + h >= 70; //devono essere minimo 35x35
        }
    }

    return esito;
}

export const GeneraListaSezioniObbligatorieCompilate = (sezioni: NomeSezioneInformazioneType[], sezioniObbligatorie: string[]): NomeSezioneInformazioneType[] => {
    let esito: NomeSezioneInformazioneType[] = [];

    if(sezioni && sezioniObbligatorie){
        esito = sezioni
            .filter(sezione => sezioniObbligatorie.includes(sezione[0]))
            .filter(sezione => {
                let esito = false;
                if(sezione[1].tipoSezione === 'sezioneTrasparenza'){
                    esito = !!sezione[1].informazioneImmagine || !!sezione[1].informazioneUv;
                }else if(sezione[1].tipoSezione === 'sezioneSingolaImmagine'){
                    esito = !!sezione[1].informazioneUv;
                }else{
                    esito = !!sezione[1].informazione || !!sezione[1].informazioneImmagine
                }
                return esito;
            });
    }

    return esito;
}

export const GeneraMessaggioSezioniNonCompilate = (sezioniConfigurabili: SezioneConfigurabile[], datiSezioni: NomeSezioneInformazioneType[]): string => {
    let esito = "";

    if(sezioniConfigurabili && datiSezioni){
        const sezioniDaCompilare = sezioniConfigurabili
            .filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.sezioneObbligatoriaFlag)
            .filter(sezioneConfigurabile => {
                let esito = true;

                const datoSezione = datiSezioni.find(sezione => sezione[0] === sezioneConfigurabile.configurazioneSezione.nomeReale);
                if(datoSezione){
                    if(datoSezione[1].tipoSezione === 'sezioneTrasparenza'){
                        esito = !datoSezione[1].informazioneImmagine && !datoSezione[1].informazioneUv;
                    }else if(datoSezione[1].tipoSezione === 'sezioneSingolaImmagine'){
                        esito = !datoSezione[1].informazioneUv;
                    }else{
                        esito = !datoSezione[1].informazione && !datoSezione[1].informazioneImmagine
                    }
                }

                return esito;
            })
            .map(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeVisualizzato);

        esito = sezioniDaCompilare.length > 0 ? `Le seguenti sezioni risultano non compilate: ${sezioniDaCompilare.join(',')}` : undefined;
    }

    return esito
}

export const GeneraRiepilogoSezioni = (sezioni: NomeSezioneInformazioneType[]): RiepilogoData[] => {
    return sezioni.map(
        sezione => {
            let esito = undefined;

            const datiSezione = sezione[1];
            if(datiSezione.informazione || datiSezione.informazioneImmagine){
                let htmlSezione = `
                Sezione: ${datiSezione.nomeVisualizzatoSezione}<br/>
                <ul>
            `;

                if(datiSezione.informazione){
                    const mapperLabel: {[key: string]: string} = {
                        'sezioneMateriale': 'Materiale',
                        'sezioneLegno': 'Legno',
                        'sezionePlex': 'Plex',
                        'sezioneColore': 'Colore'
                    }
                    htmlSezione += `<li style="margin-left: 20px">${mapperLabel[datiSezione.tipoSezione]}: ${datiSezione.informazione.split('-')[0]}</li>`;
                }

                if(datiSezione.informazioneImmagine){
                    const infImmagine = datiSezione.informazioneImmagine;
                    htmlSezione += `
                    <li style="margin-left: 20px">
                        Immagine ${infImmagine.tipoImmagine}: ${infImmagine.nomeImmagine} <br/>
                        <span class="hidden-riepilogo-utente">
                            - Posizionamento: (X: ${Math.round(infImmagine.posizioneXImmagine)}%, Y: ${Math.round(infImmagine.posizioneYImmagine)}%, Scala: ${infImmagine.scalaImmagine * 100}%)
                        </span>
                    </li>
                `;
                }

                if(datiSezione.informazioneUv){
                    const infUv = datiSezione.informazioneUv;
                    htmlSezione += `
                    <li style="margin-left: 20px">
                        UV: ${infUv.nomeUv} <br/>
                        <span class="hidden-riepilogo-utente">
                            - Posizionamento: (X: ${Math.round(infUv.posizioneXUv)}%, Y: ${Math.round(infUv.posizioneYUv)}%, Rotazione: ${infUv.rotazioneZUv}°, Scala: ${infUv.scalaUv * 100}%)
                        </span>
                    </li>
                `;
                }

                htmlSezione += `</ul>`

                esito = GenerateRiepilogoData(htmlSezione);
            }

            return esito;
        }
    )
}
