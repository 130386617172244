//QG 30/06/2024 MD

import {Box3, Object3D, Vector2} from "three";

export const fromMousePositionToNormalizedCoordinates = (mouseX: number, mouseY: number, screenWidth: number, screenHeight: number): Vector2 => {
    return new Vector2(
        (mouseX / screenWidth) * 2 - 1,
        1 - (mouseY / screenHeight) * 2
    )
}

export const toRadians = (degree: number): number => {
    return (degree / 180) * Math.PI;
}

export const calcolaBounding = (object: Object3D): Box3 => {
    const boxEsito = new Box3();

    if(object.children.length === 0 && !object.userData.escludiBounding){
        boxEsito.setFromObject(object);
    }else{
        for(const children of object.children){
            boxEsito.union(calcolaBounding(children));
        }
    }

    return boxEsito;
}

export const traverseObject = (object: Object3D, callback: (object: Object3D) => void, index = 0) => {
    if(object){
        callback(object);
        for(let i = 0; i < object.children.length; i++){
            const taget = object.children[i];
            traverseObject(taget, callback, index++);
        }
    }
}
