//QG 30/06/2024 MD

import React, {Fragment} from "react";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {CheckLine} from "../../../../../Widgets/Configuratore/CComponents";
import ConfiguraSezioneTopLevel from "./ConfiguraSezioneTopLevel";
import {InputContainer} from "../ConfiguratoreModelliComponents";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";

export default class ConfigurazioneRidimensionamento extends ConfiguraSezioneTopLevel{
    public render() {
        return (
            <Fragment>
                <CategorySectionLayout categoryLabel={"Configurazione dimensionamento"} size={"largeRelative"}>
                    <InputContainer label={"Sezione"}>
                        <ElementSelect
                            label={"Seleziona"}
                            elements={this.ListaSezioniConfigurabili()}
                            value={this.state.sezioneSelezionata}
                            onChange={v => this.SelezionaSezioneConfigurabile(v)}/>
                    </InputContainer>
                    <IfContainer condition={!!this.state.sezioneSelezionata}>
                        <CheckLine
                            label={"Sezione target per ridimensionamento"}
                            checkType={"checkbox"}
                            reduce={true}
                            checked={this.state?.sezioneConfigurabileSelezionata?.configurazioneSezione?.targetRidimensione || false}
                            onChange={v => this.ImpostaValoreConfigurazioneSezione('targetRidimensione', v)}/>
                        <CheckLine
                            label={"Sezione ridimensionabile"}
                            checkType={"checkbox"}
                            reduce={true}
                            checked={this.state?.sezioneConfigurabileSelezionata?.configurazioneSezione?.sezioneRidimensionabile || false}
                            onChange={v => {
                                this.ImpostaValoreConfigurazioneSezione('sezioneRidimensionabile', v);
                                !v && this.ImpostaValoreConfigurazioneSezione('targetRidimensione', v);
                            }}/>
                    </IfContainer>
                </CategorySectionLayout>
            </Fragment>
        );
    }
}
