//QG 30/06/2024 MD

import React, {Fragment} from "react";
import './PreviewAlberaturaModello.scss';
import SezioneConfigurabile from "../SezioneConfigurabile";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";

export interface PreviewAlberaturaModelloProps{
    sezioniConfigurabili: SezioneConfigurabile[],
    updateRenderView: () => void
}

export interface PreviewAlberaturaModelloState{
    startDragSection: string
}

export default class PreviewAlberaturaModello extends React.Component<PreviewAlberaturaModelloProps, PreviewAlberaturaModelloState>{
    constructor(props: Readonly<PreviewAlberaturaModelloProps> | PreviewAlberaturaModelloProps) {
        super(props);
        this.state = {
            startDragSection: ""
        }
    }

    private _rimuoviSezioneContenutaDaSezioni(){
        if(this.props.sezioniConfigurabili){
            this.props.sezioniConfigurabili.forEach(sezioneConfigurabile => {
                if(sezioneConfigurabile.configurazioneSezione.sezioniContenute.includes(this.state.startDragSection)){
                    sezioneConfigurabile.configurazioneSezione.sezioniContenute = sezioneConfigurabile.configurazioneSezione.sezioniContenute.filter(nomeSezione => nomeSezione !== this.state.startDragSection);
                }
            })
        }
    }

    private _isSezioneContenuta(nomeSezione: string): boolean{
        let esito = false;
        if(this.props.sezioniConfigurabili){
            this.props.sezioniConfigurabili.forEach(sezioneConfigurabile => {
                if(sezioneConfigurabile.configurazioneSezione.sezioniContenute.includes(nomeSezione))
                    esito = true;
            })
        }
        return esito
    }

    private _SezioneAlberaturaSezione(sezione: SezioneConfigurabile){
        return (
            !sezione.configurazioneSezione.sezioneNonVisibileFlag &&
            <Fragment>
                <li
                    draggable={true}
                    onDrop={() => {
                        if(this.state.startDragSection !== sezione.configurazioneSezione.nomeReale){
                            if(!sezione.configurazioneSezione.sezioniContenute.includes(this.state.startDragSection)){
                                this._rimuoviSezioneContenutaDaSezioni();
                                sezione.configurazioneSezione.sezioniContenute.push(this.state.startDragSection);
                                this.setState({
                                    startDragSection: ""
                                }, () => this.props.updateRenderView && this.props.updateRenderView())
                            }
                        }
                    }}
                    onDragStart={() => this.setState({startDragSection: sezione.configurazioneSezione.nomeReale})}
                    onDragOver={ev => ev.preventDefault()}
                    className={"draggable"}
                >
                    <ResponsiveLabel content={`${sezione.configurazioneSezione.nomeVisualizzato}`} type={"medium"} uppercase={true} bold={true} alignment={"left"}/>
                </li>
                {
                    sezione.configurazioneSezione.sezioniContenute.length > 0 &&
                    <li>
                        <ul>{
                            sezione.configurazioneSezione.sezioniContenute.map((nomeSezione, index) => (
                                <Fragment key={`SezioneConfigurabile-${index}`}>{
                                    this._SezioneAlberaturaSezione(this.props.sezioniConfigurabili.find(sezione => sezione.configurazioneSezione.nomeReale === nomeSezione))
                                }</Fragment>
                            ))
                        }</ul>
                    </li>
                }
            </Fragment>
        )
    }

    public render() {
        return (
            this.props.sezioniConfigurabili &&
            <ul className={"PreviewAlberaturaModelloContainer"}>
                <li
                    className={'root'}
                    onDragOver={ev => ev.preventDefault()}
                    onDrop={() => {
                        this._rimuoviSezioneContenutaDaSezioni();
                        this.setState({startDragSection: ""}, () => this.props.updateRenderView && this.props.updateRenderView());
                    }}>
                    <ResponsiveLabel content={"Root"} type={"xmedium"} uppercase={true} bold={true} alignment={"left"}/>
                </li>
                <li>
                    <ul>{
                        this.props.sezioniConfigurabili.map((sezioneConfigurabile, index) => (
                            !this._isSezioneContenuta(sezioneConfigurabile.configurazioneSezione.nomeReale) &&
                            <Fragment key={`SezioneConfigurabile-${index}`}>
                                {this._SezioneAlberaturaSezione(sezioneConfigurabile)}
                            </Fragment>
                        ))
                    }</ul>
                </li>
            </ul>
        );
    }
}
