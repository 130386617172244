//QG 30/06/2024 MD

import React, {RefObject} from "react";
import './RenderizzatoreModelli.scss';
import {
    AxesHelper,
    DirectionalLight, DoubleSide,
    Group, Mesh, MeshBasicMaterial, Object3D, OrthographicCamera,
    PCFSoftShadowMap,
    PerspectiveCamera, PlaneGeometry, Raycaster,
    Scene, Vector2,
    Vector3,
    WebGLRenderer
} from "three";
import {EventListenerInterface} from "../ElementoConfigurabile";
import {fromMousePositionToNormalizedCoordinates, toRadians} from "../Utils";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {OutlinePass} from "three/examples/jsm/postprocessing/OutlinePass";
import {SMAAPass} from "three/examples/jsm/postprocessing/SMAAPass";
import RenderizzatoreComandiContainer from "./RenderizzatoreComandiContainer";
import AutoRefreshComponent from "../../../Core/Arch/AutoRefreshComponent";


interface GestioneCanvasProps{
    larghezzaCanvas: string,
    aspectRatioCanvas: string,
    onGenerateImage?: (imageData: string) => void
}

interface GestioneRenderizzazioneProps{
    modalitaSupportoVisivo?: boolean,
}

export interface RenderizzatoreModelliState{
    hover: boolean,
    cameraProspettica: boolean
}

export interface RenderizzatoreModelliProps extends GestioneCanvasProps, GestioneRenderizzazioneProps, EventListenerInterface{
    gruppoRendering: Group
}

export default class RenderizzatoreModelli extends AutoRefreshComponent<RenderizzatoreModelliProps, RenderizzatoreModelliState>{
    private _canvasRef: RefObject<HTMLCanvasElement> = React.createRef();

    //THREE.js renderig objects
    private _statoRendering = true;
    private _renderer: WebGLRenderer;
    private _composer: EffectComposer;
    private _scena: Scene;
    private _renderPass: RenderPass;

    private _camera: PerspectiveCamera | OrthographicCamera;
    private _perspectiveCamera: PerspectiveCamera;
    private _ortographicCamera: OrthographicCamera;

    private _cameraLight: DirectionalLight;
    private _containerGenerico: Group;
    private _outlinePassHover: OutlinePass;
    private _outlinePassSelection: OutlinePass;
    private _smaaPass: SMAAPass;

    //Rotazione e distanza camera
    private _mousePremuto = false;
    private _movimentoCamera = false;
    private _yaw = 0;
    private _pitch = 0;
    private _distanzaCamera = 100;
    private _rotazioneOrtogonale = 0;
    private _posizioneTopOrtogonale = false;

    //Modalita supporto visivo
    private _axesHelper: AxesHelper;
    private _grigliaPavimento: Group;


    //Posizione mouse
    private _clientX = 0;
    private _clientY = 0;

    //Update generale in millisicondi
    private _msDelayUpdate = 100;

    //Update della fisica
    private _tickFisicoMassimo = 4; //Massimo tick prima del lancio di un evento fisico, la fisica si aggiorna ogni tot ms
    private _tickFisicoAttuale = 0;

    constructor(props: Readonly<RenderizzatoreModelliProps> | RenderizzatoreModelliProps) {
        super(props);
        this.Delay = 1000;
        this.state = {
            hover: false,
            cameraProspettica: true
        }
    }

    public cycleGenerazioneImmagineCorrente = () => {
        if(this.props.onGenerateImage){
            if(this._canvasRef.current)
                this.props.onGenerateImage(this._canvasRef.current.toDataURL());
        }
    }

    /**
     * Controlla lo stato della cnavas
     * @param callbackOnCheck Callback da chiamare alla verifica della presenza della canvas
     * @private
     */
    private _canvasChecker(callbackOnCheck: () => void){
        const interval = window.setInterval(() => {
            if(this._canvasRef.current){
                window.clearInterval(interval);
                callbackOnCheck && callbackOnCheck();
            }
        });
    }

    public componentDidMount() {
        this._canvasChecker(() => {
            this._setupAmbienteRendering();
            this._updateMsFunction();
            this._updateFunction(1);
            this._canvasRef.current.addEventListener('wheel', ev => {
                ev.preventDefault();
                ev.stopPropagation();
                this._aggiornaDistanzaCamera(ev.deltaY < 0);
            }, {passive: false})
        })
    }

    public componentDidUpdate(prevProps: Readonly<RenderizzatoreModelliProps>) {
        if(this.props.modalitaSupportoVisivo !== prevProps.modalitaSupportoVisivo){
            if(this.props.modalitaSupportoVisivo) {
                this._containerGenerico.add(this._axesHelper);
                this._containerGenerico.add(this._grigliaPavimento);
            }else{
                this._containerGenerico.remove(this._axesHelper);
                this._containerGenerico.remove(this._grigliaPavimento);
            }
        }
    }

    public componentWillUnmount() {
        this._statoRendering = false;
    }

    /**
     * Effettua il setup dell'ambiente di rendering
     * @private
     */
    private _setupAmbienteRendering(){
        //region Setup-Renderer
        this._renderer =new WebGLRenderer({
            canvas: this._canvasRef.current,
            alpha: true,
            antialias: true,
            depth: true,
            stencil: false,
            preserveDrawingBuffer: true,
        });
        this._renderer.shadowMap.enabled = true;
        this._renderer.shadowMap.type = PCFSoftShadowMap;

        this._composer = new EffectComposer(this._renderer);
        //endregion

        //region Setup-Scena
        this._scena = new Scene();
        this._perspectiveCamera = new PerspectiveCamera(90);
        this._perspectiveCamera.position.z = 100;
        this._perspectiveCamera.lookAt(0, 0, 0);

        this._ortographicCamera = new OrthographicCamera(-100, 100, 100, -100);
        this._ortographicCamera.position.z = 100;
        this._ortographicCamera.lookAt(0, 0, 0);

        this._camera = this.state.cameraProspettica ? this._perspectiveCamera : this._ortographicCamera;
        this._cameraLight = new DirectionalLight(0xffffff, 2);
        this._cameraLight.castShadow = true;

        const topLight = new DirectionalLight(0xdddddd, 1.5);
        topLight.position.y = 200;
        topLight.lookAt(new Vector3(0, 0, 0));

        const bottomLight = new DirectionalLight(0xdddddd, 1.5);
        bottomLight.position.y = -200;
        bottomLight.lookAt(new Vector3(0, 0, 0));

        const leftLight = new DirectionalLight(0xdddddd, 1.5);
        leftLight.position.y = 50;
        leftLight.position.x = -200;
        leftLight.lookAt(new Vector3(0, 0, 0));

        const rightLight = new DirectionalLight(0xdddddd, 1.5);
        rightLight.position.y = 50;
        rightLight.position.x = 200;
        rightLight.lookAt(new Vector3(0, 0, 0));

        this._containerGenerico = new Group();
        this._containerGenerico.position.set(0, 0, 0);
        this._containerGenerico.add(this.props.gruppoRendering);

        this._scena.add(
            this._camera,
            this._cameraLight,
            topLight,
            bottomLight,
            leftLight,
            rightLight,
            this._containerGenerico
        )

        this._renderPass = new RenderPass(this._scena, this._camera);
        this._composer.addPass(this._renderPass);
        //endregion

        //region Outline-Pass
        this._outlinePassHover = this._setupOutlinePass('#ff0000');
        this._outlinePassHover.renderToScreen = true;
        this._outlinePassSelection = this._setupOutlinePass('#00ff00');
        this._composer.addPass(this._outlinePassHover);
        this._composer.addPass(this._outlinePassSelection);
        //endregion

        //region SMAA
        this._smaaPass = new SMAAPass(2000, 2000);
        this._composer.addPass(this._smaaPass);
        //endregion

        //region Setup-AiutiVisivi
        this._axesHelper = new AxesHelper(300000);
        this._grigliaPavimento = this._creaGrigliaPavimento(1000, 20);
        if(this.props.modalitaSupportoVisivo){
            this._containerGenerico.add(this._axesHelper);
            this._containerGenerico.add(this._grigliaPavimento);
        }
        //endregin
    }

    /**
     * Effettua il setup dell'outlinePass
     * @param color
     * @private
     */
    private _setupOutlinePass(color: string): OutlinePass{
        let outlinePass = new OutlinePass(new Vector2(), this._scena, this._camera);
        outlinePass.edgeStrength = 10;                // Intensità del bordo
        outlinePass.edgeGlow = 0;                     // Luminosità del bordo
        outlinePass.edgeThickness = .05;              // Spessore del bordo
        outlinePass.pulsePeriod = 0;                  // Periodo dell'effetto pulsante
        outlinePass.visibleEdgeColor.set(color);      // Colore del bordo visibile
        outlinePass.hiddenEdgeColor.set(color);       // Colore del bordo nascosto
        return outlinePass;
    }

    /**
     * Genera la griglia di visualizzazione in modalità supporto visivo
     * @param dimensioneGriglia Dimensione della griglia
     * @param dimensioneSezioneGriglia Dimensione della singola sezione della griglia
     * @private
     */
    private _creaGrigliaPavimento(dimensioneGriglia: number, dimensioneSezioneGriglia: number): Group{
        let gruppo = new Group();
        gruppo.position.set(0, -.1, 0);

        let size = Math.round(dimensioneGriglia / dimensioneSezioneGriglia);
        if(size % 2 !== 0)
            size++;

        let startX = -(size * dimensioneSezioneGriglia * 0.5);
        let startY = -(size * dimensioneSezioneGriglia * 0.5);

        for(let i = 0; i < size; i++){
            for(let j = 0; j < size; j++){
                const currentX = startX + (j * dimensioneSezioneGriglia);
                const currentY = startY + (i * dimensioneSezioneGriglia);

                const mesh = new Mesh(
                    new PlaneGeometry(dimensioneSezioneGriglia, dimensioneSezioneGriglia),
                    new MeshBasicMaterial({color: [0xdddddd, 0x5c5c5c][(i + j) % 2], transparent: true, opacity: 0.5, side: DoubleSide})
                )
                mesh.position.set(currentX, 0, currentY);
                mesh.rotation.x = toRadians(-90);
                mesh.userData.noRayCollision = true;
                gruppo.add(mesh);
            }
        }

        return gruppo;
    }

    /**
     * Aggiorna la distanza della camera dal centro
     * @param avvicinamento Flag di avvicinamento
     * @param quantitaAvvicinamento Quantita di avvicinamento
     * @private
     */
    private _aggiornaDistanzaCamera(avvicinamento: boolean, quantitaAvvicinamento = 5){
        if(avvicinamento){
            this._distanzaCamera -= quantitaAvvicinamento;
            if(this._distanzaCamera < 0)
                this._distanzaCamera = 0;
        }else{
            this._distanzaCamera += quantitaAvvicinamento;
        }
    }

    /**
     * Aggiorna la rotazione del container
     * @param movimentoX Rotazione x del container
     * @param movimentoY Rotazione yre del container
     * @param angoloDiRotazione Angolo di rotazione da applicare
     * @private
     */
    private _aggiornaRotazioneContainer(movimentoX: number, movimentoY: number, angoloDiRotazione = 1){
        if(this._mousePremuto){
            this._movimentoCamera = true;
            this._yaw += angoloDiRotazione * movimentoX;
            this._pitch += angoloDiRotazione * movimentoY;
            if(Math.abs(this._pitch) > 85)
                this._pitch = 85 * (this._pitch / Math.abs(this._pitch));
        }
    }

    /**
     * Aggiorna lo stato della telecamera
     * @private
     */
    private _updateCamera(){
        if(this._canvasRef.current){
            const boundingClientRectCanvas = this._canvasRef.current.getBoundingClientRect();
            this._canvasRef.current.width = boundingClientRectCanvas.width;
            this._canvasRef.current.height = boundingClientRectCanvas.height;

            this._camera = this.state.cameraProspettica ? this._perspectiveCamera : this._ortographicCamera;
            if(this.state.cameraProspettica)
                (this._camera as PerspectiveCamera).aspect = boundingClientRectCanvas.width / boundingClientRectCanvas.height;
            else{
                (this._camera as OrthographicCamera).top = 100 * (boundingClientRectCanvas.height / boundingClientRectCanvas.width);
                (this._camera as OrthographicCamera).bottom = -100 * (boundingClientRectCanvas.height / boundingClientRectCanvas.width);
            }
            this._camera.updateProjectionMatrix();
            this._renderer.setSize(this._canvasRef.current.width, this._canvasRef.current.height, false);
            this._renderer.setViewport(0, 0, this._canvasRef.current.width, this._canvasRef.current.height);

            this._outlinePassHover.renderCamera = this._camera;
            this._outlinePassSelection.renderCamera = this._camera;
            this._renderPass.camera = this._camera;
            this._composer.setSize(this._canvasRef.current.width, this._canvasRef.current.height);
        }

        if(this.state.cameraProspettica){
            const radYaw = toRadians(this._yaw);
            const radPitch = toRadians(this._pitch);
            this._camera.position.set(
                Math.cos(radYaw) * Math.cos(radPitch) * this._distanzaCamera,
                Math.sin(radPitch) * this._distanzaCamera,
                Math.sin(radYaw) * Math.cos(radPitch) * this._distanzaCamera
            );
        }else{
            if(this._posizioneTopOrtogonale){
                this._camera.position.set(
                    0,
                    100,
                    0
                );
            }else{
                const radRotazione = toRadians(this._rotazioneOrtogonale * 90);
                this._camera.position.set(
                    Math.cos(radRotazione) * 100,
                    0,
                    Math.sin(radRotazione) * 100
                );
            }
        }

        this._cameraLight.position.set(
            this._camera.position.x,
            this._camera.position.y,
            this._camera.position.z
        )

        this._cameraLight.lookAt(0, 0, 0);
        this._camera.lookAt(0, 0, 0);
    }

    /**
     * Funzione che determina un update della fisica
     * @private
     */
    private _updateFisica(){
        if(this._tickFisicoAttuale === 0){
            this.triggerCanvasMove(this._clientX, this._clientY);
            this.triggerCollisionWithMouse();
        }else{
            this._tickFisicoAttuale++;
            if(this._tickFisicoAttuale > this._tickFisicoMassimo)
                this._tickFisicoAttuale = 0;
        }
    }

    /**
     * Funzione che cicla su ogni figlio e recupera i trigger per il renderizzatore
     * @private
     */
    private _updateTrigger(){
        if(this._scena){
            const outlineHoverObjects: Object3D[] = [];
            const outlineSelectedObjects: Object3D[] = [];

            this._scena.traverse(object => {
                //Resetta la posizione della telecamera
                if(object.userData.initializeCameraPosition){
                    this.initializeCameraPosition(
                        object.userData.initializeCameraPosition.yaw,
                        object.userData.initializeCameraPosition.pitch,
                        object.userData.initializeCameraPosition.raggio
                    )
                    delete object.userData.initializeCameraPosition;
                }

                //Rende evidenti gli oggetti
                if(object.userData.outlineHover)
                    outlineHoverObjects.push(object);

                if(object.userData.outlineSelected)
                    outlineSelectedObjects.push(object);
            });

            this.setState({
                hover: outlineHoverObjects.length > 0
            });

            this._outlinePassHover.selectedObjects = outlineHoverObjects;
            this._outlinePassSelection.selectedObjects = outlineSelectedObjects;
        }
    }

    /**
     * Funzione di aggiornamento dello stato del rendering con attesa di millisecondi
     * @private
     */
    private _updateMsFunction(){
        if(this._statoRendering)
            window.setTimeout(() => this._updateMsFunction(), this._msDelayUpdate);
        this._updateTrigger();
        this._updateFisica();
    }

    /**
     * Funzione di aggiornamento dello stato del rendering
     * @param nowTime Tempo dell'ultima chiamata
     * @private
     */
    private _updateFunction(nowTime: number){
        const dt = Date.now() - nowTime;
        if(this._statoRendering)
            requestAnimationFrame(() => this._updateFunction(Date.now()));
        this._updateCamera();
        this.props.onTick && this.props.onTick(dt);

        this._composer.render();
    }

    /**
     * Triggera e gestisce il click sul canvas
     * @param clientX Client X sul canvas
     * @param clientY Client Y sul canvas
     * @private
     */
    private triggerCanvasClick(clientX: number, clientY: number){
        if(this._canvasRef.current){
            const mousePosition = this.mousePosition;
            this.props.onClickCanvas && this.props.onClickCanvas(
                mousePosition.x,
                mousePosition.y,
                this._canvasRef.current,
                this._camera
            );
        }
    }

    /**
     * Triggera e gestisce il movimento sul canvas
     * @param clientX Client X sul canvas
     * @param clientY Client Y sul canvas
     * @private
     */
    private triggerCanvasMove(clientX: number, clientY: number){
        if(this._canvasRef.current){
            const mousePosition = this.mousePosition;

            this.props.onUpdateMousePosition && this.props.onUpdateMousePosition(
                mousePosition.x,
                mousePosition.y,
                this._canvasRef.current,
                this._camera
            );
        }
    }

    /**
     * Controlla l'intersezione con un figlio all'interno della scena
     * @private
     */
    private triggerCollisionWithMouse(){
        if(this._canvasRef.current && this.props.onCollisionWithMouseDetection){
            const boundingClientRect = this._canvasRef.current.getBoundingClientRect();
            const mousePosition = this.mousePosition;
            const normalizedScreenPosition = fromMousePositionToNormalizedCoordinates(mousePosition.x, mousePosition.y, boundingClientRect.width, boundingClientRect.height);

            const raycasting = new Raycaster();
            raycasting.setFromCamera(normalizedScreenPosition, this._camera);

            const intersections = raycasting.intersectObjects(this._scena.children, true);
            if(intersections.length > 0){
                if(intersections.length === 1 && intersections[0].object.visible)
                    this.props.onCollisionWithMouseDetection(intersections[0]);
                else{
                    intersections.sort((a, b) => a.distance - b.distance);
                    let launched = false;
                    for(let i = 0; i < intersections.length && !launched; i++){
                        const target = intersections[i];
                        if(target.object.visible && !target.object.userData.noRayCollision) {
                            this.props.onCollisionWithMouseDetection(target);
                            launched = true;
                        }
                    }
                }
            }else{
                this.props.onCollisionWithMouseDetection(undefined);
            }
        }
    }

    public render() {
        return (
            <div className={"CanvasContainer"}>
                <canvas
                    ref={this._canvasRef}
                    style={{
                        width: this.props.larghezzaCanvas,
                        aspectRatio: this.props.aspectRatioCanvas,
                        cursor: this.state.hover ? 'pointer' : 'default',
                        touchAction: 'none'
                    }}
                    onClick={ev => {
                        !this._movimentoCamera && this.triggerCanvasClick(ev.clientX, ev.clientY);
                        this._movimentoCamera = false;
                    }}
                    onMouseMove={ev => {
                        this._clientX = ev.clientX;
                        this._clientY = ev.clientY;
                    }}
                    onMouseDown={() => this._mousePremuto = true}
                    onMouseUp={() => {this._mousePremuto = false}}
                    onMouseMoveCapture={ev => this._aggiornaRotazioneContainer(ev.movementX, ev.movementY)}
                    onMouseLeave={() => this._mousePremuto = false}>
                    Il renderizzatore non é supportato su questo sistema
                </canvas>
                {
                    this.props.modalitaSupportoVisivo &&
                    <RenderizzatoreComandiContainer
                        cameraProspettica={this.state.cameraProspettica}
                        cambiaCamera={cameraProspettica => this.setState({cameraProspettica})}
                        sinistra={() => {this._rotazioneOrtogonale--; this._posizioneTopOrtogonale = false}}
                        sopra={() => this._posizioneTopOrtogonale = true}
                        destra={() => {this._rotazioneOrtogonale++; this._posizioneTopOrtogonale = false}}/>
                }
            </div>
        );
    }

    //functions

    /**
     * Inizializza la posizione della telecamera
     * @param yaw Rotazione attorno all'asse X
     * @param pitch Rotazione attorno all'asse Y
     * @param radius Raggio della telecamera
     */
    public initializeCameraPosition(yaw: number, pitch: number, radius: number){
        this._yaw = yaw;
        this._pitch = pitch;
        this._distanzaCamera = radius;
    }

    //beam

    public get mousePosition(): Vector2{
        const esito = new Vector2();

        if(this._canvasRef.current){
            const boundingClientRect = this._canvasRef.current.getBoundingClientRect();
            esito.x = Math.round(this._clientX - boundingClientRect.x);
            esito.y = Math.round(this._clientY - boundingClientRect.y);
        }

        return esito;
    }
}
