import React, {RefObject} from "react";
import downArrow from "../../../Media/Images/Icons/downArrowIcon2.png";
import ItemElement from "./ItemElement";

import "./ElementSelect.scss";
import DefaultInput, {DefaultInputProps} from "../../../Core/DefaultInput/DefaultInput";

export interface ItemElementInterface{
    label: string,
    image?: string | Promise<string>
}

export interface ElementSelectProps extends Omit<DefaultInputProps, "inputRef" | "onChange" | "type" | "placeHolder"> {
    label: string,
    maxHeight?: number,
    elements: ItemElementInterface[],
    onChange?: (v: string) => void,
    autocompleteMode?: boolean,
    blockMode?: boolean
}

export interface ElementSelectStatus{
    open: boolean,
    researchValue: string,
}

export default class ElementSelect extends React.Component<ElementSelectProps, ElementSelectStatus>{
    private _margin = 1;                    //Margine applicato al container

    private _parentRef: RefObject<HTMLDivElement> = React.createRef();
    private _containerRef: RefObject<HTMLDivElement> = React.createRef();
    private _updatePositionFalg: boolean;

    constructor(props: Readonly<ElementSelectProps> | ElementSelectProps) {
        super(props);
        this.state = {
            open: false,
            researchValue: ""
        }
    }

    /**
     * Controlla l'esistenza del valore corrente all'interno degli elementi passati come parametro e trigghera il cambiamento
     * @private
     */
    private _checkCurrentValueExistance() {
        let found = false;
        for (const element of this.props.elements) {
            if (element.label.toLowerCase() === this.props.value.toLowerCase()) {
                found = true;
                break;
            }
        }
        if (!found && this.props.value) {
            this.props.onChange && this.props.onChange("");
            this.setState({open: false});
        }
    }

    /**
     * Avvia l'esecuzione del posizionamento del container
     * @private
     */
    private _startContainerPositionLoop(){
        this._updatePositionFalg = true;
        const updatePositionFunction = () => {
            if(this._parentRef.current && this._containerRef.current && this.state.open){
                let parentRect = this._parentRef.current.getBoundingClientRect();
                let containerRect = this._containerRef.current.getBoundingClientRect();

                let checkPosition = parentRect.y + parentRect.height + this._margin + containerRect.height;
                if(checkPosition <= window.innerHeight)
                    this._containerRef.current.style.top = `${parentRect.height + this._margin}px`;
                else this._containerRef.current.style.top = `-${containerRect.height + this._margin}px`;
            }

            if(this._updatePositionFalg)
                requestAnimationFrame(updatePositionFunction);
        }
        updatePositionFunction();
    }

    /**
     * Effettua il setup per la gestione della chiusura del container quando si preme fuori da quest'ultimo
     * @private
     */
    private _setupCloseContainerWindowEvent(){
        window.addEventListener("click", (e) => {
            //Condizione per la chiusura al di fuori del componente
            if(this._parentRef.current && ( this.state.open || this.state.researchValue.length > 0 )){
                const bound = this._parentRef.current.getBoundingClientRect();
                if(e.x < bound.x || e.x > bound.x + bound.width || e.y < bound.y || e.y > bound.y + bound.height) {
                    this.setState({open: false, researchValue: this.props.value});
                }
            }
        })
    }

    /**
     * Restituisce gli elementi filtrati per la modalità di autocomplete
     * @private
     */
    private _filteredElements(): ItemElementInterface[]{
        return this.props.elements.filter(value => !this.props.autocompleteMode || value.label.toLowerCase().includes(this.state.researchValue.toLowerCase()));
    }

    public componentDidUpdate(prevProps: Readonly<ElementSelectProps>) {
        if (!this.props.autocompleteMode && (prevProps.value !== this.props.value || JSON.stringify(this.props.elements) !== JSON.stringify(prevProps.elements)))
            this._checkCurrentValueExistance();
    }

    public componentDidMount() {
        if(!this.props.blockMode){
            this._startContainerPositionLoop();
            this._setupCloseContainerWindowEvent();
        }
    }

    public componentWillUnmount() {
        this._updatePositionFalg = false;
    }

    render() {
        return (
            <div className={`ElementSelect`}>
                <div
                    ref={this._parentRef}
                    className={`inputWrapper ${this.props.autocompleteMode && 'autocompleteMode'}`}
                    onClick={() => {
                        if(!this.props.disabled)
                            this.setState((prevState) => ({open: !prevState.open}));
                    }}>
                    <DefaultInput
                        disabled={this.props.disabled}
                        value={this.props.autocompleteMode ? (this.state.open ? this.state.researchValue : this.props.value) : this.props.value}
                        placeHolder={this.props.label}
                        onChange={v => this.setState({researchValue: v})}/>
                    {
                        !this.props.autocompleteMode &&
                        <img
                            src={downArrow}
                            alt={"downArrow"}
                            className={`arrow ${this.state.open && "open"}`}
                            style={{filter: "invert(50%)"}}/>
                    }
                </div>
                <div
                    ref={this._containerRef}
                    className={`itemContainer ${this.props.blockMode && 'blockMode'} ${this.state.open && "open"}`}
                    style={{maxHeight: this.props.maxHeight?? 350}}>
                    {
                        this._filteredElements().length > 0 ?
                            this._filteredElements().map(element => (
                                <ItemElement
                                    key={element.label}
                                    onClick={(value) => {
                                        if(this.props.onChange) {
                                            this.props.onChange(value);
                                            this.setState({researchValue: value, open: false});
                                        }
                                    }}
                                    label={element.label}
                                    image={element.image}/>
                            )) :
                            <ItemElement
                                key={"ZeroElements"}
                                label={"0 risultati"}/>
                    }
                </div>
            </div>
        );
    }
}
