import React, {Fragment} from "react";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import uploadIcon from "../../../Media/Images/Icons/uploadIcon.png";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import ProgressBar from "../../../Core/ProgressBar/ProgressBar";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ContainerLayout from "../../../Layout/ContainerLayout/ContainerLayout";
import {StorageData} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";

export interface DADMHeaderProps{
    immagini: StorageData[],
    indicatoreMassimoFogli?: number,
    onUpload?: (file: File) => void,
    cartoncini?: [number, string][]
}
export interface DADMHeaderState{
    isLoading: boolean,
    percentage: number
}

export default class DADMHeader extends React.Component<DADMHeaderProps, DADMHeaderState>{
    constructor(props: Readonly<DADMHeaderProps> | DADMHeaderProps) {
        super(props);
        this.state = {
            isLoading: false,
            percentage: 0
        }
    }

    private _SezioneCaricamentoFallito(fileNonCaricati: string[]){
        return (
            <ContainerLayout size={"largeRelative"} fullHeight={true} scroll={true}>
                <ResponsiveLabel content={"I seguenti file non sono stati caricati"} type={"medium"} alignment={"center"}/>
                <SectionLayout size={"largeRelative"}>{
                    fileNonCaricati.map(value => (
                        <ResponsiveLabel key={value} content={value} type={"small"} alignment={"center"}/>
                    ))
                }</SectionLayout>
            </ContainerLayout>
        )
    }

    /**
     * Effettua il caricamento del singolo file
     * @param file File da caricare
     * @param fileCaricati Lista dei file caricati
     * @private
     */
    private _caricaSingoloFile(file: File, fileCaricati: string[]): boolean{
        let esito = false;

        if(file.name.toLowerCase().match(/png|jpg|jpeg/) && !fileCaricati.includes(file.name)){
            esito = true;
            if(this.props.onUpload)
                this.props.onUpload(file);
        }

        return esito;
    }

    /**
     * Effettua il caricamento dei file ottenuti
     * @param ev Evento drag dell'header
     * @private
     */
    private async _caricaFiles(ev: React.DragEvent<HTMLSpanElement>){
        if(!this.state.isLoading){
            this.setState({isLoading: true, percentage: 0});
            if(ev.dataTransfer.files){
                const files = ev.dataTransfer.files;
                const fileCaricati = this.props.immagini.map(immagine => immagine.name);
                const fileNonCaricati: string[] = [];
                for(const file of files){
                    this.setState({percentage: (fileCaricati.length + fileNonCaricati.length)  / files.length});
                    let esito = this._caricaSingoloFile(file, fileCaricati);
                    if(esito){
                        fileCaricati.push(file.name);
                    }else {
                        fileNonCaricati.push(file.name);
                    }
                }
                if(fileNonCaricati.length > 0){
                    TopLevelComponentStorage.GetTopLevel("confirmWindow").showConfirmWindow(
                        "Errore",
                        this._SezioneCaricamentoFallito(fileNonCaricati) as unknown as string,
                        "SingleButton",
                        () => {
                            this.setState({isLoading: false, percentage: 0});
                            TopLevelComponentStorage.GetTopLevel("confirmWindow").hideConfirmWindow();
                        }
                    )
                }else{
                    this.setState({isLoading: false, percentage: 0})
                }
            }
        }
    }

    /**
     * Effettua il caricamento dei files da click
     * @private
     */
    private _caricaFilesClick(){
        if(!this.state.isLoading){
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = true;
            input.addEventListener("change", () => {
                if(input.files)
                    this._caricaFiles({dataTransfer: {files: input.files}} as any);
            });
            input.click();
        }
    }

    public render() {
        return (
            <Fragment>
                <div className={"nascostoPerIndice"} style={{width: "100%"}}>
                    <ResponsiveLabel
                        content={`${this.props.immagini.length + (this.props.cartoncini ? this.props.cartoncini.length : 0)}${this.props.indicatoreMassimoFogli ? `/${this.props.indicatoreMassimoFogli}` : ''} elementi presenti`}
                        type={"medium"}
                        alignment={"center"}/>
                </div>
                <span
                    className={"DADMHeaderUploader nascostoPerIndice"}
                    onDragEnter={ev => ev.preventDefault()}
                    onDragLeave={ev => ev.preventDefault()}
                    onDragOver={ev => ev.preventDefault()}
                    onDrop={ev => {
                        ev.preventDefault();
                        this._caricaFiles(ev);
                    }}
                    onClick={() => this._caricaFilesClick()}>
                    <IfContainer
                        condition={!this.state.isLoading}
                        elseComponent={
                            <Fragment>
                                <ResponsiveLabel
                                    content={"Stiamo caricando i tuoi file"}
                                    type={"medium"}
                                    alignment={"center"}
                                    labelType={"recapLabel"}/>
                                <ProgressBar progress={this.state.percentage}/>
                            </Fragment>
                        }>
                        <img alt={"UploadIcon"} src={uploadIcon} className={"UploadIcon"}/>
                        <ResponsiveLabel
                            content={"Carica o trascina qui i tuoi file"}
                            type={"medium"}
                            alignment={"center"}
                            labelType={"recapLabel"}/>
                    </IfContainer>
                </span>
            </Fragment>
        );
    }
}
