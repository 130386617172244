import {ChatModel, ChatPersonaleResponseModel, ChatPostBodyInterface, ChatPutBodyInterface, Rest} from "tici_commons";
import Config from "../Static/Config";

export default class ChatService{
    public static async MessaggiChatPersonale(): Promise<ChatPersonaleResponseModel[]>{
        let esito: ChatPersonaleResponseModel[] = [];

        try {
            const response = await Rest.Get<ChatPersonaleResponseModel[]>(`${Config.PrivatePath}/ChatPersonaleRoute`);
            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Non é stato possibile recuperare i messaggi della chat personale", String(e));
        }

        return esito;
    }

    public static async MessaggiNonLettiOrdine(idOrdine: number): Promise<number>{
        let esito = 0;

        try {
            const response = await Rest.Get<number>(`${Config.PrivatePath}/ChatIcon/${idOrdine}`);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Non é stato possibile recuperare i messaggi non letti dell'ordine", String(e));
        }

        return esito;
    }

    public static async RecuperaMessaggiOrdine(idOrdine: number): Promise<ChatModel[]>{
        const esito: ChatModel[] = [];

        try {
            const response = await Rest.Get<ChatModel[]>(`${Config.PrivatePath}/Chat/${idOrdine}`);
            if(response && response.statusCode === 200)
                esito.push(...response.response);
        }catch (e){
            console.error("Errore nel recupero dei messaggi della chat", String(e));
        }

        return esito;
    }

    public static async LeggiMessaggio(...idMessaggi: number[]): Promise<boolean>{
        let esito = false;

        try{
            const body: ChatPutBodyInterface = {
                idMessaggi
            }
            const response = await Rest.Put(
                `${Config.PrivatePath}/Chat`,
                undefined,
                body
            );
            esito = response && response.statusCode === 200;
        }catch (e){
            console.error("Errore nella lettura del messaggio", String(e))
        }

        return esito;
    }

    public static async InviaMessaggio(contenuto: string, idOrdineAssociato: number, nomeAllegato?: string, base64Allegato?: string): Promise<boolean>{
        let esito = false;

        try {
            const body: ChatPostBodyInterface = {
                contenuto,
                idOrdineAssociato,
                nomeAllegato,
                base64Allegato
            }
            const response = await Rest.Post(
                `${Config.PrivatePath}/Chat`,
                undefined,
                body
            );
            if (response && response.statusCode === 200)
                esito = true;
        } catch (e){
            console.error("Errore nell'invio del messaggio", String(e));
        }

        return esito;
    }
}
