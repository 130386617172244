//QG 30/06/2024 MD

import './SelettoreUv.scss';
import React from "react";
import SezioneConfigurabile from "../../../GestioneModelli/SezioneConfigurabile";
import {
    ConfigurazioneModelloInterface,
    ConfigurazioneSezioneInterface,
    InformazioneUvModel
} from "tici_commons";
import {NavigatorImageFormatProps, NavigatorStorageMethods} from "../TiciNavigator/TiciNavigator";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import ImageService from "../../../../Servicies/ImageService";
import {ModelloConfigurabile} from "../../../GestioneModelli/ModelloConfigurabile";
import Button from "../../../../Core/Buttons/Button";
import EditorImmagini from "../EditorImmagini/EditorImmagini";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import DefaultInput from "../../../../Core/DefaultInput/DefaultInput";
import {imageTypeSupported} from "../../../../Utils/CommonFunctions";

export interface SelettoreUvProps extends NavigatorStorageMethods, NavigatorImageFormatProps{
    sezioneConfigurabile: SezioneConfigurabile,
    modelloConfigurabile: ModelloConfigurabile,
    configurazioneModello: ConfigurazioneModelloInterface,
    configurazioneSezione: ConfigurazioneSezioneInterface,
    informazioneUv?: InformazioneUvModel,
    onInformazioneUvChange?: (nomeUv: string, nomeMetricheUv: string, nomePosizionamentoUv: string, posizioneXUv: number, posizioneYUv: number, rotazioneZUv: number, scalaUv: number) => void,
    onInformazioneUvDelete?: () => void
}

export interface SelettoreUvState{
    posizioneXUv: number,
    posizioneYUv: number,
    rotazioneZUv: number,
    scalaUv: number

    uvSelettoreSelezionato: string
}

export default class SelettoreUv extends React.Component<SelettoreUvProps, SelettoreUvState>{
    private _editorImmaigniRef = React.createRef<EditorImmagini>();

    constructor(props: Readonly<SelettoreUvProps> | SelettoreUvProps) {
        super(props);
        this.state = {
            posizioneXUv: 0,
            posizioneYUv: 0,
            rotazioneZUv: 0,
            scalaUv: 1,
            uvSelettoreSelezionato: ""
        }
    }

    public componentDidMount() {
        if(!this.props.informazioneUv)
            this.props.eliminaImmagine(this._categoriaUv);
        else{
            this.setState({
                posizioneXUv: this.props.informazioneUv.posizioneXUv,
                posizioneYUv: this.props.informazioneUv.posizioneYUv,
                rotazioneZUv: this.props.informazioneUv.rotazioneZUv,
                scalaUv: this.props.informazioneUv.scalaUv
            })
        }
    }

    private get _categoriaUv(): string{
        return `${this.props.configurazioneSezione.nomeReale}-uv`;
    }

    private get _uVCaricato(): boolean{
        return this.props.contaImmagini && this.props.contaImmagini(this._categoriaUv) > 0;
    }

    private get _nomeUvCaricato(): string{
        return (this.props.recuperaImmagine && this.props.recuperaImmagine(this._categoriaUv)?.name) || '';
    }

    private get _uvCaricatoData(): string{
        return this.props.recuperaImmagine && this.props.recuperaImmagine(this._categoriaUv)?.url();
    }

    public render() {
        return (
            <div className={"SelettoreUvContainer"}>
                <span className={"InputFieldContainer"}>
                    <SectionLayout size={"largeRelative"}>
                        <ResponsiveLabel content={"Carica un uv:"} type={"medium"} alignment={"left"}/>
                        <DefaultInput
                            type={'file'}
                            value={this._nomeUvCaricato}
                            onFileUpload={(fileName, fileType, file) => {
                                if(imageTypeSupported(fileType)) {
                                    this.props.eliminaImmagine && this.props.eliminaImmagine(this._categoriaUv);
                                    this.props.caricaImmagine && this.props.caricaImmagine(this._categoriaUv, fileName, file);
                                }
                            }}/>
                    </SectionLayout>
                    <IfContainer condition={this._uVCaricato}>
                        <span
                            style={{backgroundImage: `url(${ImageService.getImage('xIcon')})`}}
                            className={"ButtonElimina"}
                            onClick={() => {
                                this.props.eliminaImmagine(this._categoriaUv);
                                this.props.onInformazioneUvDelete && this.props.onInformazioneUvDelete();
                            }}/>
                    </IfContainer>
                </span>
                <IfContainer condition={this._uVCaricato}>
                    <EditorImmagini
                        ref={this._editorImmaigniRef}
                        nomeManager={this.props.nomeManager}
                        configurazioneModello={this.props.configurazioneModello}
                        configurazioneSezione={this.props.configurazioneSezione}
                        modelloConfigurabile={this.props.modelloConfigurabile}
                        immagineCaricataData={this._uvCaricatoData}
                        mostraComandi={true}
                        posizioneXImmagine={this.state.posizioneXUv}
                        posizioneYImmagine={this.state.posizioneYUv}
                        zoomImmagine={this.state.scalaUv}
                        mostraComandiRotazione={true}
                        mostraComandoCentra={true}
                        mostraIndicazioniPosizionamento={true}
                        rotazioneImmagine={this.state.rotazioneZUv}
                        onCambioConfigurazioniImmagine={
                            (posizioneXImmagine, posizioneYImmagine, zoomImmagine, rotazioneImmagine) => {
                                this.setState((oldState) => ({
                                    posizioneXUv: posizioneXImmagine === undefined ? oldState.posizioneXUv : posizioneXImmagine,
                                    posizioneYUv: posizioneYImmagine === undefined ? oldState.posizioneYUv : posizioneYImmagine,
                                    scalaUv: zoomImmagine === undefined ? oldState.scalaUv : zoomImmagine,
                                    rotazioneZUv: rotazioneImmagine === undefined ? oldState.rotazioneZUv : rotazioneImmagine
                                }))
                            }
                        }
                        modalitaVisualizzazioneAlternativa={this.props.modalitaVisualizzazioneAlternativa}/>
                    <Button
                        content={"Conferma"}
                        type={"medium"}
                        buttonType={'full-normal'}
                        onClick={() => {
                            this.props.onInformazioneUvChange && this.props.onInformazioneUvChange(
                                this._nomeUvCaricato,
                                this._editorImmaigniRef.current.generaMascheraPosizionamento(8000),
                                this._editorImmaigniRef.current.generaMascheraSezione(1280),
                                this.state.posizioneXUv,
                                this.state.posizioneYUv,
                                this.state.rotazioneZUv,
                                this.state.scalaUv
                            );
                        }}/>
                </IfContainer>
            </div>
        );
    }
}
