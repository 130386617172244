//QG 30/06/2024 MD

import './EditorImmagini.scss';
import React from "react";
import ImageService from "../../../../Servicies/ImageService";
import {NavigatorImageFormatProps} from "../TiciNavigator/TiciNavigator";
import {ConfigurazioneModelloInterface, ConfigurazioneSezioneInterface} from "tici_commons";
import {ModelloConfigurabile} from "../../../GestioneModelli/ModelloConfigurabile";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import MangerRenderizzatoreModelliHelper from "../../MangerRenderizzatoreModelliHelper";
import AutoRefreshComponent from "../../../../Core/Arch/AutoRefreshComponent";
import LocalStorageService from "../../../../Servicies/LocalStorageService";
import {toRadians} from "../../../GestioneModelli/Utils";

export interface EditorImmaginiProps extends NavigatorImageFormatProps{
    configurazioneModello: ConfigurazioneModelloInterface,
    configurazioneSezione: ConfigurazioneSezioneInterface,
    modelloConfigurabile: ModelloConfigurabile,
    immagineCaricataData: string,
    posizioneXImmagine?: number,
    posizioneYImmagine?: number,
    zoomImmagine?: number,
    rotazioneImmagine?: number,
    mostraComandi?: boolean,
    mostraComandiRotazione?: boolean,
    mostraComandoCentra?: boolean,
    mostraIndicazioniPosizionamento?: boolean,
    onCambioConfigurazioniImmagine?: (posizioneXImmagine?: number, posizioneYImmagine?: number, zoomImmagine?: number, rotazioneImmagine?: number) => void
}

export interface EditorImmaginiState {
    larghezzaEditor: number;
}

export default class EditorImmagini extends AutoRefreshComponent<EditorImmaginiProps, EditorImmaginiState>{
    private _imageRef = React.createRef<HTMLImageElement>();
    private _containerRef = React.createRef<HTMLDivElement>();

    private _spostamentoX = 0;
    private _spostamentoY = 0;

    constructor(props: Readonly<EditorImmaginiProps> | EditorImmaginiProps) {
        super(props);
        this.state = {
            larghezzaEditor: 1
        }
        this.Delay = 100;
    }

    private _cycleSpostamentoImmagine = () => {
        this._spostamentoX !== 0 && this._cambiaSpostamentoXImmagine(this._spostamentoX);
        this._spostamentoY !== 0 && this._cambiaSpostamentoYImmagine(this._spostamentoY);
        if(this._containerRef.current){
            const boundingRect = this._containerRef.current.getBoundingClientRect();
            const fattoreMoltiplicativo = MangerRenderizzatoreModelliHelper.calcolaFattoreMoltiplicativoX(
                this.props.configurazioneSezione,
                this.props.configurazioneModello,
                this.props.modelloConfigurabile,
                this.props.modalitaVisualizzazioneAlternativa
            );
            this.setState({
                larghezzaEditor: boundingRect.height * fattoreMoltiplicativo
            })
        }
    }

    public generaMascheraSezione(targetWidth: number, targetMascheraSezione = `MascheraSezione~${this.props.nomeManager}~${this.props.configurazioneSezione.nomeVisualizzato}`): string{
        const canvas = document.createElement('canvas');
        const fattoreMoltiplicativoY = MangerRenderizzatoreModelliHelper.calcolaFattoreMoltiplicativoY(
            this.props.configurazioneSezione,
            this.props.configurazioneModello,
            this.props.modelloConfigurabile,
            this.props.modalitaVisualizzazioneAlternativa
        );
        canvas.width = targetWidth;
        canvas.height = targetWidth * fattoreMoltiplicativoY;

        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'rgb(221, 221, 221)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        if(this.props.immagineCaricataData){
            const image = document.createElement('img');
            image.addEventListener('load', () => {
                const imageWidth = image.width;
                const imageHeight = image.height;
                const fattoreMoltiplicativoHeight = imageWidth / imageHeight;

                const posX = canvas.width * (this.props.posizioneXImmagine / 100);
                const posY = canvas.height * (this.props.posizioneYImmagine / 100);
                const width = canvas.width * (this.props.zoomImmagine || 1);
                const height = width / fattoreMoltiplicativoHeight

                ctx.save();

                ctx.translate(posX + width * 0.5, posY + height * 0.5);
                ctx.rotate(toRadians(this.props.rotazioneImmagine));

                ctx.drawImage(
                    image,
                    -width * 0.5,
                    -height * 0.5,
                    width,
                    height
                )

                ctx.restore();

                LocalStorageService.SalvaMascheraSezione(targetMascheraSezione, canvas.toDataURL());
            });
            image.src = this.props.immagineCaricataData;
        }

        return targetMascheraSezione;
    }

    public generaMascheraPosizionamento(targetWidth: number, targetMascheraSezione = `MascheraPosizione~${this.props.nomeManager}~${this.props.configurazioneSezione.nomeVisualizzato}`): string{
        const canvas = document.createElement('canvas');
        const fattoreMoltiplicativoY = MangerRenderizzatoreModelliHelper.calcolaFattoreMoltiplicativoY(
            this.props.configurazioneSezione,
            this.props.configurazioneModello,
            this.props.modelloConfigurabile,
            this.props.modalitaVisualizzazioneAlternativa
        );
        canvas.width = targetWidth;
        canvas.height = targetWidth * fattoreMoltiplicativoY;

        const ctx = canvas.getContext('2d');

        if(this.props.immagineCaricataData){
            const image = document.createElement('img');
            image.addEventListener('load', () => {
                const imageWidth = image.width;
                const imageHeight = image.height;
                const fattoreMoltiplicativoHeight = imageWidth / imageHeight;

                const posX = canvas.width * (this.props.posizioneXImmagine / 100);
                const posY = canvas.height * (this.props.posizioneYImmagine / 100);
                const width = canvas.width * (this.props.zoomImmagine || 1);
                const height = width / fattoreMoltiplicativoHeight

                ctx.save();

                ctx.translate(posX + width * 0.5, posY + height * 0.5);
                ctx.rotate(toRadians(this.props.rotazioneImmagine));

                ctx.drawImage(
                    image,
                    -width * 0.5,
                    -height * 0.5,
                    width,
                    height
                )

                ctx.restore();

                LocalStorageService.SalvaMascheraSezione(targetMascheraSezione, canvas.toDataURL());
            });
            image.src = this.props.immagineCaricataData;
        }

        return targetMascheraSezione;
    }

    private _cambiaZoomImmagine(quantitaZoom: number){
        this.props.onCambioConfigurazioniImmagine && this.props.onCambioConfigurazioniImmagine(undefined, undefined, this.props.zoomImmagine + quantitaZoom, undefined);
    }

    private _cambiaSpostamentoXImmagine(quantitaSpostamento: number){
        this.props.onCambioConfigurazioniImmagine && this.props.onCambioConfigurazioniImmagine(this.props.posizioneXImmagine + quantitaSpostamento, undefined, undefined, undefined);
    }

    private _cambiaSpostamentoYImmagine(quantitaSpostamento: number){
        this.props.onCambioConfigurazioniImmagine && this.props.onCambioConfigurazioniImmagine(undefined, this.props.posizioneYImmagine + quantitaSpostamento, undefined, undefined);
    }

    private _cambiaRotazioneImmagine(quantitaRotazione: number){
        this.props.onCambioConfigurazioniImmagine && this.props.onCambioConfigurazioniImmagine(undefined, undefined, undefined, this.props.rotazioneImmagine + quantitaRotazione);
    }

    private _posizionaImmagineCentrale(){
        if(this._imageRef.current){
            const parentBoundingBox = this._imageRef.current.parentElement.getBoundingClientRect();
            const imageBoundingBox = this._imageRef.current.getBoundingClientRect();

            const percX = (imageBoundingBox.width / parentBoundingBox.width) * 50;
            const percY = (imageBoundingBox.height / parentBoundingBox.height) * 50;
            this.props.onCambioConfigurazioniImmagine && this.props.onCambioConfigurazioniImmagine(
                50 - percX,
                50 - percY,
                undefined,
                undefined
            )
        }
    }

    public render() {
        return (
            <div className={"ZonaEditor"}>
                <div
                    ref={this._containerRef}
                    className={"EditorImmagine"}
                    style={{
                        width: `${this.state.larghezzaEditor}px`
                    }}>
                    <img
                        ref={this._imageRef}
                        alt={"EditorImmaginiPosizionamento"}
                        className={"ImmagineEditor"}
                        src={this.props.immagineCaricataData}
                        style={{
                            width: `${(this.props.zoomImmagine || 1) * 100}%`,
                            left: `${this.props.posizioneXImmagine || 0}%`,
                            top: `${this.props.posizioneYImmagine || 0}%`,
                            filter: 'grayscale(1) opacity(0.1)'
                        }}/>
                    <img
                        ref={this._imageRef}
                        alt={"EditorImmagini"}
                        className={"ImmagineEditor"}
                        src={this.props.immagineCaricataData}
                        style={{
                            width: `${(this.props.zoomImmagine || 1) * 100}%`,
                            left: `${this.props.posizioneXImmagine || 0}%`,
                            top: `${this.props.posizioneYImmagine || 0}%`,
                            rotate: `${this.props.rotazioneImmagine || 0}deg`
                        }}/>
                    <IfContainer condition={this.props.mostraIndicazioniPosizionamento}>
                        {
                            /*
                            <span
                                className={"InidicazioniPosizione"}
                                style={{
                                    left: `${this.props.posizioneXImmagine || 0}%`,
                                    top: `${this.props.posizioneYImmagine || 0}%`
                                }}>
                                <ResponsiveLabel
                                    content={`(x: ${Math.round(this.props.posizioneXImmagine)}% sulla larghezza della sezione)`}
                                    type={"medium"}
                                    labelType={"whiteLabel"}
                                    alignment={"left"}/>
                                <ResponsiveLabel
                                    content={`(y: ${Math.round(this.props.posizioneYImmagine)}% sull'altezza della sezione)`}
                                    type={"medium"}
                                    labelType={"whiteLabel"}
                                    alignment={"left"}/>
                            </span>
                             */
                        }
                        <span
                            className={"LineaIndicazioniPosizione"}
                            style={{
                                height: 'auto',
                                top: `${this.props.posizioneYImmagine || 0}%`,
                                left: '0px',
                                width: `${this.props.posizioneXImmagine || 0}%`
                            }}/>
                        <span
                            className={"LineaIndicazioniPosizione"}
                            style={{
                                width: 'auto',
                                top: `0px`,
                                left: `${this.props.posizioneXImmagine || 0}%`,
                                height: `${this.props.posizioneYImmagine || 0}%`
                            }}/>
                    </IfContainer>
                </div>
                <IfContainer condition={this.props.mostraComandi}>
                    <div
                        className={"ZonaPulsanti column"}
                        style={{right: 10, bottom: 10}}>
                        <div className={"PulsanteZona"}>
                            <img
                                style={{rotate: '180deg'}}
                                alt={"Top"}
                                src={ImageService.getImage('simpleArrowIcon')}
                                onMouseDown={() => this._spostamentoY = -1}
                                onMouseUp={() => this._spostamentoY = 0}
                                onMouseLeave={() => this._spostamentoY = 0}/>
                        </div>
                        <div className={"PulsanteZona"}>
                            <img
                                alt={"Bottom"}
                                src={ImageService.getImage('simpleArrowIcon')}
                                onMouseDown={() => this._spostamentoY = 1}
                                onMouseUp={() => this._spostamentoY = 0}
                                onMouseLeave={() => this._spostamentoY = 0}/>
                        </div>
                    </div>
                    <IfContainer condition={this.props.mostraComandoCentra}>
                        <div
                            className={"ZonaPulsanti"}
                            style={{right: 52, bottom: 10}}>
                            <div className={"PulsanteZona"}>
                                <img
                                    alt={"Center"}
                                    src={ImageService.getImage('centerIcon')}
                                    onClick={() => this._posizionaImmagineCentrale()}/>
                            </div>
                        </div>
                    </IfContainer>
                    <IfContainer condition={this.props.mostraComandiRotazione}>
                        <div
                            className={"ZonaPulsanti"}
                            style={{left: 10, bottom: 52}}>
                            <div className={"PulsanteZona"}>
                                <img
                                    alt={"rotateLeft"}
                                    src={ImageService.getImage('rotateLeftIcon')}
                                    onClick={() => this._cambiaRotazioneImmagine(-10)}/>
                            </div>
                            <div className={"PulsanteZona"}>
                                <img
                                    alt={"rotateRight"}
                                    src={ImageService.getImage('rotateRightIcon')}
                                    onClick={() => this._cambiaRotazioneImmagine(10)}/>
                            </div>
                        </div>
                    </IfContainer>
                    <div
                        className={"ZonaPulsanti"}
                        style={{left: 10, bottom: 10}}>
                        <div className={"PulsanteZona"}>
                            <img
                                style={{rotate: '90deg'}}
                                alt={"Left"}
                                src={ImageService.getImage('simpleArrowIcon')}
                                onMouseDown={() => this._spostamentoX = -1}
                                onMouseUp={() => this._spostamentoX = 0}
                                onMouseLeave={() => this._spostamentoX = 0}/>
                        </div>
                        <div className={"PulsanteZona"}>
                            <img
                                style={{rotate: '-90deg'}}
                                alt={"Right"}
                                src={ImageService.getImage('simpleArrowIcon')}
                                onMouseDown={() => this._spostamentoX = 1}
                                onMouseUp={() => this._spostamentoX = 0}
                                onMouseLeave={() => this._spostamentoX = 0}/>
                        </div>
                    </div>
                    <div
                        className={"ZonaPulsanti"}
                        style={{right: 10, top: 10}}>
                        <div className={"PulsanteZona"}>
                            <img
                                alt={"ZoomIn"}
                                src={ImageService.getImage('zoomInIcon')}
                                onClick={() => this._cambiaZoomImmagine(0.1)}/>
                        </div>
                        <div className={"PulsanteZona"}>
                            <img
                                alt={"ZoomOut"}
                                src={ImageService.getImage('zoomOutIcon')}
                                onClick={() => this._cambiaZoomImmagine(-0.1)}/>
                        </div>
                    </div>
                </IfContainer>
            </div>
        )
    }
}
