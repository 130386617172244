//QG 30/06/2024 MD

import './ConfiguratoreModelliComponents.scss';

import React, {RefObject} from "react";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Responsive3Col from "../../../../Layout/Responsive3Col/Responsive3Col";
import DefaultInput from "../../../../Core/DefaultInput/DefaultInput";
import {Triple} from "../../../../../../tici_commons";
import {toRadians} from "../../../../Primary/GestioneModelli/Utils";

export interface TripleLineProps{
    label: string,
    xValue: number,
    yValue: number,
    zValue: number,
    mostraSlider?: boolean,
    gradi?: boolean,
    onChangeValue: (x: number, y: number, z: number) => void,
}

export class TripleLine extends React.Component<TripleLineProps, any>{
    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={this.props.label} type={"medium"} alignment={"left"}/>
                <Responsive3Col>
                    <SectionLayout size={"largeRelative"}>
                        <DefaultInput
                            type={"number"}
                            step={0.2}
                            value={this.props.xValue.toString()}
                            onChange={v => isNaN(parseFloat(v)) ?
                                this.props.onChangeValue(0, this.props.yValue, this.props.zValue) :
                                this.props.onChangeValue(parseFloat(v), this.props.yValue, this.props.zValue)
                            }/>
                        {
                            (this.props.mostraSlider === undefined || this.props.mostraSlider) &&
                            <input
                                style={{width: "100%"}}
                                type={"range"}
                                min={this.props.gradi ? -360 : -100}
                                max={this.props.gradi ? 360 : 100}
                                step={0.2}
                                value={this.props.xValue.toString()}
                                onChange={v => this.props.onChangeValue(parseFloat(v.target.value), this.props.yValue, this.props.zValue)}/>
                        }
                    </SectionLayout>
                    <SectionLayout size={'largeRelative'}>
                        <DefaultInput
                            type={"number"}
                            step={0.2}
                            value={this.props.yValue.toString()}
                            onChange={v => isNaN(parseFloat(v)) ?
                                this.props.onChangeValue(this.props.xValue, 0, this.props.zValue) :
                                this.props.onChangeValue(this.props.xValue, parseFloat(v), this.props.zValue)
                            }/>
                        {
                            (this.props.mostraSlider === undefined || this.props.mostraSlider) &&
                            <input
                                style={{width: "100%"}}
                                type={"range"}
                                min={this.props.gradi ? -360 : -100}
                                max={this.props.gradi ? 360 : 100}
                                step={0.2}
                                value={this.props.yValue.toString()}
                                onChange={v => this.props.onChangeValue(this.props.xValue, parseFloat(v.target.value), this.props.zValue)}/>
                        }
                    </SectionLayout>
                    <SectionLayout size={'largeRelative'}>
                        <DefaultInput
                            type={"number"}
                            step={0.2}
                            value={this.props.zValue.toString()}
                            onChange={v => isNaN(parseFloat(v)) ?
                                this.props.onChangeValue(this.props.xValue, this.props.yValue, 0) :
                                this.props.onChangeValue(this.props.xValue, this.props.yValue, parseFloat(v))
                            }/>
                        {
                            (this.props.mostraSlider === undefined || this.props.mostraSlider) &&
                            <input
                                style={{width: "100%"}}
                                type={"range"}
                                min={this.props.gradi ? -360 : -100}
                                max={this.props.gradi ? 360 : 100}
                                step={0.2}
                                value={this.props.zValue.toString()}
                                onChange={v => this.props.onChangeValue(this.props.xValue, this.props.yValue, parseFloat(v.target.value))}/>
                        }
                    </SectionLayout>
                </Responsive3Col>
            </SectionLayout>
        );
    }
}

export interface InputContainerProps{
    label: string,
    children: any
}

export class InputContainer extends React.Component<InputContainerProps, {}>{
    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel content={this.props.label} type={"medium"} alignment={"left"}/>
                {this.props.children}
            </SectionLayout>
        );
    }
}

export interface EditorSezioneSuperficieProps{
    altezzaSezioneSuperficie: number,
    larghezzaSezioneSuperficie: number,
    quantitaPuntiSuperficie: number,
    puntiSezioneSuperficie: Triple[],
    onPuntiSezioneChange: (puntiSezioneSuperficie: Triple[]) => void
}

export interface EditorSezioneSuperficieState{
    grabbing: boolean
}

export class EditorSezioneSuperficie extends React.Component<EditorSezioneSuperficieProps, EditorSezioneSuperficieState>{
    public static GeneraPuntiNeutriSuperficie(numeroPunti: number): Triple[]{
        const nuoviPunti: Triple[] = [];
        const angoloDiRotazione = 360 / numeroPunti;
        let angoloCorrente = angoloDiRotazione;

        for(let i = 0; i < numeroPunti; i++){
            const x = Math.cos(toRadians(angoloCorrente));
            const z = Math.sin(toRadians(angoloCorrente));
            angoloCorrente -= angoloDiRotazione;
            nuoviPunti.push({x, y: 0, z});
        }
        return nuoviPunti;
    }

    private _numeroPuntiAncoraggio = 21;

    private _puntoSezioneSelezionato = -1;
    private _refEditor: RefObject<HTMLDivElement> = React.createRef();

    public constructor(props: Readonly<EditorSezioneSuperficieProps> | EditorSezioneSuperficieProps) {
        super(props);
        this.state = {
            grabbing: false
        }
    }

    //region Calcolo

    private _calcolaPosizioneXPuntoPoligono(posizioneX: number): string{
        return `${((posizioneX + 1) / 2) * 100}%`;
    }

    private _calcolaPosizioneYPuntoPoligono(posizioneY: number): string{
        return `${((posizioneY + 1) / 2) * 100}%`;
    }

    private _calcolaPrimoPuntoVicino(posizioneXMouse: number, posizioneYMouse: number){
        if(this._puntoSezioneSelezionato !== -1){
            if(this._refEditor.current){
                const current = this._refEditor.current;
                const rect = current.getBoundingClientRect();

                let xPercentuale = ((posizioneXMouse - rect.x) / rect.width) * 100;
                let yPercentuale = ((posizioneYMouse - rect.y) / rect.height) * 100;

                if(xPercentuale < 0)
                    xPercentuale = 0;
                if(xPercentuale > 100)
                    xPercentuale = 100;
                if(yPercentuale < 0)
                    yPercentuale = 0;
                if(yPercentuale > 100)
                    yPercentuale = 100;

                const percentualeDivisione = 100 / (this._numeroPuntiAncoraggio - 1);

                const puntoXVicino = Math.round(xPercentuale / percentualeDivisione) / (this._numeroPuntiAncoraggio - 1);
                const puntoYVicino = Math.round(yPercentuale / percentualeDivisione) / (this._numeroPuntiAncoraggio - 1);

                this.props.puntiSezioneSuperficie[this._puntoSezioneSelezionato] = {x: puntoXVicino * 2 - 1, y: 0, z: puntoYVicino * 2 - 1};
                this.props.onPuntiSezioneChange && this.props.onPuntiSezioneChange(this.props.puntiSezioneSuperficie);
            }
        }
    }

    //endregion

    //region Beam

    public get aspectRatioSezioneSuperficie(): string{
        return `${this.larghezzaSezioneSuperficie} / ${this.altezzaSezioneSuperficie}`;
    }

    public get altezzaSezioneSuperficie(): number{
        return Math.abs(this.props?.altezzaSezioneSuperficie || 0);
    }

    public get larghezzaSezioneSuperficie(): number{
        return Math.abs(this.props?.larghezzaSezioneSuperficie || 0);
    }

    public get clipPathShape(): string{
        return `polygon(${this.props.puntiSezioneSuperficie.map(punto => `${this._calcolaPosizioneXPuntoPoligono(punto.x)} ${this._calcolaPosizioneYPuntoPoligono(punto.z)}`).join(',')})`;
    }

    //endregion

    public render() {
        return (
            <div
                ref={this._refEditor}
                className={"EditorSezioneSuperficieContainer"}
                style={{
                    aspectRatio: this.aspectRatioSezioneSuperficie
                }}
                onMouseMove={event => this._calcolaPrimoPuntoVicino(event.clientX, event.clientY)}
                onMouseUp={() => {
                    this._puntoSezioneSelezionato = -1;
                    this.setState({grabbing: false});
                }}>
                {
                    new Array(this._numeroPuntiAncoraggio * this._numeroPuntiAncoraggio).fill(0).map((punto, index) => {
                        const y = Math.floor(index / this._numeroPuntiAncoraggio);
                        const x = index - (y * this._numeroPuntiAncoraggio);
                        return (
                            <span
                                key={`PuntoEditor-${index}`}
                                style={{
                                    aspectRatio: "1 / 1",
                                    backgroundColor: 'gray',
                                    width: '5px',
                                    position: 'absolute',
                                    borderRadius: '50%',
                                    transform: 'translateX(-50%) translateY(-50%)',
                                    top: `${(y / (this._numeroPuntiAncoraggio - 1)) * 100}%`,
                                    left: `${(x / (this._numeroPuntiAncoraggio - 1)) * 100}%`
                                }}/>
                        );
                    })
                }
                <span
                    className={"ShapeSezioneSuperficie"}
                    style={{clipPath: this.clipPathShape}}/>
                {this.props.puntiSezioneSuperficie.map((puntoSezione, index) =>
                    <span
                        key={`PuntoSezione-${index}`}
                        className={`PuntoPoligonoSezioneSuperficie ${this.state.grabbing && 'grabbing'}`}
                        style={{
                            top: this._calcolaPosizioneYPuntoPoligono(puntoSezione.z),
                            left: this._calcolaPosizioneXPuntoPoligono(puntoSezione.x)
                        }}
                        onMouseDown={() => {
                            this._puntoSezioneSelezionato = index;
                            this.setState({grabbing: true});
                        }}
                        onMouseUp={() => {
                            this._puntoSezioneSelezionato = -1;
                            this.setState({grabbing: false});
                        }}>
                        {index + 1}
                    </span>
                )}
            </div>
        );
    }
}
