//QG 30/06/2024 MD

import React, {Fragment} from "react";
import "./ConfiguratoreModelli.scss";

import ConfiguratoreModelliMenu, {ConfiguratoreModelliMenuType} from "./ConfiguratoreModelliMenu";
import VerticalSpace from "../../../../Layout/VerticalSpace/VerticalSpace";
import CaricaModello, {CaricaModelloProps} from "./Sezioni/CaricaModello";
import {CaricaModelloResponseInterface} from "../../../../Primary/GestioneModelli/GestioneModelliCommonFunction";
import ManagerRenderizzatoreModelli from "../../../../Primary/ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import ConfiguraModello from "./Sezioni/ConfiguraModello";
import ConfiguraSezioni from "./Sezioni/ConfiguraSezioni";
import {ConfiguraModelloTopLevelProps} from "./Sezioni/ConfiguraModelloTopLevel";
import {ConfiguraSezioneTopLevelProps} from "./Sezioni/ConfiguraSezioneTopLevel";
import ConfigurazioneRidimensionamento from "./Sezioni/ConfigurazioneRidimensionamento";
import ConfiguraGiunti from "./Sezioni/ConfiguraGiunti";
import ConfiguraAnimazioni from "./Sezioni/ConfiguraAnimazioni";
import ConfigurazioneVista from "./Sezioni/ConfigurazioneVista";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import SwitchContainer from "../../../../Layout/SwitchContainer/SwitchContainer";
import {NomeSezioneInformazioneType} from "tici_commons";
import StorageManager from "../../Configuratore/Storage/StorageManager";
import ConfiguratoreModelliModalita from "./ConfiguratoreModelliModalita";
import OverflowTag from "../../../../Layout/OverflowTag/OverflowTag";
import ModalitaTest from "./Sezioni/ModalitaTest";
import {
    ConfigurazioneGestore3DBridgeContext,
    ConfigurazioneGestore3DBridgeState
} from "../Bridge/ConfigurazioneGestore3DBridge";

export interface ConfiguratoreModelliState extends
    Omit<CaricaModelloProps, "onModelloUpload">,
    ConfiguraModelloTopLevelProps,
    ConfiguraSezioneTopLevelProps {
    renderCounter: number,
    selectedCategory: ConfiguratoreModelliMenuType,
    modelloCaricato: CaricaModelloResponseInterface,

    modalitaAlternativaModello: boolean,
    animazioneSelezionata: string,
    formatoSelezionato: string

    datiSezioni: NomeSezioneInformazioneType[]
}

export default class ConfiguratoreModelli extends React.Component<{}, ConfiguratoreModelliState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            configurazioneDaBridge: false,

            renderCounter: 0,
            selectedCategory: 'Configura dimensionamento',
            modelloCaricato: undefined,

            nomeModelloCaricato: '',

            modelloConfigurabile: undefined,
            sezioniConfigurabili: undefined,

            modalitaAlternativaModello: false,
            animazioneSelezionata: "",
            formatoSelezionato: "",

            datiSezioni: []
        }
    }

    public componentDidMount() {
        const context = this.context as ConfigurazioneGestore3DBridgeState;
        if(context.hasData()){
            this.setState({
                configurazioneDaBridge: true
            });
        }
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<ConfiguratoreModelliState>) {
        if(prevState.modalitaAlternativaModello !== this.state.modalitaAlternativaModello && this.state.modelloConfigurabile){
            const modelloConfigurabile = this.state.modelloConfigurabile;
            modelloConfigurabile.modalitaVisualizzazioneAlternativa = this.state.modalitaAlternativaModello;
        }
    }

    private _uploadModello(nomeModello: string, modelloCaricato: CaricaModelloResponseInterface){
        this.setState({
            nomeModelloCaricato: nomeModello,
            modelloCaricato,
        });
    }

    private _updateRenderView(){
        this.setState(currentState => ({renderCounter: currentState.renderCounter + 1}));
    }

    private _cambiaCategoriaMenu(nuovaCategoria: ConfiguratoreModelliMenuType){
        if(this.state.modelloConfigurabile)
            this.state.modelloConfigurabile.resetVisualizzazioni();
        this.setState({selectedCategory: nuovaCategoria});
    }

    //region Mapper

    private _mappaCategoria(nomeCategoria: ConfiguratoreModelliMenuType): number{
        return ([
            "Configura dimensionamento",
            "Configura modello",
            "Configura vista",
            "Configura sezioni",
            "Configura giunti",
            "Configura animazioni",
            "Test"
        ] as ConfiguratoreModelliMenuType[]).findIndex(elemento => elemento === nomeCategoria);
    }

    //endregion

    //region Beam

    public get aspectRatioModello(): string{
        let esito = "1 / 1";

        if(this.state.formatoSelezionato && this.state.selectedCategory === 'Test'){
            const splittedValue = this.state.formatoSelezionato.split('x');
            if(splittedValue.length === 2)
                esito = `${splittedValue[0]} / ${splittedValue[1]}`;
        }

        return esito;
    }

    //endregion

    public render() {
        return (
            <Fragment>
                <CaricaModello
                    {...this.state}
                    onModelloUpload={this._uploadModello.bind(this)}>
                    <IfContainer condition={!!this.state.modelloCaricato || this.state.configurazioneDaBridge}>
                        <ConfiguratoreModelliMenu
                            selectedCategory={this.state.selectedCategory}
                            onChangeCategory={nuovaCategoria => this._cambiaCategoriaMenu(nuovaCategoria)}/>
                    </IfContainer>
                </CaricaModello>
                <VerticalSpace gapMobile={10} gapTablet={10} gapDesktop={10}/>
                <IfContainer condition={!!this.state.modelloCaricato || this.state.configurazioneDaBridge}>
                    <StorageManager>
                        <div className={`ContainerCondiviso ${this.state.selectedCategory === 'Test' && 'modalitaTest'}`}>
                            <ConfigurazioneGestore3DBridgeContext.Consumer>{
                                bridge => (
                                    <ManagerRenderizzatoreModelli
                                        modalitaSupportoVisivo={this.state.selectedCategory !== 'Test'}
                                        modalitaVisualizzazioneAlternativa={this.state.modalitaAlternativaModello}
                                        visualizzaPulsanteDiApertura={true}
                                        intercettaModificaVisualizzazione={this.state.selectedCategory === 'Test'}
                                        intercettaEventiClick={this.state.selectedCategory === 'Test'}
                                        datiSezioni={this.state.datiSezioni}
                                        onDatiSezioniChange={nuoveInformazioni => this.setState({datiSezioni: nuoveInformazioni})}
                                        modelloCaricato={!this.state.configurazioneDaBridge && this.state.modelloCaricato}
                                        bridgeModello={bridge}
                                        aspectRatioCanvas={this.state.selectedCategory === 'Test' ? "3 / 1" : "3 / 2"}
                                        aspectRatioModello={this.aspectRatioModello}
                                        nomeManager={"ConfigurazioneModello"}
                                        onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => this.setState({modelloConfigurabile, sezioniConfigurabili})}/>
                                )
                            }</ConfigurazioneGestore3DBridgeContext.Consumer>
                            <IfContainer
                                condition={!!this.state.modelloConfigurabile && !!this.state.sezioniConfigurabili}>
                                <div className={"ContainerConfigurazione"}>
                                    <IfContainer condition={this.state.selectedCategory !== 'Test'}>
                                        <OverflowTag/>
                                        <ConfiguratoreModelliModalita
                                            {...this.state}
                                            onModalitaVisualizzazioneAlternativaChange={modalitaAlternativaModello => this.setState({modalitaAlternativaModello})}
                                            visualizzaBoxRiproduzioneAnimazione={this.state.selectedCategory !== 'Configura animazioni'}/>
                                    </IfContainer>
                                    <SwitchContainer caseNumber={this._mappaCategoria(this.state.selectedCategory)}>
                                        <ConfigurazioneRidimensionamento {...this.state}/>
                                        <ConfiguraModello {...this.state}/>
                                        <ConfigurazioneVista {...this.state}/>
                                        <ConfiguraSezioni {...this.state}/>
                                        <ConfiguraGiunti {...this.state} updateRenderView={this._updateRenderView.bind(this)}/>
                                        <ConfiguraAnimazioni {...this.state} updateRenderView={this._updateRenderView.bind(this)}/>
                                        <ModalitaTest
                                            {...this.state}
                                            onModalitaAlternativaModelloChange={modalitaAlternativaModello => this.setState({modalitaAlternativaModello})}
                                            onFormatoSelezionatoChange={formatoSelezionato => this.setState({formatoSelezionato})}/>
                                    </SwitchContainer>
                                </div>
                            </IfContainer>
                        </div>
                    </StorageManager>
                </IfContainer>
            </Fragment>
        )
    }
}

ConfiguratoreModelli.contextType = ConfigurazioneGestore3DBridgeContext;
