//QG 30/06/2024 MD

import React, {Fragment} from "react";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {CheckLine, ScrollZone, ScrollZoneElement,} from "../../../../../Widgets/Configuratore/CComponents";
import {ImmagineAmmessaEnum, ImmagineAmmessaValues, TipologiaMaterialeSupportatoValues} from "tici_commons";
import ConfiguraSezioneTopLevel, {ConfiguraSezioneTopLevelProps, ConfiguraSezioneTopLevelState} from "./ConfiguraSezioneTopLevel";
import {EditorSezioneSuperficie, InputContainer, TripleLine} from "../ConfiguratoreModelliComponents";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Button from "../../../../../Core/Buttons/Button";
import DefaultInput from "../../../../../Core/DefaultInput/DefaultInput";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import {imageTypeSupported} from "../../../../../Utils/CommonFunctions";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";

export interface ConfiguraSezioniState extends ConfiguraSezioneTopLevelState{
    famigliaSelezionata: string
}

export default class ConfiguraSezioni extends ConfiguraSezioneTopLevel<ConfiguraSezioneTopLevelProps, ConfiguraSezioniState>{
    constructor(props: Readonly<ConfiguraSezioneTopLevelProps> | ConfiguraSezioneTopLevelProps) {
        super(props);
        this.state = {
            sezioneSelezionata: "",
            famigliaSelezionata: "",
            sezioneConfigurabileSelezionata: undefined
        }
    }

    private _SezioneConfigurazione(){
        return (
            <CategorySectionLayout categoryLabel={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeVisualizzato} size={"largeRelative"}>
                <InputContainer label={"Nome sezione"}>
                    <DefaultInput
                        value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeVisualizzato}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('nomeVisualizzato', v)}/>
                </InputContainer>
                <InputContainer label={"Colore sezione"}>
                    <DefaultInput
                        value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.coloreSezione.replaceAll('0x', '#')}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('coloreSezione', v)}
                        type={"color"}/>
                </InputContainer>
                <InputContainer label={"Gruppo vincolante"}>
                    <DefaultInput
                        value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeGruppo}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('nomeGruppo', v)}/>
                </InputContainer>
                <IfContainer condition={this.RecuperaListaGruppiVincolanti().length > 0}>
                    <InputContainer label={"Gruppi esistenti"}>
                        <ElementSelect
                            label={"Seleziona"}
                            elements={this.RecuperaListaGruppiVincolanti().map(gruppo => ({label: gruppo}))}
                            value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeGruppo}
                            onChange={v => this.ImpostaValoreConfigurazioneSezione('nomeGruppo', v)}/>
                    </InputContainer>
                </IfContainer>
                <InputContainer label={"Sezione di riferimento"}>
                    <ElementSelect
                        label={"Seleziona"}
                        elements={this.ListaSezioniConfigurabili()}
                        value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeSezioneRiferimentoInformazioni}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('nomeSezioneRiferimentoInformazioni', v)}/>
                </InputContainer>
                <InputContainer label={"Immagine ammessa"}>
                    <ElementSelect
                        label={"Seleziona"}
                        elements={ImmagineAmmessaValues.map(value => ({label: value}))}
                        value={ImmagineAmmessaValues[this.state.sezioneConfigurabileSelezionata.configurazioneSezione.immagineAmmessa]}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('immagineAmmessa', ImmagineAmmessaValues.findIndex(value => value === v))}/>
                </InputContainer>
                <IfContainer condition={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.immagineAmmessa === ImmagineAmmessaEnum.IMMAGINE}>
                    <CheckLine
                        label={"Sezione principale per l'immagine"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneImmaginePrincipale}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneImmaginePrincipale', v)}/>
                </IfContainer>
                <InputContainer label={"Materiale supportato"}>
                    <ElementSelect
                        label={"Seleziona"}
                        elements={TipologiaMaterialeSupportatoValues.map(value => ({label: value}))}
                        value={TipologiaMaterialeSupportatoValues[this.state.sezioneConfigurabileSelezionata.configurazioneSezione.tipologiaMaterialeSupportato]}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('tipologiaMaterialeSupportato', TipologiaMaterialeSupportatoValues.findIndex(value => value === v))}/>
                </InputContainer>
                <InputContainer label={"Trasparenza sezione"}>
                    <DefaultInput
                        min={0}
                        max={100}
                        type={'number'}
                        value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.livelloTrasparenza.toString()}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('livelloTrasparenza', parseFloat(v))}/>
                </InputContainer>
                <CheckLine
                    label={"Ruota alla rotazione alternativa del modello"}
                    checkType={"checkbox"}
                    reduce={true}
                    checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneRuotaAllaRotazioneModelloAlternativa}
                    onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneRuotaAllaRotazioneModelloAlternativa', v)}/>
            </CategorySectionLayout>
        )
    }

    private _SezioneConfigurazioneSuperficie(){
        return (
            <CategorySectionLayout categoryLabel={"Superficie di applicazione"} size={"largeRelative"}>
                <CheckLine
                    label={"Sezione poligonale"}
                    checkType={"checkbox"}
                    reduce={true}
                    checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneSuperficiePoligonaleFlag}
                    onChange={v => {
                        this.ImpostaValoreConfigurazioneSezione('sezioneSuperficiePoligonaleFlag', v);
                        const puntiGenerici = EditorSezioneSuperficie.GeneraPuntiNeutriSuperficie(this.state.sezioneConfigurabileSelezionata.configurazioneSezione.puntiPoligonaliSezioneSuperficie.length);
                        this.ImpostaValoreConfigurazioneSezione('puntiPoligonaliSezioneSuperficie', puntiGenerici);
                    }}/>
                <IfContainer condition={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneSuperficiePoligonaleFlag}>
                    <SectionLayout size={"largeRelative"}>
                        <EditorSezioneSuperficie
                            larghezzaSezioneSuperficie={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.dimensioneSezioneSuperficie.z}
                            altezzaSezioneSuperficie={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.dimensioneSezioneSuperficie.x}
                            puntiSezioneSuperficie={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.puntiPoligonaliSezioneSuperficie}
                            quantitaPuntiSuperficie={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.puntiPoligonaliSezioneSuperficie.length === 0 ? 3 : this.state.sezioneConfigurabileSelezionata.configurazioneSezione.puntiPoligonaliSezioneSuperficie.length}
                            onPuntiSezioneChange={puntiSezioneSuperficie => this.ImpostaValoreConfigurazioneSezione('puntiPoligonaliSezioneSuperficie', puntiSezioneSuperficie)}/>
                        <ResponsiveLabel
                            content={`Punti poligonali (${this.state.sezioneConfigurabileSelezionata.configurazioneSezione.puntiPoligonaliSezioneSuperficie.length})`}
                            type={"medium"}
                            alignment={"left"}/>
                        <input
                            style={{width: "100%"}}
                            type={"range"}
                            min={3}
                            max={10}
                            step={1}
                            value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.puntiPoligonaliSezioneSuperficie.length}
                            onChange={v => {
                                let integerValue = parseInt(v.target.value);
                                if(integerValue < 3)
                                    integerValue = 3;
                                if(integerValue > 10)
                                    integerValue = 10;
                                const puntiGenerici = EditorSezioneSuperficie.GeneraPuntiNeutriSuperficie(integerValue);
                                this.ImpostaValoreConfigurazioneSezione('puntiPoligonaliSezioneSuperficie', puntiGenerici);
                            }}/>
                        <CheckLine
                            label={"Specchia su asse X"}
                            checkType={"checkbox"}
                            reduce={true}
                            checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.specchiaAsseXSezioneSuperficie}
                            onChange={v => this.ImpostaValoreConfigurazioneSezione('specchiaAsseXSezioneSuperficie', v)}/>
                        <CheckLine
                            label={"Specchia su asse Y"}
                            checkType={"checkbox"}
                            reduce={true}
                            checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.specchiaAsseYSezioneSuperficie}
                            onChange={v => this.ImpostaValoreConfigurazioneSezione('specchiaAsseYSezioneSuperficie', v)}/>
                    </SectionLayout>
                </IfContainer>
                <TripleLine
                    label={"Offset superficie di applicazione (X, Y, Z)"}
                    xValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetSezioneSuperficie.x}
                    yValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetSezioneSuperficie.y}
                    zValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetSezioneSuperficie.z}
                    onChangeValue={(x, y, z) => this.ImpostaValoreConfigurazioneSezione('offsetSezioneSuperficie', {x, y, z})}/>
                <TripleLine
                    label={"Dimensione superficie di applicazione (X, Y, Z)"}
                    xValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.dimensioneSezioneSuperficie.x}
                    yValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.dimensioneSezioneSuperficie.y}
                    zValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.dimensioneSezioneSuperficie.z}
                    onChangeValue={(x, y, z) => this.ImpostaValoreConfigurazioneSezione('dimensioneSezioneSuperficie', {x, y, z})}/>
                <TripleLine
                    label={"Rotazione superficie di applicazione (X, Y, Z)"}
                    xValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.rotazioneSezioneSuperficie.x}
                    yValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.rotazioneSezioneSuperficie.y}
                    zValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.rotazioneSezioneSuperficie.z}
                    onChangeValue={(x, y, z) => this.ImpostaValoreConfigurazioneSezione('rotazioneSezioneSuperficie', {x, y, z})}/>
            </CategorySectionLayout>
        )
    }

    private _SezioneFlag(){
        return (
            <CategorySectionLayout categoryLabel={"Configurazione sezione"} size={"largeRelative"}>
                <CheckLine
                    label={"Sezione non visibile"}
                    checkType={"checkbox"}
                    reduce={true}
                    checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneNonVisibileFlag}
                    onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneNonVisibileFlag', v)}/>
                <IfContainer condition={!this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneNonVisibileFlag}>
                    <CheckLine
                        label={"Sezione obbligatoria"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneObbligatoriaFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneObbligatoriaFlag', v)}/>
                    <CheckLine
                        label={"Sezione ammette UV"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneAmmetteUvFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneAmmetteUvFlag', v)}/>
                    <CheckLine
                        label={"Sezione legno"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneLegnoFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneLegnoFlag', v, true)}/>
                    <CheckLine
                        label={"Sezione plex"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezionePlexFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezionePlexFlag', v, true)}/>
                    <CheckLine
                        label={"Sezione colore bianco nero"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneColoreBiancoNeroFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneColoreBiancoNeroFlag', v, true)}/>
                    <CheckLine
                        label={"Sezione colore"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneColoreFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneColoreFlag', v, true)}/>
                    <CheckLine
                        label={"Sezione contenitrice"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneContenitriceFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneSezione('sezioneContenitriceFlag', v, true)}/>
                    <InputContainer label={"Singola immagine"}>
                        <DefaultInput
                            type={'file'}
                            value={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.singolaImmagineName}
                            onFileUpload={(fileName, fileType, file, base64Encoding) => {
                                if(imageTypeSupported(fileType)) {
                                    this.ImpostaValoreConfigurazioneSezione('singolaImmagineName', fileName, true);
                                    this.ImpostaValoreConfigurazioneSezione('singolaImmagineBase64', base64Encoding);
                                }
                            }}/>
                    </InputContainer>
                </IfContainer>
            </CategorySectionLayout>
        )
    }

    private _SezioneFamiglieEscluse(){
        return (
            <CategorySectionLayout categoryLabel={"Famiglie escluse da includere"} size={"largeRelative"}>
                <IfContainer
                    condition={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.famiglieMaterialiIncluse.length > 0}
                    elseComponent={
                        <ResponsiveLabel content={"Nessuna famiglia esclusa dal modello é inclusa nella sezione"} type={"medium"} alignment={"center"}/>
                    }>
                    <ScrollZone>{
                        this.state.sezioneConfigurabileSelezionata.configurazioneSezione.famiglieMaterialiIncluse.map(famigliaInclusa => (
                            <ScrollZoneElement content={famigliaInclusa.toUpperCase()} onDeleteClick={() => {
                                const nuovaListaFamiglie = this.state.sezioneConfigurabileSelezionata.configurazioneSezione.famiglieMaterialiIncluse.filter(famiglia => famiglia !== famigliaInclusa);
                                this.ImpostaValoreConfigurazioneSezione('famiglieMaterialiIncluse', nuovaListaFamiglie);
                            }}/>
                        ))
                    }</ScrollZone>
                </IfContainer>
                <InputContainer label={"Seleziona famiglia esclusa da includere"}>
                    <ElementSelect
                        label={"Seleziona"}
                        value={this.state.famigliaSelezionata}
                        elements={this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse
                            .filter(famigliaEsclusa => !this.state.sezioneConfigurabileSelezionata.configurazioneSezione.famiglieMaterialiIncluse.includes(famigliaEsclusa))
                            .map(famigliaEsclusa => ({label: famigliaEsclusa}))}
                        onChange={famigliaSelezionata => this.setState({famigliaSelezionata})}/>
                </InputContainer>
                <Button
                    content={"Includi famiglia"}
                    type={"medium"}
                    buttonType={"full-normal"}
                    disabled={this.state.famigliaSelezionata.length === 0}
                    onClick={() => {
                        this.ImpostaValoreConfigurazioneSezione("famiglieMaterialiIncluse", [...this.state.sezioneConfigurabileSelezionata.configurazioneSezione.famiglieMaterialiIncluse, this.state.famigliaSelezionata]);
                        this.setState({famigliaSelezionata: ""});
                    }}/>
            </CategorySectionLayout>
        )
    }

    public render() {
        return (
            <Fragment>
                <CategorySectionLayout categoryLabel={"Lista sezioni"} size={"largeRelative"}>
                    <InputContainer label={"Seleziona sezione"}>
                        <ElementSelect
                            label={"Seleziona"}
                            value={this.state.sezioneSelezionata}
                            onChange={v => this.SelezionaSezioneConfigurabile(v, true)}
                            elements={this.ListaSezioniConfigurabili()}/>
                    </InputContainer>
                </CategorySectionLayout>
                {
                    !!this.state.sezioneSelezionata &&
                    <IfContainer
                        condition={!this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeSezioneRiferimentoInformazioni}
                        elseComponent={
                            <CategorySectionLayout
                                categoryLabel={"this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeVisualizzato"}
                                size={"largeRelative"}>
                                <ResponsiveLabel
                                    content={`Sezione collegata a "${this.state.sezioneConfigurabileSelezionata.configurazioneSezione.nomeSezioneRiferimentoInformazioni}"`}
                                    type={"medium"}
                                    alignment={"left"}/>
                                <Button
                                    content={"Rimuovi sezione di riferimento"}
                                    type={'medium'}
                                    buttonType={"full-normal"}
                                    onClick={() => this.ImpostaValoreConfigurazioneSezione('nomeSezioneRiferimentoInformazioni', '')}/>
                            </CategorySectionLayout>
                        }>
                        {this._SezioneConfigurazione()}
                        {
                            (
                                this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioneAmmetteUvFlag ||
                                ![ImmagineAmmessaEnum.IMMAGINE, ImmagineAmmessaEnum.NO_IMMAGINI].includes(this.state.sezioneConfigurabileSelezionata.configurazioneSezione.immagineAmmessa)
                            ) && this._SezioneConfigurazioneSuperficie()
                        }
                        {this._SezioneFlag()}
                        {this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse.length > 0 && this._SezioneFamiglieEscluse()}
                    </IfContainer>
                }
            </Fragment>
        );
    }
}
