//QG 30/06/2024 MD

import React, {Fragment} from "react";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import Button from "../../../../../Core/Buttons/Button";
import {InputContainer} from "../ConfiguratoreModelliComponents";
import DefaultInput from "../../../../../Core/DefaultInput/DefaultInput";
import {ConfiguraModelloTopLevelProps} from "./ConfiguraModelloTopLevel";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import {ConfigurazioneGestore3DBridgeContext} from "../../Bridge/ConfigurazioneGestore3DBridge";
import RedirectService from "../../../../../Servicies/RedirectService";

export interface ModalitaTestProps extends ConfiguraModelloTopLevelProps{
    configurazioneDaBridge: boolean,
    formatoSelezionato: string,
    modalitaAlternativaModello: boolean,
    onModalitaAlternativaModelloChange: (modalitaAlternativaModello: boolean) => void,
    onFormatoSelezionatoChange: (formatoSelezionato: string) => void
}

export default class ModalitaTest extends React.Component<ModalitaTestProps, {}>{
    public render() {
        return (
            <Fragment>
                <SectionLayout size={"smallRelative"} center={true}>
                    <Button
                        content={`Cambia modalità ${this.props.modalitaAlternativaModello ? '(ALTERNATIVA)' : '(NORMALE)'}`}
                        type={"xmedium"}
                        buttonType={"full-normal"}
                        onClick={() => this.props.onModalitaAlternativaModelloChange && this.props.onModalitaAlternativaModelloChange(!this.props.modalitaAlternativaModello)}/>
                    <InputContainer label={"Formato"}>
                        <DefaultInput
                            type={"text"}
                            value={this.props.formatoSelezionato}
                            placeHolder={"NxM"}
                            onChange={formatoSelezionato => this.props.onFormatoSelezionatoChange && this.props.onFormatoSelezionatoChange(formatoSelezionato)}/>
                    </InputContainer>
                    <ConfigurazioneGestore3DBridgeContext.Consumer>{
                        bridge => (
                            <IfContainer
                                condition={!this.props.configurazioneDaBridge}
                                elseComponent={
                                    <Button
                                        content={"Accetta modifiche"}
                                        type={"xmedium"}
                                        buttonType={"full-normal"}
                                        onClick={() => {
                                            bridge.setPayload({
                                                ...bridge.payload,
                                                nomeConfigurazioneJSON: 'Attualmente modificata',
                                                configurazione: this.props.modelloConfigurabile.configurazioneTotaleModello
                                            });
                                            RedirectService.GoTo("GestioneModelli3D", "/homepage/gestione_modelli_3d");
                                        }}/>
                                }>
                                <Button
                                    content={"Salva configurazione"}
                                    type={"xmedium"}
                                    buttonType={"full-normal"}
                                    onClick={() => {
                                        const element = document.createElement('a');
                                        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.props.modelloConfigurabile.configurazioneTotaleModello, undefined, 2)));
                                        element.setAttribute('download', 'config.json');
                                        element.click();
                                    }}/>
                            </IfContainer>
                        )
                    }</ConfigurazioneGestore3DBridgeContext.Consumer>
                </SectionLayout>
            </Fragment>
        );
    }
}
