import React, {Fragment} from "react";
import DatePicker, {DatePickerData, DatePickerDataStatus} from "../../../Primary/DatePicker/DatePicker";
import {AmministratoreContextResponseModel, DettaglioOrdineModel, TipoFiltroType} from "tici_commons";
import Fr2frLayout from "../../../Layout/Fr2frLayout/Fr2frLayout";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import AutoRefreshComponent from "../../../Core/Arch/AutoRefreshComponent";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import OrdineLine from "../../../Primary/OrdineLine/OrdineLine";
import ChatContainer from "../../../Primary/ChatOverlay/ChatContainer/ChatContainer";
import OrdineService from "../../../Servicies/OrdineService";
import LocalStorageService from "../../../Servicies/LocalStorageService";
import OrdiniMenu from "./OrdiniMenu";
import OrdiniLoader from "./OrdiniLoader";
import Button from "../../../Core/Buttons/Button";
import TwoSpaceLine from "../../../Layout/TwoSpaceLine/TwoSpaceLine";
import DefaultInput from "../../../Core/DefaultInput/DefaultInput";
import SearchLayout from "../../../Layout/SearchLayout/SearchLayout";
import VerticalSpace from "../../../Layout/VerticalSpace/VerticalSpace";
import OverflowTag from "../../../Layout/OverflowTag/OverflowTag";
import Pagination from "../../../Primary/Pagination/Pagination";

export type SezioneOrdiniType = "Inviato" | "Errore" | "Confermato" | "TotaleOridni" | "ListaTotale" | "Ricerca" | "Dettaglio";

export interface OrdiniProps{
    amministrazioneContext: AmministratoreContextResponseModel
}

export interface OrdiniState{
    sezioneCorrente: SezioneOrdiniType,
    dataCorrente: DatePickerData;
    statoDatePicker: DatePickerDataStatus[],
    listaOrdini: DettaglioOrdineModel[],
    idOrdineAperto: number

    filtroRicerca: string,
    idOrdineDettaglio: number,
    isLoading: boolean
}

export default class Ordini extends AutoRefreshComponent<OrdiniProps, OrdiniState>{
    private _cicloControlloDettaglioOrdine: number;
    private _aggiornamento: boolean = false;

    constructor(props: Readonly<OrdiniProps> | OrdiniProps) {
        super(props);
        this.Delay = 180000; //Tutti gli aggiornamenti avverranno ogni 3 minuti
        this.state = {
            sezioneCorrente: "Inviato",
            dataCorrente: DatePicker.GenerateCurrentData(),
            statoDatePicker: [],
            listaOrdini: [],
            idOrdineAperto: undefined,

            filtroRicerca: "",
            idOrdineDettaglio: undefined,
            isLoading: true
        }
    }

    public componentDidMount() {
        super.componentDidMount();
        this._checkSessionStorageForSearch();
        this._cicloControlloDettaglioOrdine = window.setInterval(() => this._checkSessionStorageForSearch(), 200);
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.clearInterval(this._cicloControlloDettaglioOrdine);
    }

    public componentDidUpdate(prevProps: Readonly<OrdiniProps>, prevState: Readonly<OrdiniState>) {
        //Aggiornamento per passaggio
        if(this.state.sezioneCorrente !== prevState.sezioneCorrente && ['Dettaglio', 'Ricerca'].includes(prevState.sezioneCorrente)) {
            this._getOrdini(true);
        }

        //Aggiornamento per selezione della data
        if(JSON.stringify(this.state.dataCorrente) !== JSON.stringify(prevState.dataCorrente)){
            this._getOrdini(true);
        }

        //Gestisce il cambio di id ordine
        if(
            (this.state.sezioneCorrente === 'Dettaglio' && this.state.idOrdineDettaglio !== prevState.idOrdineDettaglio) ||
            (this.state.sezioneCorrente === 'Dettaglio' && prevState.sezioneCorrente !== 'Dettaglio')
        ){
            this._getOrdini(true);
        }

        //Gestisce il cambio del valore di ricerca
        if(this.state.sezioneCorrente === 'Ricerca' && prevState.sezioneCorrente !== 'Ricerca'){
            this.setState({listaOrdini: []})
        }

        if(this.state.sezioneCorrente === 'ListaTotale' && prevState.sezioneCorrente !== 'ListaTotale'){
            this._getOrdini(true);
        }
    }

    /**
     * Aggirona le date degli ordini
     */
    public cycleDateOrdini = async () => {
        if(this.state && this.state.sezioneCorrente !== "Ricerca"){
            this.setState({
                statoDatePicker: (await OrdineService.RecuperaDateTimeOrdini()).map(responseElement => {
                    const dataElements = responseElement.dataTicket.split("-");
                    const status = responseElement.statoConferma === 1;

                    return {
                        day: parseInt(dataElements[2]),
                        month: parseInt(dataElements[1]),
                        year: parseInt(dataElements[0]),
                        status: status
                    }
                })
            });
        }
    }

    /**
     * Recupera tutti gli ordini minimi dal database
     */
    public cycleOrdini = async () => {
        if(this.state.sezioneCorrente !== 'Ricerca'){
            await this._getOrdini(false);
        }
    }

    /**
     * Controlla il session storage alla ricerca di un ordine preciso
     * @private
     */
    private _checkSessionStorageForSearch(){
        const idOrdineDettaglio = LocalStorageService.RedirectOrdineNotifica;
        if(idOrdineDettaglio){
            LocalStorageService.RedirectOrdineNotifica = undefined;
            this.setState({
                sezioneCorrente: "Dettaglio",
                idOrdineDettaglio: idOrdineDettaglio,
                idOrdineAperto: idOrdineDettaglio
            })
        }
    }

    /**
     * Cambia la sezione corrente visualizzata
     * @param nuovaSezione Nuova sezione da visualizzare
     * @private
     */
    private _cambiaSezione(nuovaSezione: SezioneOrdiniType){
        if(!this._aggiornamento && this.state.sezioneCorrente !== nuovaSezione)
            this.setState({sezioneCorrente: nuovaSezione});
    }

    /**
     * Cambia la data corrente
     * @param nuovaData Nuova data da impostare
     * @private
     */
    private _cambiaDataCorrente(nuovaData: DatePickerData){
        if(!this._aggiornamento && JSON.stringify(this.state.dataCorrente) !== JSON.stringify(nuovaData)) {
            this.setState({dataCorrente: nuovaData});
        }
    }

    /**
     * Recupera gli ordini minimi per una determinata data
     * @private
     */
    private async _getOrdini(showLoading = true){
        if(!this._aggiornamento || this.state.sezioneCorrente === "Ricerca"){
            this._aggiornamento = true;
            if(showLoading)
                this.setState({isLoading: true});

            let filtro: string = `${this.state.dataCorrente.year}-${this.state.dataCorrente.month}-${this.state.dataCorrente.day}`;
            let tipoFiltro: TipoFiltroType = "Data";
            switch (this.state.sezioneCorrente){
                case "Ricerca":
                    tipoFiltro = 'Filtro';
                    filtro = this.state.filtroRicerca;
                    break;
                case "ListaTotale":
                    tipoFiltro = 'Filtro';
                    filtro = "";
                    break;
                case "Dettaglio":
                    tipoFiltro = 'Id';
                    filtro = `${this.state.idOrdineDettaglio}`;
                    break;
            }

            this.setState({
                listaOrdini: await OrdineService.DettaglioOrdini(tipoFiltro, filtro),
                isLoading: false
            });
            this._aggiornamento = false;
        }
    }

    /**
     * Filtro per la lista degli ordini
     * @param ordine Ordine da controllare per il filtraggio
     * @private
     */
    private _listaOrdiniFilter(ordine: DettaglioOrdineModel): boolean{
        let esito = true;

        switch (this.state.sezioneCorrente){
            case "Inviato":
                esito = ordine.statoConferma === 0 && ordine.statoErrore === 0
                break;
            case "Confermato":
                esito = ordine.statoConferma === 1 && ordine.statoErrore === 0
                break;
            case "Errore":
                esito = ordine.statoErrore === 1;
                break;
        }

        return esito;
    }

    /**
     * Restituisce la lista filtrata degli ordini
     * @private
     */
    private get _filteredList(): DettaglioOrdineModel[]{
        let esito = this.state.listaOrdini.filter(this._listaOrdiniFilter.bind(this));
        if(this.state.sezioneCorrente === "TotaleOridni"){
            const getPrioritaOrdine = (ordine: DettaglioOrdineModel): number => {
                let esito = 0;

                if(ordine.statoErrore === 0 && ordine.statoConferma === 0){
                    esito = 1;
                }else if(ordine.statoErrore === 1){
                    esito = 2;
                }else if(ordine.statoErrore === 0 && ordine.statoConferma === 1){
                    esito = 3;
                }

                return esito;
            }
            esito = esito.sort((a, b) => getPrioritaOrdine(a) - getPrioritaOrdine(b));
        }

        return esito;
    }

    private _listaOrdini(){
        return (
            <IfContainer
                condition={this._filteredList.length > 0}
                elseComponent={<ResponsiveLabel content={"Nessun ordine presente"} alignment={"center"} type={"large"}/>}>
                {Pagination.OverflowStructureMultiStato(
                    <Pagination paginationSize={10} elements={this._filteredList}>
                        {props => (
                            <SectionLayout size={"largeRelative"}>
                                <OverflowTag/>
                                {
                                    props.paginatedElements.map(
                                        ordine => (
                                            <OrdineLine
                                                key={`Ordine-${ordine.id}`}
                                                ordine={ordine}
                                                isOpen={this.state.idOrdineAperto === ordine.id}
                                                updateOrdini={() => {
                                                    this.cycleDateOrdini();
                                                    this._getOrdini(true);
                                                }}
                                                amministrazioneContext={this.props.amministrazioneContext}
                                                onClick={(idOrdine) => this.setState((prevState) => ({idOrdineAperto: prevState.idOrdineAperto === idOrdine ? undefined : idOrdine}))}/>
                                        )
                                    )
                                }
                            </SectionLayout>
                        )}
                    </Pagination>
                )}
            </IfContainer>
        )
    }

    public render() {
        return (
            <Fragment>
                <OrdiniMenu sezioneSelezionata={this.state.sezioneCorrente} cambiaSezione={nuovaSezione => this._cambiaSezione(nuovaSezione)}/>
                <ChatContainer>
                    <IfContainer condition={this.state.sezioneCorrente === 'Ricerca'}>
                        <TwoSpaceLine label={""}>
                            <SearchLayout>
                                <DefaultInput
                                    placeHolder={"Ordine, giorno, mese, anno, ..."}
                                    onChange={v => this.setState({filtroRicerca: v})}
                                    value={this.state.filtroRicerca}/>
                                <Button content={"Cerca"} type={"medium"} onClick={() => this._getOrdini(true)}/>
                            </SearchLayout>
                        </TwoSpaceLine>
                        <VerticalSpace gapMobile={5} gapTablet={8} gapDesktop={10}/>
                    </IfContainer>
                    <Fr2frLayout disabled={['Ricerca', 'Dettaglio', 'ListaTotale'].includes(this.state.sezioneCorrente)}>
                        <IfContainer condition={!['Ricerca', 'Dettaglio', 'ListaTotale'].includes(this.state.sezioneCorrente)}>
                            <DatePicker
                                selectedDate={this.state.dataCorrente}
                                status={this.state.statoDatePicker}
                                onChange={v => this._cambiaDataCorrente(v)}
                                fullWidthMobile={true}/>
                        </IfContainer>
                        <IfContainer
                            condition={this.state.isLoading}
                            elseComponent={this._listaOrdini()}>
                            <OrdiniLoader/>
                        </IfContainer>
                    </Fr2frLayout>
                </ChatContainer>
            </Fragment>
        );
    }
}
