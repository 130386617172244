import React from "react";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import TextArea from "../../../../../../Core/TextArea/TextArea";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";

export default class SezioneInformazioniAggiuntiveCustodia extends SezioneGenerica<"custodia", {}, {}> {
    public generaRiepilogo = (fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoInternalBox | RiepilogoData[] | RiepilogoBox | RiepilogoInternalBox[] => {
        return GenerateRiepilogoInternalBox(
            'Informazioni aggiuntive',
            ...GenerateGroup(
                fotolibro.custodia.noteAggiuntive.trim().length > 0,
                GenerateRiepilogoData(`Note: ${fotolibro.custodia.noteAggiuntive}`)
            )
        )
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Informazioni aggiuntive"} size={"largeRelative"} center={true}>
                <ResponsiveLabel content={"Note aggiuntive"} type={"medium"} alignment={"left"}/>
                <TextArea
                    maxLenght={300}
                    value={this.$.noteAggiuntive}
                    onChange={v => this.$.noteAggiuntive = v}/>
            </CategorySectionLayout>
        );
    }
}
