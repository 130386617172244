//QG 30/06/2024 MD

import './MenuRadiale.scss';
import React, {Fragment} from "react";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {toRadians} from "../../../GestioneModelli/Utils";

export interface MenuRadialeItem{
    key: string,
    value: string
}

export interface MenuRadialeProps{
    titoloMenuRadiale: string,
    elementiMenuRadiale: MenuRadialeItem[],
    sottotitoloMenuRadiale?: string,
    posizioneXApertura?: number,
    posizioneYApertura?: number,
    onClickElement?: (elemento: string) => void
}

export interface MenuRadialeState{
    ultimaPosizioneSelezionata: number,
}

export default class MenuRadiale extends React.Component<MenuRadialeProps, MenuRadialeState> {
    constructor(props: Readonly<MenuRadialeProps> | MenuRadialeProps) {
        super(props);
        this.state = {
            ultimaPosizioneSelezionata: 0
        }
    }

    private _createPoligonoSegmento(angoloSegmento: number, numeroStep = 10): string{
        let stringaEsito = "50% 50%,";

        const step = angoloSegmento / numeroStep;
        for(let i = 0; i <= numeroStep; i++){
            const angle = step * i;
            const xValue = 50 + (50 * Math.cos(toRadians(angle)));
            const yValue = 50 + (50 * Math.sin(toRadians(angle)));
            stringaEsito += ` ${xValue}% ${yValue}%,`;
        }

        return stringaEsito.substring(0, stringaEsito.length - 1);
    }

    private _Segmento(
        content: string,
        angoloPartenza: number,
        angoloSegmento: number,
        selected?: boolean,
        onHover?: () => void,
        onClick?: () => void
    ){
        return (
            <div
                style={{
                    "--startAngle": `${135 + angoloPartenza}deg`,
                    "--segmentAngle": `${angoloSegmento}deg`,
                    "clip-path": `polygon(${this._createPoligonoSegmento(angoloSegmento, angoloSegmento)})`
                } as any}
                className={`Segmento ${selected && 'selected'}`}
                onMouseOverCapture={() => onHover && onHover()}
                onClick={() => onClick && onClick()}>
                <span className={"PivotSegmento"}>
                    <span className={"ContentSegmento"}>
                        <ResponsiveLabel
                            content={content}
                            type={"medium"}
                            alignment={"left"}
                            uppercase={true}
                            bold={true}/>
                    </span>
                </span>
            </div>
        )
    }

    public render() {
        return (
            <div
                className={"MenuRadialeContainer"}
                style={{
                    '--posizioneXContainer': `${this.props.posizioneXApertura}px`,
                    '--posizioneYContainer': `${this.props.posizioneYApertura}px`
                } as any}>
                {this.props.elementiMenuRadiale.map((elemento, index) =>
                    <Fragment key={`Segmento-${index}`}>{
                        this._Segmento(
                            elemento.value,
                            60 * index,
                            60,
                            index === this.state.ultimaPosizioneSelezionata,
                            () => this.setState({ultimaPosizioneSelezionata: index}),
                            () => this.props.onClickElement && this.props.onClickElement(elemento.key)
                        )
                    }</Fragment>
                )}
                <span className={"TitoloMenu"}>
                    <ResponsiveLabel
                        content={this.props.titoloMenuRadiale}
                        type={"medium"}
                        alignment={"left"}
                        labelType={"whiteLabel"}/>
                    {
                        this.props.sottotitoloMenuRadiale &&
                        <ResponsiveLabel
                            content={this.props.sottotitoloMenuRadiale}
                            type={"xsmall"}
                            alignment={"left"}
                            labelType={"whiteLabel"}/>
                    }
                </span>
            </div>
        );
    }
}
