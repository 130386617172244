import './Impersonificazione.scss';

import {FotografoBaseResponseModel, Rest} from "tici_commons";
import React from "react";
import ImpersonificazioneService from "../../../Servicies/ImpersonificazioneService";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import {TextLine} from "../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";
import UserService from "../../../Servicies/UserService";
import ImageService from "../../../Servicies/ImageService";
import TopLevelComponentStorage from "../../../Core/Arch/TopLevelComponentStorage";
import RedirectService from "../../../Servicies/RedirectService";
import OverflowTag from "../../../Layout/OverflowTag/OverflowTag";
import Pagination from "../../../Primary/Pagination/Pagination";

export interface ImpersonificazioneState{
    listaFotografi: FotografoBaseResponseModel[],
    filtroFotografo: string,
    isLoadding: boolean
}

export default class Impersonificazione extends React.Component<{}, ImpersonificazioneState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            listaFotografi: [],
            filtroFotografo: "",
            isLoadding: true
        }

        ImpersonificazioneService.GetFotografi().then(response => this.setState({listaFotografi: response, isLoadding: false}));
    }

    /**
     * Restituisce la lista filtrata dei fotografi
     * @private
     */
    private _listaFotografiFiltrata(): FotografoBaseResponseModel[]{
        return this.state.listaFotografi.filter(fotografo => {
            const searchString = `${fotografo.nome}${fotografo.cognome}${fotografo.email}${fotografo.nomeStudio} ${fotografo.nome} ${fotografo.cognome} ${fotografo.email} ${fotografo.nomeStudio}`;
            return searchString.includes(this.state.filtroFotografo.trim());
        })
    }

    /**
     * Esegue l'impersonificazione del fotografo
     * @param emailFotografo Email del fotografo da impersonificare
     * @private
     */
    private async _impersonificaFotografo(emailFotografo: string){
        TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
            'Impersonificazione',
            `Vuoi impersonificare ${emailFotografo} ?`,
            'DoubleButton',
            async () => {
                TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow(
                    'Impersonificazione',
                    'Recuperiamo le informazioni per l\'impersonificazione'
                );
                const uid = await ImpersonificazioneService.Impersonifica(emailFotografo);
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                if(uid){
                    Rest.PERSONIFICATION = uid;
                    localStorage.clear();
                    sessionStorage.clear();
                    UserService.UID = Rest.UID;
                    RedirectService.GoToCaricamentoImpersonificazione();
                }else{
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                        'Impersonificazione fallita',
                        'Non é stato possibile impersonificare l\'utente'
                    )
                }
            },
            () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow
        )
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <ResponsiveLabel
                    content={"Impersonificazione fotografo"}
                    type={"large"}
                    alignment={"center"}
                    bold={true}
                    uppercase={true}
                    zeroShrink={true}/>
                <SectionLayout size={"mediumRelative"} showBorder={true} addPadding={true} lightBackground={true}>
                    <TextLine
                        label={"Filtro fotografi"}
                        value={this.state.filtroFotografo}
                        onChange={v => this.setState({filtroFotografo: v})}/>
                    <IfContainer
                        condition={!this.state.isLoadding}
                        elseComponent={<LabelLoader label={"Caricamento dei fotografi"}/>}>
                        <IfContainer
                            condition={this._listaFotografiFiltrata().length !== 0}
                            elseComponent={<ResponsiveLabel content={"Nessun fotografo disponibile"} type={"large"} alignment={"center"}/>}>
                            {
                                Pagination.OverflowStructure(
                                    <Pagination
                                        elements={this._listaFotografiFiltrata()}
                                        paginationSize={10}>{
                                        props => (
                                            <SectionLayout size={"largeRelative"}>
                                                <OverflowTag/>
                                                {
                                                    props.paginatedElements.map(fotografo => (
                                                        <span
                                                            key={`Fotografo-${fotografo.idFotografo}`}
                                                            className={"RigaImpersonificazione"}
                                                            onClick={() => this._impersonificaFotografo(fotografo.email)}>
                                                            <img
                                                                alt={"ImmagineProfilo"}
                                                                className={"ImmagineProfilo"}
                                                                src={UserService.UserImageUrl(fotografo.email, fotografo.immagineProfilo)}/>
                                                            <ResponsiveLabel
                                                                content={`${fotografo.idFotografo}) ${fotografo.nome} ${fotografo.cognome}, ${fotografo.nomeStudio}`}
                                                                type={"medium"}
                                                                alignment={"center"}
                                                                labelType={"whiteLabel"}/>
                                                            <img
                                                                alt={"ImpersonificaProfilo"}
                                                                className={"ImpersonificazioneButton"}
                                                                src={ImageService.getImage('eyeIcon')}/>
                                                        </span>
                                                    ))
                                                }
                                            </SectionLayout>
                                        )
                                    }</Pagination>
                                )
                            }
                        </IfContainer>
                    </IfContainer>
                </SectionLayout>
            </SectionLayout>
        );
    }
}
