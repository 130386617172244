import React from "react";
import "./DownloadLine.scss";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import PaddingLayout from "../../Layout/PaddingLayout/PaddingLayout";
import Button from "../../Core/Buttons/Button";

export interface DownloadLineProps{
    immagineLinea?: string;
    nomeFile: string;
    onDownload?: () => void;
}

export default class DownloadLine extends React.Component<DownloadLineProps, any>{
    public render() {
        return (
            <span className={`DownloadLineContainer ${this.props.immagineLinea && 'immagineLinea'}`}>
                <PaddingLayout paddingMobile={"4px"} paddingTablet={"8px"} paddingDesktop={"8px"}>
                    {this.props.immagineLinea && <img alt={'dowmload'} src={this.props.immagineLinea}/>}
                    <ResponsiveLabel
                        content={this.props.nomeFile}
                        type={"medium"}
                        alignment={"left"}
                        labelType={"whiteLabel"}/>
                    <Button
                        content={"Scarica"}
                        type={"medium"}
                        buttonType={"basic"}
                        onClick={() => this.props.onDownload && this.props.onDownload()}/>
                </PaddingLayout>
            </span>
        );
    }
}
