import React from "react";
import "./ResponsiveLabel.scss";
import IfContainer from "../../Layout/IfContainer/IfContainer";

export type ResponsiveLabelType =
    "separatorLabel" |
    "recapLabel" |
    "errorLabel" |
    "warningLabel" |
    "whiteLabel" |
    "leftOnMobile" |
    "cardAgenteInfo" |
    "_404" |
    "footer" |
    "footerHp"

export interface ResponsiveLabelProps{
    content: string,
    type: "xxlarge" | "xlarge" | "large" | "xmedium" | "medium" | "xsmall" | "small" | "very-small",
    alignment: "left" | "center" | "right",
    labelType?: ResponsiveLabelType,
    italic?: boolean,
    bold?: boolean,
    uppercase?: boolean,
    selezionabile?: boolean,
    zeroShrink?: boolean,
    noWrap?: boolean,
    asHTML?: boolean
}
export default class ResponsiveLabel extends React.Component<ResponsiveLabelProps, {}>{
    public render() {
        return (
            <IfContainer
                condition={this.props.asHTML}
                elseComponent={
                    <span
                        className={`ResponsiveLabel ${this.props.uppercase && 'uppercase'} ${this.props.bold && 'bold'} ${this.props.italic && 'italic'} ${this.props.noWrap && 'noWrap'} ${this.props.labelType} ${this.props.type} ${this.props.alignment} ${this.props.selezionabile && 'testoSelezionabile'} ${this.props.zeroShrink && 'zeroShrink'}`}>
                        {this.props.content}
                    </span>
                }>
                 <span
                    className={`ResponsiveLabel ${this.props.uppercase && 'uppercase'} ${this.props.bold && 'bold'} ${this.props.italic && 'italic'} ${this.props.noWrap && 'noWrap'} ${this.props.labelType} ${this.props.type} ${this.props.alignment} ${this.props.selezionabile && 'testoSelezionabile'} ${this.props.zeroShrink && 'zeroShrink'}`}
                    dangerouslySetInnerHTML={{__html: this.props.content}}/>
            </IfContainer>
        );
    }
}
