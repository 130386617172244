import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import React from "react";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import Loader from "../../Core/Loader/Loader";

export interface SingoloSelettoreImmagineProps {
    immagine: StorageData | StorageData[],
    order: number
    escluso?: boolean,
    selezionato?: boolean,
    onClick?: () => void,
    overlaySelettore?: React.ReactElement;
}

export default class SingoloSelettoreImmagine extends React.Component<SingoloSelettoreImmagineProps, {}>{
    public get isMultiplaImmagine(): boolean{
        return Array.isArray(this.props.immagine);
    }

    public get immaginePrincipale(): StorageData{
        return this.isMultiplaImmagine ? (this.props.immagine as StorageData[])[0] : this.props.immagine as StorageData;
    }

    public get immaginiSecondarie(): StorageData[]{
        let esito = undefined;

        if(this.isMultiplaImmagine){
            const arrayImmagini = [...(this.props.immagine as StorageData[])];
            arrayImmagini.shift();
            esito = arrayImmagini;
        }

        return esito;
    }

    public get immaginiSecondarieCount(): number{
        return this.immaginiSecondarie?.length || 0;
    }



    public render() {
        return (
            <IfContainer
                condition={!!this.immaginePrincipale?.url()}
                elseComponent={
                    <Loader/>
                }>
                <div
                    className={`SingoloSelettoreImmagineContainer ${this.isMultiplaImmagine && 'multiploContenuto'}`}
                    onClick={() => {this.props.onClick && this.props.onClick()}}
                    style={{order: `${this.props.order}`, '--imageNumber': this.immaginiSecondarieCount + 1} as any}>
                    <img
                        alt={this.immaginePrincipale.name}
                        className={`SingoloSelettoreImmagine ${this.props.escluso && 'escluso'} ${this.props.selezionato && 'selezionato'}`}
                        loading={"lazy"}
                        src={this.immaginePrincipale.url()}/>
                    {
                        this.isMultiplaImmagine &&
                        this.immaginiSecondarie.map(immagineSecondaria => (
                            <img
                                alt={immagineSecondaria.name}
                                className={`SingoloSelettoreImmagine ${this.props.escluso && 'escluso'}`}
                                loading={"lazy"}
                                src={immagineSecondaria.url()}/>
                        ))
                    }
                    {this.props.overlaySelettore && this.props.overlaySelettore}
                </div>
            </IfContainer>
        );
    }
}
