//QG 30/06/2024

import React, {Fragment} from "react";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import PreviewAlberaturaModello from "../../../../../Primary/GestioneModelli/PreviewAlberaturaModello/PreviewAlberaturaModello";
import {CheckLine} from "../../../../../Widgets/Configuratore/CComponents";
import ConfiguraSezioneTopLevel, {ConfiguraSezioneTopLevelProps, ConfiguraSezioneTopLevelState} from "./ConfiguraSezioneTopLevel";
import {InputContainer, TripleLine} from "../ConfiguratoreModelliComponents";
import {Triple} from "tici_commons";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Responsive3Col from "../../../../../Layout/Responsive3Col/Responsive3Col";
import Button from "../../../../../Core/Buttons/Button";

export interface ConfiguraGiuntiProps extends ConfiguraSezioneTopLevelProps{
    updateRenderView: () => void
}
export interface ConfiguraGiuntiState extends ConfiguraSezioneTopLevelState{
    sezioneTopLevelSelezionataCollegamento: string
}

export default class ConfiguraGiunti extends ConfiguraSezioneTopLevel<ConfiguraGiuntiProps, ConfiguraGiuntiState>{
    constructor(props: Readonly<ConfiguraGiuntiProps> | ConfiguraGiuntiProps) {
        super(props);
        this.state = {
            sezioneSelezionata: '',
            sezioneConfigurabileSelezionata: undefined,
            sezioneTopLevelSelezionataCollegamento: ''
        }
    }

    private _cambiaStatoCollegamentoSezione(statoApplicato: boolean){
        let sezioniCollegate = this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioniCollegate;
        if(statoApplicato){
            for(const sezioneConfigurabile of this.props.sezioniConfigurabili){
                sezioneConfigurabile.configurazioneSezione.sezioniCollegate = sezioneConfigurabile.configurazioneSezione.sezioniCollegate
                    .filter(sezione => sezione.sezione !== this.state.sezioneTopLevelSelezionataCollegamento);
            }
            sezioniCollegate.push({sezione: this.state.sezioneTopLevelSelezionataCollegamento, giunto: {x: 0, y: 0, z: 0}});
        }else{
            sezioniCollegate = sezioniCollegate.filter(sezione => sezione.sezione !== this.state.sezioneTopLevelSelezionataCollegamento);
        }
        this.ImpostaValoreConfigurazioneSezione('sezioniCollegate', sezioniCollegate);
    }

    private _calcolaInversaGiuntoRotazioneCollegamentoSezione(){
        const sezioneCollegata = this.props.sezioniConfigurabili.find(sezione => sezione.configurazioneSezione.nomeReale === this.state.sezioneTopLevelSelezionataCollegamento);
        if(sezioneCollegata){
            this._giuntoSezioneTopLevelCollegata = {
                x: -sezioneCollegata.configurazioneSezione.offsetGiuntoRotazione.x,
                y: -sezioneCollegata.configurazioneSezione.offsetGiuntoRotazione.y,
                z: -sezioneCollegata.configurazioneSezione.offsetGiuntoRotazione.z
            }
        }
    }

    private _applicaHelperSezione(asse: 'X' | 'Y' | 'Z', posizionamento: 1 | 2 | 3){
        const valoreCalcolato = this.state.sezioneConfigurabileSelezionata.calcolaPosizioneGiuntoRotazioneUtility(asse, posizionamento);

        const vecchioOffset = this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetGiuntoRotazione;
        (vecchioOffset as any)[asse.toLowerCase()] = valoreCalcolato;
        this.ImpostaValoreConfigurazioneSezione('offsetGiuntoRotazione', vecchioOffset);
    }

    //region Beam

    private get _isSezioneTopLevelCollegata(): boolean{
        let esito = false;

        if(this.state.sezioneTopLevelSelezionataCollegamento){
            esito = !!this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioniCollegate
                .find(sezione => sezione.sezione === this.state.sezioneTopLevelSelezionataCollegamento);
        }

        return esito;
    }

    private get _giuntoSezioneTopLevelCollegata(): Triple{
        return this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioniCollegate
            .find(sezione => sezione.sezione === this.state.sezioneTopLevelSelezionataCollegamento)?.giunto || {x: 0, y: 0, z: 0};
    }

    private set _giuntoSezioneTopLevelCollegata(triple: Triple){
        const sezioniCollegate = this.state.sezioneConfigurabileSelezionata.configurazioneSezione.sezioniCollegate;
        const index = sezioniCollegate.findIndex(sezione => sezione.sezione === this.state.sezioneTopLevelSelezionataCollegamento);
        if(index >= 0)
            sezioniCollegate[index].giunto = triple;
        this.ImpostaValoreConfigurazioneSezione('sezioniCollegate', sezioniCollegate);
    }

    //endregion

    private _sezioneConfigurazioneGiunto(){
        return (
            <TripleLine
                label={"Offset giunto rotazione (X, Y, Z)"}
                xValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetGiuntoRotazione.x}
                yValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetGiuntoRotazione.y}
                zValue={this.state.sezioneConfigurabileSelezionata.configurazioneSezione.offsetGiuntoRotazione.z}
                onChangeValue={(x, y, z) => {
                    this.ImpostaValoreConfigurazioneSezione('offsetGiuntoRotazione', {x, y, z});
                }}
                gradi={true}/>
        )
    }

    private _sezioneConfigurazioneGiuntoHelper(){
        return (
            <Fragment>
                <ResponsiveLabel content={"Aiuti posizionamento asse X"} type={"medium"} alignment={"left"}/>
                <Responsive3Col>
                    <Button content={"Esterna dx"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('X', 1)}/>
                    <Button content={"Centrale"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('X', 2)}/>
                    <Button content={"Esterna sx"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('X', 3)}/>
                </Responsive3Col>
                <ResponsiveLabel content={"Aiuti posizionamento asse Y"} type={"medium"} alignment={"left"}/>
                <Responsive3Col>
                    <Button content={"Esterna dx"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('Y', 1)}/>
                    <Button content={"Centrale"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('Y', 2)}/>
                    <Button content={"Esterna sx"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('Y', 3)}/>
                </Responsive3Col>
                <ResponsiveLabel content={"Aiuti posizionamento asse Z"} type={"medium"} alignment={"left"}/>
                <Responsive3Col>
                    <Button content={"Esterna dx"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('Z', 1)}/>
                    <Button content={"Centrale"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('Z', 2)}/>
                    <Button content={"Esterna sx"} type={"medium"} buttonType={"full-normal"} onClick={() => this._applicaHelperSezione('Z', 3)}/>
                </Responsive3Col>
            </Fragment>
        )
    }

    private _sezioneConfigurazioneCollegamentoSezione(){
        return (
            <CategorySectionLayout categoryLabel={"Giunto collegamento"} size={"largeRelative"}>
                <InputContainer label={"Collega a sezione"}>
                    <ElementSelect
                        label={"Seleziona"}
                        elements={this.ListaSezioniTopLevel(true)}
                        value={this.state.sezioneTopLevelSelezionataCollegamento}
                        onChange={v => this.setState({sezioneTopLevelSelezionataCollegamento: v})}/>
                </InputContainer>
                <IfContainer condition={!!this.state.sezioneTopLevelSelezionataCollegamento}>
                    <CheckLine
                        label={"Sezione collegata"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this._isSezioneTopLevelCollegata}
                        onChange={v => this._cambiaStatoCollegamentoSezione(v)}/>
                    <IfContainer condition={this._isSezioneTopLevelCollegata}>
                        <TripleLine
                            label={"Offset Giunto collegamento (X, Y, Z)"}
                            xValue={this._giuntoSezioneTopLevelCollegata.x}
                            yValue={this._giuntoSezioneTopLevelCollegata.y}
                            zValue={this._giuntoSezioneTopLevelCollegata.z}
                            onChangeValue={(x, y, z) => this._giuntoSezioneTopLevelCollegata = {x, y, z}}/>
                        <Button
                            content={"Calcola inversi giunto posizione"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            onClick={() => this._calcolaInversaGiuntoRotazioneCollegamentoSezione()}/>
                    </IfContainer>
                </IfContainer>
            </CategorySectionLayout>
        )
    }

    private _sezioneTestRotazione(){
        return (
            <CategorySectionLayout categoryLabel={"Test rotazione"} size={"largeRelative"}>
                <TripleLine
                    label={"Test rotazione (X, Y, Z)"}
                    xValue={this.state.sezioneConfigurabileSelezionata.animationPivot.rotationVector.x}
                    yValue={this.state.sezioneConfigurabileSelezionata.animationPivot.rotationVector.y}
                    zValue={this.state.sezioneConfigurabileSelezionata.animationPivot.rotationVector.z}
                    onChangeValue={(x, y, z) => {
                        const sezioneConfigurabile = this.state.sezioneConfigurabileSelezionata;
                        sezioneConfigurabile.animationPivot.rotationVector.set(x, y, z);
                        sezioneConfigurabile.update();
                        this.setState({sezioneConfigurabileSelezionata: sezioneConfigurabile});
                    }}
                    gradi={true}/>
            </CategorySectionLayout>
        )
    }

    public render() {
        return (
            <Fragment>
                <CategorySectionLayout categoryLabel={"Alberatura modello"} size={"largeRelative"}>
                    <PreviewAlberaturaModello
                        updateRenderView={() => {
                            this.props.updateRenderView && this.props.updateRenderView();
                            this.props.modelloConfigurabile.update();
                        }}
                        sezioniConfigurabili={this.props.sezioniConfigurabili}/>
                </CategorySectionLayout>
                <CategorySectionLayout categoryLabel={"Configurazione giunto"} size={"largeRelative"}>
                    <InputContainer label={"Sezione top level"}>
                        <ElementSelect
                            label={"Seleziona"}
                            elements={this.ListaSezioniTopLevel()}
                            value={this.state.sezioneSelezionata}
                            onChange={v => this.SelezionaSezioneConfigurabile(v, false, true)}/>
                    </InputContainer>
                    {this.state.sezioneSelezionata && this._sezioneConfigurazioneGiunto()}
                    {this.state.sezioneSelezionata && this._sezioneConfigurazioneGiuntoHelper()}
                </CategorySectionLayout>
                {
                    this.state.sezioneSelezionata &&
                    <Fragment>
                        {this._sezioneTestRotazione()}
                        {this._sezioneConfigurazioneCollegamentoSezione()}
                    </Fragment>
                }
            </Fragment>
        );
    }
}
