//QG 30/06/2024

import './ManagerRenderizzatoreModelli.scss';

import React, {RefObject} from "react";
import {Group, Object3D} from "three";
import {ModelloConfigurabile} from "../GestioneModelli/ModelloConfigurabile";
import SezioneConfigurabile from "../GestioneModelli/SezioneConfigurabile";
import RenderizzatoreModelli from "../GestioneModelli/RenderizzatoreModelli/RenderizzatoreModelli";
import {CaricaModello, CaricaModelloResponseInterface} from "../GestioneModelli/GestioneModelliCommonFunction";
import Modello from "../../DatabaseData/Modello";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import LabelLoader from "../LabelLoader/LabelLoader";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {ElementoConfigurabile} from "../GestioneModelli/ElementoConfigurabile";
import {ConfigurazioneBlank, NomeSezioneInformazioneType} from "tici_commons";
import TiciNavigator from "./EditorComponents/TiciNavigator/TiciNavigator";
import { StorageData, StorageManagerContext, StorageManagerStatus } from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import ImageService from "../../Servicies/ImageService";
import Button from "../../Core/Buttons/Button";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import {
    ConfigurazioneGestore3DBridgeState
} from "../../Pages/SitoInterno/SuperUser/Bridge/ConfigurazioneGestore3DBridge";
import LocalStorageService from "../../Servicies/LocalStorageService";

export interface ManagerRenderizzatoreModelliProps{
    modalitaVisualizzazioneAlternativa?: boolean,
    modalitaSupportoVisivo?: boolean,

    visualizzaPulsanteDiApertura?: boolean,

    intercettaModificaVisualizzazione?: boolean,
    intercettaEventiClick?: boolean,

    aspectRatioCanvas?: string,
    aspectRatioModello?: string,

    nomeManager: string,
    nomeModelloSelezionato?: string,
    modelloCaricato?: CaricaModelloResponseInterface,
    bridgeModello?: ConfigurazioneGestore3DBridgeState,

    onModelloInizializzato?: (modelloConfigurabile: ModelloConfigurabile, sezioniConfigurabili: SezioneConfigurabile[]) => void,

    datiSezioni?: NomeSezioneInformazioneType[],
    onDatiSezioniChange?: (nuoveInformazioni: NomeSezioneInformazioneType[]) => void
}

export interface ManagerRenderizzatoreModelliState{
    modelloCaricato: boolean,
    isLoading: boolean,

    visualizzaNavigator: boolean,
    posizioneXNavigator: number,
    posizioneYNavigator: number,
    sezioneConfigurabileSelezionata: SezioneConfigurabile,

    vistaSelezionata: string,
    statoModelloAperto: boolean
}

export default class ManagerRenderizzatoreModelli extends React.Component<ManagerRenderizzatoreModelliProps, ManagerRenderizzatoreModelliState>{
    private _gruppoRendering: Group = new Group();
    private _modelloConfigurabile: ModelloConfigurabile;
    private _sezioniConfigurabili: SezioneConfigurabile[];

    private _renderizzatoreModelli: RefObject<RenderizzatoreModelli> = React.createRef();

    constructor(props: Readonly<ManagerRenderizzatoreModelliProps> | ManagerRenderizzatoreModelliProps) {
        super(props);
        this.state = {
            isLoading: false,

            modelloCaricato: false,
            visualizzaNavigator: false,
            posizioneXNavigator: 0,
            posizioneYNavigator: 0,
            sezioneConfigurabileSelezionata: undefined,

            vistaSelezionata: '',
            statoModelloAperto: false
        }
    }

    public componentDidMount() {
        this._inizializzaModello();
    }

    public componentDidUpdate(prevProps: Readonly<ManagerRenderizzatoreModelliProps>) {
        //console.warn("Update manager");
        if(
            prevProps.nomeModelloSelezionato !== this.props.nomeModelloSelezionato ||
            prevProps.modelloCaricato !== this.props.modelloCaricato ||
            prevProps.bridgeModello !== this.props.bridgeModello
        ) {
            this._inizializzaModello();
        }
        if(this.props.intercettaEventiClick !== prevProps.intercettaEventiClick && !this.props.intercettaEventiClick){
            this.setState({visualizzaNavigator: false});
        }
        if(this.props.intercettaModificaVisualizzazione && prevProps.modalitaVisualizzazioneAlternativa !== this.props.modalitaVisualizzazioneAlternativa) {
            this._selezionaProssimaVista();
        }
        if(this.props.aspectRatioModello !== prevProps.aspectRatioModello){
            this._modelloConfigurabile.aspectRatioModello = this.props.aspectRatioModello || "1 / 1";
        }
        if(this._modelloConfigurabile && prevProps.modalitaVisualizzazioneAlternativa !== this.props.modalitaVisualizzazioneAlternativa) {
            this._modelloConfigurabile.modalitaVisualizzazioneAlternativa = this.props.modalitaVisualizzazioneAlternativa;
        }
        if(this._modelloConfigurabile && prevProps.modalitaSupportoVisivo !== this.props.modalitaSupportoVisivo) {
            this._modelloConfigurabile.modalitaSupportoVisivo = this.props.modalitaSupportoVisivo;
        }
    }

    //region InizializzazioneModello

    private async _recuperaDatiModello(modello3D: string): Promise<Object3D | false>{
        let esito: Object3D | false = false;

        try{
            const loader = new OBJLoader();
            const url = modello3D.includes('base64') ? modello3D : URL.createObjectURL(new Blob([modello3D], {type: 'text/plain'}));
            esito = await loader.loadAsync(url);
            URL.revokeObjectURL(url);
        }catch (e){
            console.error("Non é stato possibile recuperare i dati del modello", String(e));
        }

        return esito;
    }

    private async _inizializzaModello(){
        this.setState({isLoading: true, modelloCaricato: false});
        let modelloCaricato: CaricaModelloResponseInterface | false = false;
        if(this.props.nomeModelloSelezionato){
            const entryModello = Modello.RecuperaModelloDaNome(this.props.nomeModelloSelezionato);
            modelloCaricato = await CaricaModello(entryModello.nomeConfigurazione);
        }else if(this.props.modelloCaricato) {
            modelloCaricato = this.props.modelloCaricato;
        }else if(this.props.bridgeModello){
            const metadataConfiguratore = this.props.bridgeModello.metaData.configuratore;
            const bridgePayload = this.props.bridgeModello.payload;

            let modello3D = undefined;
            let configurazione = undefined;
            if(bridgePayload.nomeModello.toLowerCase() === 'attualmente caricata'){
                modello3D = await Modello.GetModello(metadataConfiguratore.modello3DBase64Encoding);
            }else{
                modello3D = metadataConfiguratore.modello3DBase64Encoding;
            }
            configurazione = ConfigurazioneBlank(bridgePayload.configurazione);

            if(modello3D && configurazione){
                modelloCaricato = {
                    modello3D,
                    configurazione
                }
            }
        }

        if(modelloCaricato){
            const datiModello = await this._recuperaDatiModello(modelloCaricato.modello3D);
            if(datiModello){
                const modelloConfigurabile = new ModelloConfigurabile(datiModello, modelloCaricato.configurazione);
                const sezioniConfigurabili = await modelloConfigurabile.setupModelloConfigurabile();
                if(sezioniConfigurabili){
                    this._modelloConfigurabile && this._modelloConfigurabile.removeFromParent();
                    this._gruppoRendering.add(modelloConfigurabile);

                    this._modelloConfigurabile = modelloConfigurabile;
                    this._sezioniConfigurabili = sezioniConfigurabili;

                    this._modelloConfigurabile.modalitaSupportoVisivo = this.props.modalitaSupportoVisivo;
                    this._modelloConfigurabile.modalitaVisualizzazioneAlternativa = this.props.modalitaVisualizzazioneAlternativa;
                    this._modelloConfigurabile.aspectRatioModello = this.props.aspectRatioModello || "1 / 1";
                    this.props.onModelloInizializzato && this.props.onModelloInizializzato(this._modelloConfigurabile, this._sezioniConfigurabili);
                    for(const sezioneConfigurabile of this._sezioniConfigurabili)
                        sezioneConfigurabile.addCallbackClick(elementoConfigurabile => this.props.intercettaEventiClick && this._visualizzaNavigator(elementoConfigurabile));

                    this._selezionaProssimaVista();
                    this.setState({modelloCaricato: true});
                }
            }
        }

        this.setState({isLoading: false})
    }

    //endregion

    //region Handlers

    private _selezionaProssimaVista(direzione?: number){
        if(this.state.statoModelloAperto) {
            this._modelloConfigurabile.avviaAnimazione('animazioneApertura', true);
            this.setState({statoModelloAperto: false});
        }

        const listaViste = this._modelloConfigurabile.configurazioneModello.visteSezioni;
        let vistaSelezionata;
        if(listaViste && listaViste.length > 0){
            const primaVista = listaViste.find(vista => vista.primaVista);
            if(direzione === undefined){
                if(primaVista)
                    vistaSelezionata = primaVista;
                else vistaSelezionata = listaViste[0];
                this.setState({vistaSelezionata: vistaSelezionata.nomeSezione})
            }else{
                const lastIndex = listaViste.findIndex(vista => vista.nomeSezione === this.state.vistaSelezionata);
                if(lastIndex === -1){
                    if(primaVista)
                        vistaSelezionata = primaVista;
                    else vistaSelezionata = listaViste[0];
                    this.setState({vistaSelezionata: vistaSelezionata.nomeSezione});
                }else{
                    let nextIndex = lastIndex + direzione;
                    if(nextIndex >= listaViste.length)
                        nextIndex = 0;
                    else if(nextIndex < 0)
                        nextIndex = listaViste.length - 1;
                    vistaSelezionata = listaViste[nextIndex];
                    this.setState({vistaSelezionata: vistaSelezionata.nomeSezione});
                }
            }
        }else{
            this.setState({vistaSelezionata: ''});
        }

        if(vistaSelezionata) {
            this._modelloConfigurabile.updateCamera();
            this._modelloConfigurabile.muoviSuVista(this.props.modalitaVisualizzazioneAlternativa ? vistaSelezionata.orientamentoAlternativo : vistaSelezionata.orientamento);
        }
    }

    private _possiedeAnimazioneApertura(){
        return !!this._modelloConfigurabile?.configurazioneModello?.animazioni?.animazioneApertura;
    }

    private _visualizzaNavigator(elementoConfigurabile: ElementoConfigurabile){
        const sezioneConfigurabile = elementoConfigurabile as SezioneConfigurabile;
        if(sezioneConfigurabile){
            this.setState({visualizzaNavigator: true, sezioneConfigurabileSelezionata: sezioneConfigurabile});
        }
    }

    //endregion

    //region FunzioniTopGestioneStorage

    private _recuperaImmagineManager(storageManager: StorageManagerStatus, nomeCategoria: string, nomeImmagine?: string): StorageData{
        let esito = undefined;

        if(storageManager){
            const data = storageManager.GetStorageData(`${this.props.nomeManager}-${nomeCategoria}`, nomeImmagine);
            if(data.length > 0)
                esito = data[0];
        }

        return esito;
    }

    private _eliminaImmagineManager(storageManager: StorageManagerStatus, nomeCategoria: string, nomeImmagine?: string, aggiungiPrefissoManager = true){
        if(storageManager){
            if(aggiungiPrefissoManager)
                storageManager.DeleteStorageData(`${this.props.nomeManager}-${nomeCategoria}`, nomeImmagine);
            else storageManager.DeleteStorageData(nomeCategoria, nomeImmagine);
        }
    }

    private _caricaImmagineManager(storageManager: StorageManagerStatus, nomeCategoria: string, nomeImmagine: string, fileImmagine: File) {
        storageManager && storageManager.SetStorageData(`${this.props.nomeManager}-${nomeCategoria}`, nomeImmagine, fileImmagine);
    }

    private _contaImmagini(storageManager: StorageManagerStatus, nomeCategoria: string): number{
        let esito = 0;

        if(storageManager)
            esito = storageManager.GetStorageData(`${this.props.nomeManager}-${nomeCategoria}`).length;

        return esito;
    }

    //endregion

    //region Beam
    public get nomeVisualizzatoSezioneVista(): string{
        let esito = "";
        if(this.state.vistaSelezionata && this._sezioniConfigurabili){
            for(const sezioneConfigurabile of this._sezioniConfigurabili){
                if(sezioneConfigurabile.configurazioneSezione.nomeReale === this.state.vistaSelezionata) {
                    esito = sezioneConfigurabile.configurazioneSezione.nomeVisualizzato;
                    break;
                }
            }
        }
        return esito;
    }
    //endregion

    //region Components

    private _Renderizzatore(){
        return (
            <RenderizzatoreModelli
                ref={this._renderizzatoreModelli}
                aspectRatioCanvas={this.props.aspectRatioCanvas}
                larghezzaCanvas={"100%"}
                modalitaSupportoVisivo={this.props.modalitaSupportoVisivo}
                gruppoRendering={this._gruppoRendering}
                onClickCanvas={(mouseX, mouseY, canvas, camera) => {
                    const canvasBoundingRect = canvas.getBoundingClientRect();
                    this.setState({posizioneXNavigator: canvasBoundingRect.x + mouseX, posizioneYNavigator: canvasBoundingRect.y + mouseY});
                    this._modelloConfigurabile && this._modelloConfigurabile.onClickCanvas(mouseX, mouseY, canvas, camera);
                }}
                onGenerateImage={imageData => {
                    if(this.props.nomeManager === 'Copertina')
                        LocalStorageService.CopertinaImmagineGenerata = imageData;
                    if(this.props.nomeManager === 'Custodia')
                        LocalStorageService.CustodiaImmagineGenerata = imageData;
                }}
                onUpdateMousePosition={(mouseX, mouseY, canvas, camera) => {
                    this._modelloConfigurabile && this._modelloConfigurabile.onUpdateMousePosition(mouseX, mouseY, canvas, camera);
                }}
                onCollisionWithMouseDetection={(collision) => {
                    this._modelloConfigurabile && this._modelloConfigurabile.onCollisionWithMouseDetection(collision);
                }}
                onTick={(dt) => {
                    this._modelloConfigurabile && this._modelloConfigurabile.onTick(dt);
                }}/>
        )
    }

    private _Navigator(){
        return (
            <StorageManagerContext.Consumer>{
                storageManager => (
                    <TiciNavigator
                        nomeManager={this.props.nomeManager}
                        modelloConfigurabile={this._modelloConfigurabile}
                        sezioneConfigurabile={this.state.sezioneConfigurabileSelezionata}
                        posizioneXAperturaNavigator={this.state.posizioneXNavigator}
                        posizioneYAperturaNavigator={this.state.posizioneYNavigator}
                        onCloseNavigator={() => this.setState({visualizzaNavigator: false})}
                        datiSezioni={this.props.datiSezioni}
                        onDatiSezioniChange={nuoveInformazioni => {
                            this.props.onDatiSezioniChange && this.props.onDatiSezioniChange(nuoveInformazioni);
                            this.setState({visualizzaNavigator: false});
                        }}
                        caricaImmagine={(nomeCategoria, nomeImmagine, fileImmagine) => this._caricaImmagineManager(storageManager, nomeCategoria, nomeImmagine, fileImmagine)}
                        eliminaImmagine={(nomeCategoria, nomeImmagine, aggiungiPrefissoManager) => this._eliminaImmagineManager(storageManager, nomeCategoria, nomeImmagine, aggiungiPrefissoManager)}
                        recuperaImmagine={(nomeCategoria, nomeImmagine) => this._recuperaImmagineManager(storageManager, nomeCategoria, nomeImmagine)}
                        contaImmagini={nomeCategoria => this._contaImmagini(storageManager, nomeCategoria)}
                        recuperaStorage={() => storageManager.GetStorage()}
                        modalitaVisualizzazioneAlternativa={this.props.modalitaVisualizzazioneAlternativa}/>
                )
            }</StorageManagerContext.Consumer>
        )
    }

    //endregion

    public render() {
        return (
            <IfContainer
                condition={!this.state.isLoading}
                elseComponent={
                    <LabelLoader label={"Caricamento modello"}/>
                }>
                <IfContainer
                    condition={this.state.modelloCaricato}
                    elseComponent={<ResponsiveLabel content={"Nessun modello caricato"} type={"medium"} alignment={"center"}/>}>
                    <div className={"ContainerRenderizzatoreModelli"}>
                        {this._Renderizzatore()}
                        <IfContainer condition={!this.props.modalitaSupportoVisivo && this._modelloConfigurabile?.configurazioneModello?.visteSezioni?.length > 1}>
                            <span className={"ContainerComandiViste"}>
                                <img
                                    alt={"Spostamento vista sinistro"}
                                    style={{rotate: '90deg'}}
                                    src={ImageService.getImage('simpleArrowIcon')}
                                    onClick={() => this._selezionaProssimaVista(-1)}/>
                                <img
                                    alt={"Spostamento vista destro"}
                                    style={{rotate: '-90deg'}}
                                    src={ImageService.getImage('simpleArrowIcon')}
                                    onClick={() => this._selezionaProssimaVista(1)}/>
                            </span>
                            <span className={"ContainerVistaSelezionata"}>
                                <ResponsiveLabel
                                    content={`Sezione: ${this.nomeVisualizzatoSezioneVista}`}
                                    type={"xmedium"}
                                    alignment={"center"}
                                    labelType={'whiteLabel'}
                                    bold={true}
                                    uppercase={true}/>
                            </span>
                            <IfContainer condition={this._possiedeAnimazioneApertura() && this.props.visualizzaPulsanteDiApertura}>
                                <SectionLayout size={"smallRelative"} center={true}>
                                    <Button
                                        content={"Aperto/Chiuso"}
                                        type={"xmedium"}
                                        buttonType={"full-normal"}
                                        onClick={() => {
                                            this._modelloConfigurabile.avviaAnimazione('animazioneApertura', this.state.statoModelloAperto);
                                            this.setState(oldState => ({statoModelloAperto: !oldState.statoModelloAperto}));
                                        }}/>
                                </SectionLayout>
                            </IfContainer>
                        </IfContainer>
                    </div>
                    <IfContainer condition={this.state.visualizzaNavigator}>
                        {this._Navigator()}
                    </IfContainer>
                </IfContainer>
            </IfContainer>
        );
    }
}
