import './Pagination.scss';
import React, {Fragment} from "react";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import ContainerLayout from '../../Layout/ContainerLayout/ContainerLayout';

export interface PaginationProps<T>{
    children: (props: { paginatedElements: T[] }) => React.ReactNode,
    elements: T[],
    paginationSize: number
}

export interface PaginationState{
    paginaCorrente: number
    startRangeIndex: number,
    numeroPagine: number,
    endRangeIndex: number,
}

export default class Pagination<T> extends React.Component<PaginationProps<T>, PaginationState>{
    private _tabVisualizzati = 10;

    constructor(props: Readonly<PaginationProps<T>> | PaginationProps<T>) {
        super(props);
        this.state = {
            paginaCorrente: 0,
            startRangeIndex: 0,
            numeroPagine: 0,
            endRangeIndex: this._tabVisualizzati
        }
    }

    public componentDidMount() {
        this._aggiornaStartEndRange();
    }

    public componentDidUpdate(prevProps: Readonly<PaginationProps<T>>, prevState: Readonly<PaginationState>, snapshot?: any) {
        if(prevProps.elements.length !== this.props.elements.length) {
            this.setState({
                endRangeIndex: this._tabVisualizzati,
                paginaCorrente: 0,
                startRangeIndex: 0,
            }, () => {
                this._aggiornaStartEndRange();
            });
        }if(prevState.paginaCorrente !== this.state.paginaCorrente)
            this._aggiornaStartEndRange();
    }

    public static OverflowStructure(children: any){
        return (
            <div style={{
                width: '100%',
                flexGrow: '1',
                overflow: 'auto'
            }}>
                <ContainerLayout size={"largeRelative"} gap={false} fullHeight={true}>
                    {children}
                </ContainerLayout>
            </div>
        )
    }

    public static OverflowStructureMultiStato(children: any){
        return (
            <div
                className={"WrapperMultistato"}
                style={{
                    width: '100%',
                    flexGrow: '1',
                    overflow: 'auto'
                }}>
                <ContainerLayout size={"largeRelative"} gap={false} fullHeight={true}>
                    {children}
                </ContainerLayout>
            </div>
        )
    }

    private _aggiornaStartEndRange(){
        if(this.props.elements && this.props.paginationSize) {
            const numeroPagine = Math.ceil(this.props.elements.length / this.props.paginationSize);
            let startIndex = this.state.startRangeIndex;
            let endIndex = this.state.endRangeIndex;
            if (numeroPagine < this.state.endRangeIndex)
                endIndex = numeroPagine;
            if (this.state.paginaCorrente === endIndex - 1 && endIndex + 1 <= numeroPagine) {
                startIndex++;
                endIndex++;
            }
            if (this.state.paginaCorrente === startIndex && startIndex - 1 >= 0) {
                startIndex--;
                endIndex--;
            }

            this.setState({
                startRangeIndex: startIndex,
                endRangeIndex: endIndex,
                numeroPagine
            });
        }
    }

    private _getPagesCount(): number[]{
        let esito: number[] = [];

        if(this.props.elements && this.props.paginationSize){
            esito = new Array(this.state.endRangeIndex - this.state.startRangeIndex);
            for(let i = 0; i < esito.length; i++){
                esito[i] = this.state.startRangeIndex + i;
            }
        }

        return esito;
    }

    private _getFilteredElements(): T[]{
        return  this.props.elements.slice(this.state.paginaCorrente * this.props.paginationSize, (this.state.paginaCorrente + 1) * this.props.paginationSize);
    }

    public render() {
        return (
            <Fragment>
                {this.props.children({paginatedElements: this._getFilteredElements()})}
                <span className={"PaginationWrapper"}>
                    {this._getPagesCount().map((element) => (
                        <span
                            className={`PaginationButton ${this.state.paginaCorrente === (element) && 'selected'}`}
                            onClick={() => this.setState({paginaCorrente: element})}>
                            <ResponsiveLabel
                                content={`${element === this.state.startRangeIndex && element > 0 ? '...' : ''} ${element + 1} ${element === this.state.endRangeIndex - 1 && element < this.state.numeroPagine - 1 ? '...' : ''}`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                        </span>
                    ))}
                </span>
            </Fragment>
        );
    }
}
