import "./CategorySectionLayout.scss";
import SectionLayout, {SectionLayoutInterface} from "../SectionLayout/SectionLayout";
import React from "react";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";

export interface CategorySectionLayoutProps extends Omit<SectionLayoutInterface, "showBorder" | "addPadding">{
    categoryLabel: string
}

export default class CategorySectionLayout extends React.Component<CategorySectionLayoutProps, {}>{
    public render() {
        return (
            <div className={"CategorySectionLayout"}>
                <span
                    className={"CategoryHeader"}>
                    <ResponsiveLabel
                        content={this.props.categoryLabel}
                        type={"xmedium"}
                        alignment={"left"}
                        noWrap={true}/>
                </span>
                <SectionLayout
                    size={this.props.size}
                    center={this.props.center}
                    showBorder={false}
                    addPadding={false}
                    order={this.props.order}>
                    {this.props.children}
                </SectionLayout>
            </div>
        );
    }
}
