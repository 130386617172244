import React from "react";
import CategorySwitcher from "../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import ImageService from "../../../Servicies/ImageService";
import CategorySwitcherElement from "../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import {SubmenuContext} from "../../../Primary/Submenu/Submenu";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import PaddingLayout from "../../../Layout/PaddingLayout/PaddingLayout";
import UV from "../../../DatabaseData/UV";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import VSLoader from "../../../Primary/VSLoader/VSLoader";
import ContainerLayout from "../../../Layout/ContainerLayout/ContainerLayout";
import Config from "../../../Static/Config";
import DownloadLine from "../../../Primary/DownloadLine/DownloadLine";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";

export interface PaginaDownloadState{
    isLoading: boolean,
    sezioneCorrente: number,
    listaElementiVisualizzati: [string, string][]
}

export default class PaginaDownload extends React.Component<{}, PaginaDownloadState>{

    public constructor(props: {}, context: any) {
        super(props, context);
        this.state = {
            isLoading: true,
            sezioneCorrente: 0,
            listaElementiVisualizzati: []
        }
    }

    public componentDidMount() {
        this._gestisciContenutoVisualizzato();
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<PaginaDownloadState>) {
        if(prevState.sezioneCorrente !== this.state.sezioneCorrente){
            this._gestisciContenutoVisualizzato();
        }
    }

    private _gestisciContenutoVisualizzato(){
        switch (this.state.sezioneCorrente){
            case 0:
                this.setState({isLoading: false, listaElementiVisualizzati: UV.GetUv()});
                break;
            default:
                this.setState({isLoading: false, listaElementiVisualizzati: [
                    ['Catalogo-RADICI-TICI-Album', 'Catalogo RADICI TICI Album'],
                    ['Catalogo-materiali', 'Catalogo materiali']
                ]});
                break
        }
    }

    private _getElementImage(elementName: string){
        let esito = "";

        switch (this.state.sezioneCorrente){
            case 0:
                esito = `${Config.NoMapping}/Uv/${elementName}.jpg`;
                break;
            case 1:
                esito = undefined;
                break;
        }

        return esito;
    }

    private _downloadElement(elementName: string){
        switch (this.state.sezioneCorrente){
            case 0: {
                const link = document.createElement('a');
                link.href = `${Config.PublicPath}/UV/${elementName}`;
                link.click();
                break;
            }case 1: {
                const link = document.createElement('a');
                link.href = `${Config.NoMapping}/Cataloghi/${elementName}.pdf`;
                link.target = "_blank";
                link.download = elementName;
                link.click();
                break;
            }
        }
    }

    public render() {
        return (
            <PaddingLayout
                paddingMobile={"16px"}
                paddingTablet={"16px"}
                paddingDesktop={"16px"}>
                <ContainerLayout size={"smallRelative"} scroll={true} fullHeight={true} center={true}>
                    <SubmenuContext.Provider value={{aviable: false}}>
                        <CategorySwitcher>
                            <CategorySwitcherElement
                                elementIndex={0}
                                icon={ImageService.getImage('uvIcon')}
                                label={'File UV'}
                                selected={this.state.sezioneCorrente === 0}
                                onClick={v => this.setState({sezioneCorrente: v})}
                                grow={true}/>
                            <CategorySwitcherElement
                                elementIndex={1}
                                icon={ImageService.getImage('pdfIcon')}
                                label={'Catalogo'}
                                selected={this.state.sezioneCorrente === 1}
                                onClick={v => this.setState({sezioneCorrente: v})}
                                grow={true}/>
                        </CategorySwitcher>
                    </SubmenuContext.Provider>
                    <ContainerLayout size={"largeRelative"} gap={false} scroll={true} grow={true}>
                        <IfContainer
                            condition={!this.state.isLoading}
                            elseComponent={<VSLoader gapDesktop={16} gapTablet={16} gapMobile={16}/>}>
                            <IfContainer
                                condition={this.state.listaElementiVisualizzati.length > 0}
                                elseComponent={<ResponsiveLabel content={"Nessun file presente"} type={"xmedium"} alignment={"center"}/>}>
                                <SectionLayout size={"largeRelative"}>
                                    {this.state.listaElementiVisualizzati.map(elemento => (
                                        <DownloadLine
                                            key={`elemento-${elemento[1]}`}
                                            immagineLinea={this._getElementImage(elemento[1])}
                                            nomeFile={elemento[1]}
                                            onDownload={() => this._downloadElement(elemento[0])}/>
                                    ))}
                                </SectionLayout>
                            </IfContainer>
                        </IfContainer>
                    </ContainerLayout>
                </ContainerLayout>
            </PaddingLayout>
        );
    }
}
