//QG 30/06/2024 MD

import {
    Mesh, Object3D,
    Vector3
} from "three";
import SezioneConfigurabile from "./SezioneConfigurabile";
import {
    Animazione,
    ConfigurazioneModelloInterface,
    ConfigurazioneRenderizzatoreInterface,
    ConfigurazioneSezioneBlank
} from "tici_commons";
import {calcolaBounding} from "./Utils";
import ElementoAnimabile from "./ElementoAnimabile";
import {RenderObjectUserData} from "./ElementoConfigurabile";

const mockConf: any = {
    "configurazioneModello": {
        "rotazioneModello": {
            "x": 0,
            "y": 1,
            "z": 0
        },
        "rotazioneModelloAlternativo": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "offsetOrigineModello": {
            "x": 0,
            "y": 0,
            "z": 0
        },
        "yawPitchRaggioCamera": {
            "x": 90,
            "y": 45,
            "z": 100
        },
        "modelloQuadratoFlag": true,
        "modelloAmmetteFoderaFlag": true,
        "famiglieMaterialiEscluse": [],
        "vincoliDiFormato": [],
        "visteSezioni": [
            {
                "nomeSezione": "C_C",
                "primaVista": false,
                "orientamento": {
                    "x": 45,
                    "y": 90,
                    "z": 0
                },
                "orientamentoAlternativo": {
                    "x": 45,
                    "y": 180,
                    "z": 0
                }
            },
            {
                "nomeSezione": "A_A_None",
                "primaVista": true,
                "orientamento": {
                    "x": 0,
                    "y": 0,
                    "z": 45
                },
                "orientamentoAlternativo": {
                    "x": 45,
                    "y": 0,
                    "z": 0
                }
            }
        ],
        "animazioni": {
            "animazioneApertura": {
                "frames": [
                    {
                        "nomeSezione": "C_C",
                        "stato0": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        },
                        "stato1": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": -80,
                                "y": 0,
                                "z": 0
                            }
                        }
                    },
                    {
                        "nomeSezione": "Blocco_blocco",
                        "stato0": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        },
                        "stato1": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": -4.4
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        }
                    },
                    {
                        "nomeSezione": "Foto_Foto_None",
                        "stato0": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        },
                        "stato1": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        }
                    },
                    {
                        "nomeSezione": "A_A_None",
                        "stato0": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        },
                        "stato1": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": -86,
                                "y": 0,
                                "z": 0
                            }
                        }
                    },
                    {
                        "nomeSezione": "D_D.001_None",
                        "stato0": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        },
                        "stato1": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        }
                    },
                    {
                        "nomeSezione": "B_B_None",
                        "stato0": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        },
                        "stato1": {
                            "posizione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            },
                            "rotazione": {
                                "x": 0,
                                "y": 0,
                                "z": 0
                            }
                        }
                    }
                ],
                "durata": 1000
            }
        }
    },
    "configurazioniSezioni": [
        {
            "sezioneRidimensionabile": false,
            "targetRidimensione": false,
            "nomeReale": "C_C",
            "nomeGruppo": "",
            "coloreSezione": "0xdddddd",
            "nomeVisualizzato": "C_C",
            "livelloTrasparenza": 0,
            "immagineAmmessa": 0,
            "tipologiaMaterialeSupportato": 0,
            "famiglieMaterialiIncluse": [],
            "offsetSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "dimensioneSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "sezioneNonVisibileFlag": false,
            "sezioneObbligatoriaFlag": false,
            "sezioneLegnoFlag": false,
            "sezionePlexFlag": false,
            "sezioneColoreFlag": false,
            "sezioneAmmetteUvFlag": false,
            "singolaImmagineName": "",
            "singolaImmagineBase64": "",
            "sezioneContenitrice": false,
            "sezioneContenitriceFodera": false,
            "offsetSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "statoAperturaSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitrice": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitriceAlternativa": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "sezioniContenute": [],
            "sezioniCollegate": [
                {
                    "sezione": "A_A_None",
                    "giunto": {
                        "x": 0,
                        "y": 8.2,
                        "z": -40
                    }
                }
            ],
            "offsetGiuntoRotazione": {
                "x": 0,
                "y": 0,
                "z": 40
            }
        },
        {
            "sezioneRidimensionabile": true,
            "targetRidimensione": true,
            "nomeReale": "Blocco_blocco",
            "nomeGruppo": "",
            "coloreSezione": "0xdddddd",
            "nomeVisualizzato": "Blocco_blocco",
            "livelloTrasparenza": 0,
            "immagineAmmessa": 0,
            "tipologiaMaterialeSupportato": 0,
            "famiglieMaterialiIncluse": [],
            "offsetSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "dimensioneSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "sezioneNonVisibileFlag": false,
            "sezioneObbligatoriaFlag": false,
            "sezioneLegnoFlag": false,
            "sezionePlexFlag": false,
            "sezioneColoreFlag": false,
            "sezioneAmmetteUvFlag": false,
            "singolaImmagineName": "",
            "singolaImmagineBase64": "",
            "sezioneContenitrice": true,
            "sezioneContenitriceFodera": true,
            "offsetSezioneContenitrice": {
                "x": 0,
                "y": 0.2,
                "z": 37
            },
            "statoAperturaSezioneContenitrice": {
                "x": -150.4,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitrice": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitriceAlternativa": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "sezioniContenute": [],
            "sezioniCollegate": [],
            "offsetGiuntoRotazione": {
                "x": 0,
                "y": 0,
                "z": 0
            }
        },
        {
            "sezioneRidimensionabile": true,
            "targetRidimensione": false,
            "nomeReale": "Foto_Foto_None",
            "nomeGruppo": "",
            "coloreSezione": "0xdddddd",
            "nomeVisualizzato": "Foto_Foto_None",
            "livelloTrasparenza": 0,
            "immagineAmmessa": 0,
            "tipologiaMaterialeSupportato": 0,
            "famiglieMaterialiIncluse": [],
            "offsetSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "dimensioneSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "sezioneNonVisibileFlag": true,
            "sezioneObbligatoriaFlag": false,
            "sezioneLegnoFlag": false,
            "sezionePlexFlag": false,
            "sezioneColoreFlag": false,
            "sezioneAmmetteUvFlag": false,
            "singolaImmagineName": "",
            "singolaImmagineBase64": "",
            "sezioneContenitrice": false,
            "sezioneContenitriceFodera": false,
            "offsetSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "statoAperturaSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitrice": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitriceAlternativa": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "sezioniContenute": [],
            "sezioniCollegate": [],
            "offsetGiuntoRotazione": {
                "x": 0,
                "y": 0,
                "z": 0
            }
        },
        {
            "sezioneRidimensionabile": true,
            "targetRidimensione": false,
            "nomeReale": "A_A_None",
            "nomeGruppo": "",
            "coloreSezione": "0xdddddd",
            "nomeVisualizzato": "A_A_None",
            "livelloTrasparenza": 0,
            "immagineAmmessa": 1,
            "tipologiaMaterialeSupportato": 0,
            "famiglieMaterialiIncluse": [],
            "offsetSezioneSuperficie": {
                "x": 0,
                "y": 9,
                "z": 0
            },
            "dimensioneSezioneSuperficie": {
                "x": 59.2,
                "y": 0,
                "z": 79.8
            },
            "sezioneNonVisibileFlag": false,
            "sezioneObbligatoriaFlag": false,
            "sezioneLegnoFlag": false,
            "sezionePlexFlag": false,
            "sezioneColoreFlag": false,
            "sezioneAmmetteUvFlag": true,
            "singolaImmagineName": "",
            "singolaImmagineBase64": "",
            "sezioneContenitrice": false,
            "sezioneContenitriceFodera": false,
            "offsetSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "statoAperturaSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitrice": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitriceAlternativa": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "sezioniContenute": [
                "D_D.001_None"
            ],
            "sezioniCollegate": [],
            "offsetGiuntoRotazione": {
                "x": 0,
                "y": -8.4,
                "z": 40
            },
            "sezioneRuotaAllaRotazioneModelloAlternativa": true
        },
        {
            "sezioneRidimensionabile": true,
            "targetRidimensione": false,
            "nomeReale": "D_D.001_None",
            "nomeGruppo": "",
            "coloreSezione": "0xdddddd",
            "nomeVisualizzato": "D_D.001_None",
            "livelloTrasparenza": 0,
            "immagineAmmessa": 0,
            "tipologiaMaterialeSupportato": 0,
            "famiglieMaterialiIncluse": [],
            "offsetSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "dimensioneSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "sezioneNonVisibileFlag": false,
            "sezioneObbligatoriaFlag": false,
            "sezioneLegnoFlag": false,
            "sezionePlexFlag": false,
            "sezioneColoreFlag": false,
            "sezioneAmmetteUvFlag": false,
            "singolaImmagineName": "",
            "singolaImmagineBase64": "",
            "sezioneContenitrice": false,
            "sezioneContenitriceFodera": false,
            "offsetSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "statoAperturaSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitrice": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitriceAlternativa": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "sezioniContenute": [],
            "sezioniCollegate": [],
            "offsetGiuntoRotazione": {
                "x": 0,
                "y": 0,
                "z": 0
            }
        },
        {
            "sezioneRidimensionabile": true,
            "targetRidimensione": false,
            "nomeReale": "B_B_None",
            "nomeGruppo": "",
            "coloreSezione": "0xdddddd",
            "nomeVisualizzato": "B_B_None",
            "livelloTrasparenza": 0,
            "immagineAmmessa": 0,
            "tipologiaMaterialeSupportato": 0,
            "famiglieMaterialiIncluse": [],
            "offsetSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "dimensioneSezioneSuperficie": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "sezioneNonVisibileFlag": false,
            "sezioneObbligatoriaFlag": false,
            "sezioneLegnoFlag": false,
            "sezionePlexFlag": false,
            "sezioneColoreFlag": false,
            "sezioneAmmetteUvFlag": false,
            "singolaImmagineName": "",
            "singolaImmagineBase64": "",
            "sezioneContenitrice": false,
            "sezioneContenitriceFodera": false,
            "offsetSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "statoAperturaSezioneContenitrice": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitrice": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "orientamentoSezioneContenitriceAlternativa": {
                "x": 1,
                "y": 0,
                "z": 0
            },
            "sezioniContenute": [],
            "sezioniCollegate": [
                {
                    "sezione": "C_C",
                    "giunto": {
                        "x": 0,
                        "y": 0,
                        "z": -40
                    }
                }
            ],
            "offsetGiuntoRotazione": {
                "x": 0,
                "y": 0,
                "z": 0
            }
        }
    ]
}

export class ModelloConfigurabile extends ElementoAnimabile{
    private _aspectRatioModello: string;
    private _configurazioneModello: ConfigurazioneRenderizzatoreInterface;
    private _modelloOriginale: Object3D;

    constructor(renderObject: Object3D, configurazioneModello: ConfigurazioneRenderizzatoreInterface) {
        super("RootModello", renderObject);
        this.renderObject.name = "RootModelloRenderObject";
        this._configurazioneModello = window.location.href.includes('?mock=true') ? mockConf : configurazioneModello;
        this._modelloOriginale = renderObject.clone(true);
        (window as any)['modello'] = this;
    }

    public async setupModelloConfigurabile(): Promise<SezioneConfigurabile[]>{
        const sezioniConfigurabili: SezioneConfigurabile[] = [];

        let testI = 0;
        while(this.renderObject.children.length !== 0 && testI < this.renderObject.children.length){
            const child = this.renderObject.children[testI];
            if(child instanceof Mesh) {
                const sezioneConfigurabile = new SezioneConfigurabile(
                    child,
                    this._configurazioneModello.configurazioniSezioni.find(configurazione => configurazione.nomeReale === child.name) || ConfigurazioneSezioneBlank(child.name),
                    this._configurazioneModello.configurazioneModello,
                    this
                );
                sezioniConfigurabili.push(sezioneConfigurabile);
            }else testI++;
        }
        this.elementiConfigurabili.push(...sezioniConfigurabili);
        for(const sezioneConfigurabile of sezioniConfigurabili)
            sezioneConfigurabile.sezioniConfigurabili = sezioniConfigurabili;

        this.update(true);
        return sezioniConfigurabili;
    }

    private _applicaAlberaturaModello(){
        for(const sezioneConfigurabile of this.sezioniConfigurabili){
            if(sezioneConfigurabile.configurazioneSezione.sezioniContenute.length === 0){
                let contenuta = false;
                for(const controllo of this.sezioniConfigurabili){
                    if(controllo.configurazioneSezione.sezioniContenute.includes(sezioneConfigurabile.configurazioneSezione.nomeReale)) {
                        contenuta = true;
                        break;
                    }
                }
                if(!contenuta)
                    this.renderObject.add(sezioneConfigurabile);
            }else{
                for(const sezioneContenutaName of sezioneConfigurabile.configurazioneSezione.sezioniContenute){
                    const sezioneContenuta = this.sezioniConfigurabili.find(sezioneConfigurabile => sezioneConfigurabile.name === sezioneContenutaName);
                    if(sezioneContenuta)
                        sezioneConfigurabile.renderObject.add(sezioneContenuta);
                }
                this.renderObject.add(sezioneConfigurabile);
            }
        }
    }

    public update(updateCamera = false){
        this._applicaAlberaturaModello();

        updateCamera && this.updateCamera();

        this.movimentoPivot.objectOffset.set(
            this._configurazioneModello.configurazioneModello.offsetOrigineModello.x,
            this._configurazioneModello.configurazioneModello.offsetOrigineModello.y,
            this._configurazioneModello.configurazioneModello.offsetOrigineModello.z
        );

        //Essendo una trasformazione di oggetto allora noi ruotiamo l'oggetto senza influenzare i pivot
        this.movimentoPivot.rotationVector.set(
            this._configurazioneModello.configurazioneModello[this.modalitaVisualizzazioneAlternativa ? 'rotazioneModelloAlternativo' : 'rotazioneModello'].x,
            this._configurazioneModello.configurazioneModello[this.modalitaVisualizzazioneAlternativa ? 'rotazioneModelloAlternativo' : 'rotazioneModello'].y * 90,
            this._configurazioneModello.configurazioneModello[this.modalitaVisualizzazioneAlternativa ? 'rotazioneModelloAlternativo' : 'rotazioneModello'].z
        );

        this._calcolaLarghezzaModello(this._getTargetRidimensionamento);

        super.update();
    }

    public updateCamera(){
        (this.renderObject.userData as RenderObjectUserData).initializeCameraPosition = {
            yaw: this._configurazioneModello.configurazioneModello.yawPitchRaggioCamera.x,
            pitch: this._configurazioneModello.configurazioneModello.yawPitchRaggioCamera.y,
            raggio: this._configurazioneModello.configurazioneModello.yawPitchRaggioCamera.z
        }
    }

    private _calcolaLarghezzaModello(targetRidimensione: Object3D){
        if(targetRidimensione){
            const boundingBox = calcolaBounding(targetRidimensione);

            const sizeVector = boundingBox.getSize(new Vector3());

            let scalaAsseZ = this.configurazioneModello.modelloQuadratoFlag ? (sizeVector.x / sizeVector.z) : 1;

            if(this.configurazioneModello.modelloQuadratoFlag && this._aspectRatioModello){
                const splittedData = this._aspectRatioModello.split('/');
                if(splittedData.length === 2 && splittedData[0].trim().length > 0 && splittedData[1].trim().length > 0){
                    const x = Math.ceil(parseFloat(splittedData[0].trim()));
                    const y = Math.ceil(parseFloat(splittedData[1].trim()));
                    scalaAsseZ *= this.modalitaVisualizzazioneAlternativa ? (y / x) : (x / y);
                }
            }

            this.scalaAsseZ = scalaAsseZ;
            for(const sezioneConfigurabile of this.sezioniConfigurabili){
                if(sezioneConfigurabile.configurazioneSezione.sezioneRidimensionabile){
                    let contenuta = false;
                    for(const sezioneControllo of this.sezioniConfigurabili){
                        contenuta = sezioneControllo.configurazioneSezione.sezioniContenute.includes(sezioneConfigurabile.configurazioneSezione.nomeReale);
                        if(contenuta)
                            break;
                    }

                    if(!contenuta) {
                        sezioneConfigurabile.scalaAsseZ = scalaAsseZ;
                    }
                }
            }
        }
    }

    //region Beam

    public get listaAnimazioni(): { [p: string]: Animazione } {
        return this.configurazioneModello.animazioni as any;
    }

    private get _getTargetRidimensionamento(): Object3D{
        let esito = undefined;
        if(this._modelloOriginale){
            for(const sezioneConfigurabile of this.sezioniConfigurabili){
                if(sezioneConfigurabile.configurazioneSezione.targetRidimensione){
                    this._modelloOriginale.traverse(object => {
                        if(object.name === sezioneConfigurabile.configurazioneSezione.nomeReale)
                            esito = object;
                    });
                }

                if(esito)
                    break;
            }
        }
        return esito;
    }

    public get sezioniConfigurabili(): SezioneConfigurabile[]{
        return this.elementiConfigurabili as SezioneConfigurabile[];
    }

    public get configurazioneTotaleModello(): ConfigurazioneRenderizzatoreInterface{
        return this._configurazioneModello;
    }

    public get configurazioneModello(): ConfigurazioneModelloInterface{
        return this._configurazioneModello.configurazioneModello;
    }

    public get aspectRatioModello(): string {
        return this._aspectRatioModello;
    }

    public set aspectRatioModello(value: string) {
        this._aspectRatioModello = value;
        if(this._aspectRatioModello.split('/').length === 2)
            this.update(false);
    }

//endregion
}
