//QG 30/06/2024 MD

import {ElementoConfigurabile} from "./ElementoConfigurabile";
import {Animazione, Triple} from "tici_commons";
import SezioneConfigurabile from "./SezioneConfigurabile";

export default class ElementoAnimabile extends ElementoConfigurabile{
    private _animazione: Animazione;
    private _animazioneStart: number;
    private _animazioneInvertita: boolean;
    private _onEndAnimationCallback: () => void;

    private _vista: Triple;
    private _vistaCorrente: Triple;
    private _vistaStart: number;
    private _durataVista = 500;

    public avviaAnimazione(nomeAnimazione: string, animazioneInvertita = false, onEndAnimation: () => void = undefined){
        if(this.listaAnimazioni[nomeAnimazione]){
            this._animazione = this.listaAnimazioni[nomeAnimazione];
            this._animazioneInvertita = animazioneInvertita;
            this._animazioneStart = Date.now();
            this._onEndAnimationCallback = onEndAnimation;
        }
    }

    public muoviSuVista(vista: Triple){
        this._vista = vista;
        this._vistaCorrente = {
            x: this.animationPivot.rotationVector.x,
            y: this.animationPivot.rotationVector.y,
            z: this.animationPivot.rotationVector.z
        }
        this._vistaStart = Date.now();
    }

    private _calcolaStatoAnimazione(){
        if(this._animazione){
            const now = Date.now() - this._animazioneStart;
            let perc = now / this._animazione.durata;
            let deleteAnimazione = false;

            if(perc > 1) {
                perc = 1;
                deleteAnimazione = true;
            }

            for(const frame of this._animazione.frames){
                const step0 = this._animazioneInvertita ? frame.stato1 : frame.stato0;
                const step1 = this._animazioneInvertita ? frame.stato0 : frame.stato1;

                const diffRotX = (step1.rotazione.x - step0.rotazione.x) * perc;
                const diffRotY = (step1.rotazione.y - step0.rotazione.y) * perc;
                const diffRotZ = (step1.rotazione.z - step0.rotazione.z) * perc;

                const diffPosX = (step1.posizione.x - step0.posizione.x) * perc;
                const diffPosY = (step1.posizione.y - step0.posizione.y) * perc;
                const diffPosZ = (step1.posizione.z - step0.posizione.z) * perc * this.scalaAsseZ;

                const sezioneConfigurabile =
                    (this.elementiConfigurabili as SezioneConfigurabile[]).find(sezione => sezione.configurazioneSezione.nomeReale === frame.nomeSezione);

                if(sezioneConfigurabile){
                    sezioneConfigurabile.animationPivot.rotationVector.set(
                        step0.rotazione.x + diffRotX,
                        step0.rotazione.y + diffRotY,
                        step0.rotazione.z + diffRotZ
                    );
                    sezioneConfigurabile.animationPivot.positionVector.set(
                        step0.posizione.x + diffPosX,
                        step0.posizione.y + diffPosY,
                        (step0.posizione.z * this.scalaAsseZ) + diffPosZ
                    );
                    sezioneConfigurabile.update();
                }
            }

            if(deleteAnimazione) {
                this._animazione = undefined;
                this._onEndAnimationCallback && this._onEndAnimationCallback();
            }
        }
    }

    private _calcolaStatoVista(){
        if(this._vista){
            const now = Date.now() - this._vistaStart;

            let deleteVista = false;
            let perc = now / this._durataVista;

            if(perc > 1){
                perc = 1;
                deleteVista = true;
            }

            const diffX = this._vista.x - this._vistaCorrente.x;
            const diffY = this._vista.y - this._vistaCorrente.y;
            const diffZ = this._vista.z - this._vistaCorrente.z;

            const currentX = this._vistaCorrente.x + (diffX * perc);
            const currentY = this._vistaCorrente.y + (diffY * perc);
            const currentZ = this._vistaCorrente.z + (diffZ * perc);

            this.animationPivot.rotationVector.set(
                currentX,
                currentY,
                currentZ
            )
            this.animationPivot.update();

            if(deleteVista){
                this._vista = undefined;
            }
        }
    }

    public onTick(dt: number) {
        this._calcolaStatoAnimazione();
        this._calcolaStatoVista();
        super.onTick(dt);
    }

    //region Beam

    public get listaAnimazioni(): {[key: string]: Animazione}{
        return {};
    }

    //endregion
}
