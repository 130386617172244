//QG 30/06/2024 MD

import React from "react";
import ImageService from "../../../Servicies/ImageService";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../Layout/IfContainer/IfContainer";

export interface ComandiContainerProps{
    cameraProspettica: boolean,
    cambiaCamera: (cameraProspettica: boolean) => void,
    sinistra: () => void,
    sopra: () => void,
    destra: () => void
}
export default class RenderizzatoreComandiContainer extends React.Component<ComandiContainerProps, {}>{
    public render() {
        return (
            <span className={"ComandiContainer"}>
                <span className={"ZonaCamera"}>
                    <span
                        className={`ComandoButton ${this.props.cameraProspettica && 'selected'}`}
                        onClick={() => this.props.cambiaCamera && this.props.cambiaCamera(true)}>
                        <img alt={"ButtonImage"} className={"ComandoButtonImage"} src={ImageService.getImage('_3dIcon')}/>
                        <ResponsiveLabel labelType={"whiteLabel"} content={"Prospettica"} type={"xsmall"} alignment={"center"}/>
                    </span>
                    <span
                        className={`ComandoButton ${!this.props.cameraProspettica && 'selected'}`}
                        onClick={() => this.props.cambiaCamera && this.props.cambiaCamera(false)}>
                        <img alt={"ButtonImage"} className={"ComandoButtonImage"} src={ImageService.getImage('squareIcon')}/>
                        <ResponsiveLabel labelType={"whiteLabel"} content={"Ortogonale"} type={"xsmall"} alignment={"center"}/>
                    </span>
                </span>
                <IfContainer condition={!this.props.cameraProspettica}>
                    <span className={"ZonaTrasformazioni"}>
                        <span
                            className={"ComandoButton"}
                            onClick={() => this.props.destra && this.props.destra()}>
                            <ResponsiveLabel labelType={"whiteLabel"} content={"Right"} type={"xsmall"} alignment={"center"}/>
                        </span>
                        <span
                            className={"ComandoButton"}
                            onClick={() => this.props.sopra && this.props.sopra()}>
                            <ResponsiveLabel labelType={"whiteLabel"} content={"Top"} type={"xsmall"} alignment={"center"}/>
                        </span>
                        <span
                            className={"ComandoButton"}
                            onClick={() => this.props.sinistra && this.props.sinistra()}>
                            <ResponsiveLabel labelType={"whiteLabel"} content={"Left"} type={"xsmall"} alignment={"center"}/>
                        </span>
                    </span>
                </IfContainer>
            </span>
        )
    }
}
