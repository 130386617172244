//QG 30/06/2024 MD

import {Camera, Intersection, Mesh, MeshLambertMaterial, Object3D, Vector2, Vector3} from "three";
import { Triple } from "tici_commons";

import CoreObject from "./UtilsObjects/CoreObject";

export interface EventListenerInterface{
    onUpdateMousePosition?: (mouseX: number, mouseY: number, canvas: HTMLCanvasElement, camera: Camera) => void,
    onClickCanvas?: (mouseX: number, mouseY: number,  canvas: HTMLCanvasElement, camera: Camera) => void,
    onCollisionWithMouseDetection?: (collision: Intersection) => void,
    onTick?: (dt: number) => void
}

export interface RenderObjectUserData{
    initializeCameraPosition: {yaw: number, pitch: number, raggio: number},
    escludiBounding: boolean,
    outlineHover: boolean,
    outlineSelected: boolean,
    escludiEventoClick: boolean
}

export abstract class ElementoConfigurabile extends CoreObject implements EventListenerInterface{
    private _callbackClickElemento: (elementoConfigurabile: ElementoConfigurabile) => void;
    private _renderObject: Object3D;

    private _elementiConfigurabili: ElementoConfigurabile[] = [];
    private _mousePosition = new Vector2(0, 0);
    private _selezionatoDalMouse = false;

    private _scalaAsseZ = 1;

    constructor(objectName: string, renderObject: Object3D) {
        super(objectName);
        this._renderObject = renderObject;
        (this._renderObject as Mesh).material = new MeshLambertMaterial();
        this.centerRotationPivot.mainObject = this._renderObject;
    }

    public update() {
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.update();
        super.update();
    };

    public resetVisualizzazioni(updateElemento = true){
        this.resetStatoAnimazione(false);
        this.resetStatoVisualizzazioneMeshPivot();
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.resetVisualizzazioni(false);
        updateElemento && this.update();
    }

    public addCallbackClick(callback: (elementoConfigurabile: ElementoConfigurabile) => void){
        this._callbackClickElemento = callback;
    }

    //region Eventi

    private _controllaIntersezioneMouseModello(collision: Intersection){
        const userData = this._renderObject.userData as RenderObjectUserData;
        if(!userData.escludiEventoClick){
            this._selezionatoDalMouse = this.uuidCollisionList.includes(collision?.object?.uuid || "");
            userData.outlineHover = this._selezionatoDalMouse;
            for(const elementoSelezionatoConElemento of this.elementiSelezionatiConElemento){
                (elementoSelezionatoConElemento.userData as RenderObjectUserData).outlineHover = this._selezionatoDalMouse;
            }
        }
    }

    public onUpdateMousePosition(mouseX: number, mouseY: number, canvas: HTMLCanvasElement, camera: Camera) {
        this._mousePosition.set(mouseX, mouseY);
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.onUpdateMousePosition(mouseX, mouseY, canvas, camera);
    }

    public onClickCanvas(mouseX: number, mouseY: number, canvas: HTMLCanvasElement, camera: Camera) {
        const userData = this._renderObject.userData as RenderObjectUserData;
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.onClickCanvas(mouseX, mouseY, canvas, camera);
        this._selezionatoDalMouse && !userData.escludiEventoClick && this._callbackClickElemento && this._callbackClickElemento(this);
    }

    public onCollisionWithMouseDetection(collision: Intersection){
        this._controllaIntersezioneMouseModello(collision);
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.onCollisionWithMouseDetection(collision);
    }

    public onTick(dt: number) {
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.onTick(dt);
    }

    //endregion

    //region Beam

    public set posizioneModello(data: Triple){
        this.position.set(data.x, data.y, data.z);
        this.update();
    }

    public get uuidCollisionList(): string[]{
        return [];
    }

    public get elementiSelezionatiConElemento(): Object3D[]{
        return [];
    }

    public get posizioneModello(): Vector3{
        return this.position;
    }

    public set modalitaSupportoVisivo(modalitaSupportoVisivo: boolean) {
        super.modalitaSupportoVisivo = modalitaSupportoVisivo;
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.modalitaSupportoVisivo = modalitaSupportoVisivo
    }

    public get modalitaSupportoVisivo(): boolean{
        return super.modalitaSupportoVisivo;
    }

    public set modalitaVisualizzazioneAlternativa(modalitaAlternativa: boolean) {
        super.modalitaVisualizzazioneAlternativa = modalitaAlternativa
        for(const elementoConfigurabile of this._elementiConfigurabili)
            elementoConfigurabile.modalitaVisualizzazioneAlternativa = modalitaAlternativa;
    }

    public get modalitaVisualizzazioneAlternativa(){
        return super.modalitaVisualizzazioneAlternativa
    }

    public get renderObject(): Object3D {
        return this._renderObject;
    }


    public get elementiConfigurabili(): ElementoConfigurabile[] {
        return this._elementiConfigurabili;
    }

    public get mousePosition(): Vector2 {
        return this._mousePosition;
    }

    public get selezionatoDalMouse(): boolean {
        return this._selezionatoDalMouse;
    }

    public get scalaAsseZ(): number {
        return this._scalaAsseZ;
    }

    public set scalaAsseZ(value: number) {
        this._scalaAsseZ = value;
    }

    //endregion
}
