import SezioneRequiredProps from "../../../Commons/SezioneRiquiredProps";
import React, {Fragment} from "react";
import {SelectLine} from "../../../../../../Widgets/Configuratore/CComponents";
import Modello from "../../../../../../DatabaseData/Modello";
import Formati from "../../../../../../DatabaseData/Formati";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SentenceLayout from "../../../../../../Layout/SentenceLayout/SentenceLayout";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Loader from "../../../../../../Core/Loader/Loader";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroContextInterface} from "../../../Fotolibro/Fotolibro";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import ManagerRenderizzatoreModelli
    from "../../../../../../Primary/ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import {
    GeneraListaSezioniObbligatorieCompilate,
    GeneraMessaggioSezioniNonCompilate,
    GeneraRiepilogoSezioni
} from "../../../Commons/Utils";
import SezioneConfigurabile from "../../../../../../Primary/GestioneModelli/SezioneConfigurabile";

export interface SezioneCopertinaProps {
    orientamentoAlbumPrincipale: string,
    formatoAlbumPrincipale: string
}

export interface SezioneCopertinaState {
    isLoading: boolean,
    sezioniObbligatorie: string[],
    sezioniConfigurabili: SezioneConfigurabile[],
}

export default class SezioneCopertina extends SezioneGenerica<"copertina", SezioneCopertinaProps, SezioneCopertinaState>{
    constructor(props: SezioneCopertinaProps & SezioneRequiredProps<"copertina"> & { data: FotolibroContextInterface["copertina"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            isLoading: false,
            sezioniObbligatorie: [],
            sezioniConfigurabili: []
        }
    }

    public static ErrorMessages = {
        ModelloCopertinaError: "Il modello della copertina non è stato selezionato"
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        const sezioniObbligatorie = GeneraListaSezioniObbligatorieCompilate(fotolibro.copertina.sezioniCopertina, LocalStorageService.SezioniObbligatorieCopertina);

        return GenerateRiepilogoInternalBox(
            'Copertina',
            GenerateRiepilogoData(
                `Modello copertina: ${fotolibro.copertina.modelloCopertina}`,
                SezioneCopertina.ErrorMessages.ModelloCopertinaError,
                fotolibro.copertina.modelloCopertina.length === 0
            ),
            GenerateRiepilogoData(
                undefined,
                "Alcune sezioni non sono state compilate",
                true,
                storage && sezioniObbligatorie.length < LocalStorageService.SezioniObbligatorieCopertina.length
            ),
            ...GeneraRiepilogoSezioni(fotolibro.copertina.sezioniCopertina)
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Copertina"} size={"largeRelative"} center={true}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={
                        <Fragment>
                            <SentenceLayout alignment={"center"}>
                                <ResponsiveLabel content={"Controllo vincoli"} type={"medium"} alignment={"left"}/>
                                <Loader/>
                            </SentenceLayout>
                        </Fragment>
                    }>
                    <SelectLine
                        label={"Modello copertina"}
                        placeHolder={"Seleziona"}
                        errorMessage={SezioneCopertina.ErrorMessages.ModelloCopertinaError}
                        showError={!this.$.modelloCopertina.length}
                        elements={Modello.GetModelli("copertina", this.props.formatoAlbumPrincipale)}
                        onChange={v => {
                            this.$.modelloCopertina = v;
                            this.$.sezioniCopertina = [];
                            const immaginiCopertina = this.props.storage.GetStorage().filter(storageData => storageData.category.toLowerCase().startsWith('copertina'));
                            for(const immagineCopertina of immaginiCopertina) {
                                this.props.storage.DeleteStorageData(immagineCopertina.category, immagineCopertina.name);
                            }
                        }}
                        value={this.$.modelloCopertina}/>
                </IfContainer>
                <IfContainer condition={!!GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCopertina)}>
                    <ResponsiveLabel
                        content={GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCopertina)}
                        type={"medium"}
                        alignment={"left"}
                        labelType={"errorLabel"}/>
                </IfContainer>
                <ManagerRenderizzatoreModelli
                    modalitaVisualizzazioneAlternativa={this.props.orientamentoAlbumPrincipale.toLowerCase() === 'panoramico'}
                    intercettaModificaVisualizzazione={true}
                    intercettaEventiClick={true}
                    aspectRatioModello={Formati.GetAspectRatioFromFormatoString(this.props.formatoAlbumPrincipale)}
                    nomeManager={"Copertina"}
                    datiSezioni={this.$.sezioniCopertina}
                    nomeModelloSelezionato={this.$.modelloCopertina}
                    onDatiSezioniChange={sezioniCopertina => this.$.sezioniCopertina = sezioniCopertina}
                    onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => {
                        const sezioniObbligatorie: string[] = [];
                        sezioniConfigurabili.forEach(sezioneConfigurabile => {
                            sezioneConfigurabile.aggiornaVisualizzazioneModello(this.$.sezioniCopertina, this.props.storage.GetStorage());
                            if(sezioneConfigurabile.configurazioneSezione.sezioneObbligatoriaFlag)
                                sezioniObbligatorie.push(sezioneConfigurabile.configurazioneSezione.nomeReale);
                        });
                        LocalStorageService.SezioniObbligatorieCopertina = sezioniObbligatorie;
                        this.setState({sezioniObbligatorie, sezioniConfigurabili});
                    }}
                />
            </CategorySectionLayout>
        );
    }
}
