//QG 30/06/2024 MD

import React, {Fragment} from "react";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {
    CheckLine,
    ScrollZone,
    ScrollZoneElement
} from "../../../../../Widgets/Configuratore/CComponents";
import Materiali from "../../../../../DatabaseData/Materiali";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Button from "../../../../../Core/Buttons/Button";
import Formati from "../../../../../DatabaseData/Formati";
import ContainerLayout from "../../../../../Layout/ContainerLayout/ContainerLayout";
import {InputContainer, TripleLine} from "../ConfiguratoreModelliComponents";
import ConfiguraModelloTopLevel, {
    ConfiguraModelloTopLevelProps,
    ConfiguraModelloTopLevelState
} from "./ConfiguraModelloTopLevel";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import VerticalSpace from "../../../../../Layout/VerticalSpace/VerticalSpace";


export interface ConfiguraModelloState extends ConfiguraModelloTopLevelState{
    famigliaSelezionata: string,
    formatoSelezionato: string,
}

export default class ConfiguraModello extends ConfiguraModelloTopLevel<ConfiguraModelloTopLevelProps, ConfiguraModelloState>{
    constructor(props: Readonly<ConfiguraModelloTopLevelProps> | ConfiguraModelloTopLevelProps) {
        super(props);
        this.state = {
            modelloConfigurabile: this.props.modelloConfigurabile,
            famigliaSelezionata: "",
            formatoSelezionato: ""
        }
    }

    private _SezioneFamiglieEscluse(){
        return (
            <Fragment>
                <VerticalSpace gapMobile={10} gapTablet={10} gapDesktop={10}/>
                <IfContainer
                    condition={this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse.length > 0}
                    elseComponent={
                        <ResponsiveLabel content={"Nessuna famiglia esclusa dal modello"} type={"medium"} alignment={"center"}/>
                    }>
                    <ScrollZone>{
                        this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse.map(famigliaEsclusa => (
                            <ScrollZoneElement content={famigliaEsclusa.toUpperCase()} onDeleteClick={() => {
                                const nuovaListaFamiglie = this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse.filter(famiglia => famiglia !== famigliaEsclusa);
                                this.ImpostaValoreConfigurazioneModello("famiglieMaterialiEscluse", nuovaListaFamiglie);
                            }}/>
                        ))
                    }</ScrollZone>
                </IfContainer>
                <InputContainer label={"Famiglia da escludere"}>
                    <ElementSelect
                        label={"Seleziona"}
                        value={this.state.famigliaSelezionata}
                        elements={
                            Materiali.GetFamiglieMateriali()
                                .filter(famiglia => !this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse.includes(famiglia))
                                .map(famiglia => ({label: famiglia}))
                        }
                        onChange={v => this.setState({famigliaSelezionata: v})}/>
                </InputContainer>
                <Button
                    content={"Escludi famiglia"}
                    type={"medium"}
                    buttonType={"full-normal"}
                    disabled={this.state.famigliaSelezionata.length === 0}
                    onClick={() => {
                        this.ImpostaValoreConfigurazioneModello("famiglieMaterialiEscluse", [...this.props.modelloConfigurabile.configurazioneModello.famiglieMaterialiEscluse, this.state.famigliaSelezionata]);
                        this.setState({famigliaSelezionata: ""});
                    }}/>
            </Fragment>
        )
    }

    private _SezioneVincoliFormati(){
        return (
            <Fragment>
                <VerticalSpace gapMobile={10} gapTablet={20} gapDesktop={20}/>
                <IfContainer
                    condition={this.props.modelloConfigurabile.configurazioneModello.vincoliDiFormato.length > 0}
                    elseComponent={
                        <ResponsiveLabel content={"Nessun vincolo di formato presente"} type={"medium"} alignment={"center"}/>
                    }>
                    <ScrollZone>{
                        this.props.modelloConfigurabile.configurazioneModello.vincoliDiFormato.map(vincoloFormato => (
                            <ScrollZoneElement content={vincoloFormato.toUpperCase()} onDeleteClick={() => {
                                const nuovaListaVincoli = this.props.modelloConfigurabile.configurazioneModello.vincoliDiFormato.filter(vincolo => vincolo !== vincoloFormato);
                                this.ImpostaValoreConfigurazioneModello("vincoliDiFormato", nuovaListaVincoli);
                            }}/>
                        ))
                    }</ScrollZone>
                </IfContainer>
                <InputContainer label={"Formato da vincolare"}>
                    <ElementSelect
                        label={"Seleziona"}
                        value={this.state.formatoSelezionato}
                        elements={Formati.GetFormati("*").filter(formato => {
                            return !this.props.modelloConfigurabile.configurazioneModello.vincoliDiFormato.includes(formato.label)
                        })}
                        onChange={v => this.setState({formatoSelezionato: v})}/>
                </InputContainer>
                <Button
                    content={"Vincola formato"}
                    type={"medium"}
                    buttonType={"full-normal"}
                    disabled={this.state.formatoSelezionato.length === 0}
                    onClick={() => {
                        this.ImpostaValoreConfigurazioneModello("vincoliDiFormato", [...this.props.modelloConfigurabile.configurazioneModello.vincoliDiFormato, this.state.formatoSelezionato]);
                        this.setState({formatoSelezionato: ""});
                    }}/>
            </Fragment>
        )
    }

    public render() {
        return (
            <ContainerLayout size={"largeRelative"} gap={false} scroll={true}>
                <CategorySectionLayout categoryLabel={"Configurazioni di partenza"} size={"largeRelative"}>
                    <TripleLine
                        label={"Posizione test modello (X, Y, Z):"}
                        xValue={this.props.modelloConfigurabile.posizioneModello.x}
                        yValue={this.props.modelloConfigurabile.posizioneModello.y}
                        zValue={this.props.modelloConfigurabile.posizioneModello.z}
                        onChangeValue={(x, y, z) => {
                            this.props.modelloConfigurabile.posizioneModello = {x, y, z};
                            this.props.modelloConfigurabile.update(false);
                            this.setState({modelloConfigurabile: this.props.modelloConfigurabile});
                        }}/>
                    <TripleLine
                        label={"Rotazione modello (X, Y, Z):"}
                        xValue={this.props.modelloConfigurabile.configurazioneModello.rotazioneModello.x}
                        yValue={this.props.modelloConfigurabile.configurazioneModello.rotazioneModello.y}
                        zValue={this.props.modelloConfigurabile.configurazioneModello.rotazioneModello.z}
                        onChangeValue={(x, y, z) =>
                            this.ImpostaValoreConfigurazioneModello('rotazioneModello', {x, y, z})}
                        gradi={true}/>
                    <TripleLine
                        label={"Rotazione modello alternativo (X, Y, Z):"}
                        xValue={this.props.modelloConfigurabile.configurazioneModello.rotazioneModelloAlternativo.x}
                        yValue={this.props.modelloConfigurabile.configurazioneModello.rotazioneModelloAlternativo.y}
                        zValue={this.props.modelloConfigurabile.configurazioneModello.rotazioneModelloAlternativo.z}
                        onChangeValue={(x, y, z) =>
                            this.ImpostaValoreConfigurazioneModello('rotazioneModelloAlternativo', {x, y, z})}
                        gradi={true}/>
                    <TripleLine
                        label={"Offset origine modello (X, Y, Z):"}
                        xValue={this.props.modelloConfigurabile.configurazioneModello.offsetOrigineModello.x}
                        yValue={this.props.modelloConfigurabile.configurazioneModello.offsetOrigineModello.y}
                        zValue={this.props.modelloConfigurabile.configurazioneModello.offsetOrigineModello.z}
                        onChangeValue={(x, y, z) =>
                            this.ImpostaValoreConfigurazioneModello('offsetOrigineModello', {x, y, z})}/>
                    <TripleLine
                        label={"Origine camera modello (Yaw, Pitch, Raggio):"}
                        xValue={this.props.modelloConfigurabile.configurazioneModello.yawPitchRaggioCamera.x}
                        yValue={this.props.modelloConfigurabile.configurazioneModello.yawPitchRaggioCamera.y}
                        zValue={this.props.modelloConfigurabile.configurazioneModello.yawPitchRaggioCamera.z}
                        onChangeValue={(x, y, z) =>
                            this.ImpostaValoreConfigurazioneModello('yawPitchRaggioCamera', {x, y, z}, true)}
                        gradi={true}/>
                </CategorySectionLayout>
                <CategorySectionLayout categoryLabel={"Configurazioni specifiche"} size={"largeRelative"}>
                    <CheckLine
                        label={"Modello quadrato"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.props.modelloConfigurabile.configurazioneModello.modelloQuadratoFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneModello("modelloQuadratoFlag", v)}/>
                    <CheckLine
                        label={"Modello ammette fodera"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={this.props.modelloConfigurabile.configurazioneModello.modelloAmmetteFoderaFlag}
                        onChange={v => this.ImpostaValoreConfigurazioneModello("modelloAmmetteFoderaFlag", v)}/>
                    {this._SezioneFamiglieEscluse()}
                    {this._SezioneVincoliFormati()}
                </CategorySectionLayout>
            </ContainerLayout>
        );
    }
}
