import "./OrdineLineComponents.scss";
import React, {useEffect, useState} from "react";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import Button from "../../Core/Buttons/Button";
import Loader from "../../Core/Loader/Loader";

export interface OrdineLineRecapProps{
    label?: string,
    content: string | number | boolean
}
export function OrdineLineRecap(props: OrdineLineRecapProps){
    /**
     * Effettua la traduzione del valore dal formato originale al formato di destinazione
     * @param originalValue
     */
    const valueTranslator = (originalValue: string | number | boolean): string => {
        let value = "";

        switch (typeof originalValue){
            case 'string' : {
                value = originalValue.trim().length === 0 ? 'non assegnato' : originalValue.trim();
                break;
            }
            case 'number' : {
                value = originalValue.toString();
                break;
            }
            case 'boolean' : {
                value = originalValue ? 'sì' : 'no';
            }
        }

        return value;
    }

    return (
        <span className={"Recap printable"}>
            <ResponsiveLabel
                content={props.label ? `${props.label}: ${valueTranslator(props.content)}` : props.content as string}
                type={"medium"}
                alignment={"left"}
                labelType={"recapLabel"}
                asHTML={true}/>
        </span>
    );
}

export interface OrdineLineTopSeparatorProps{
    label?: string,
    backgroundColor?: string,
    children?: any
}
export function OrdineLineTopSeparator(props: OrdineLineTopSeparatorProps){
    return (
        <div
            className={"OrdineLineTopSeparator"}
            style={{backgroundColor: props?.backgroundColor || 'white'}}>
            <IfContainer condition={!!props.label}>
                <span className={"topSeparator printable"}>
                    <ResponsiveLabel content={props.label} type={"large"} alignment={"left"} labelType={"recapLabel"}/>
                </span>
            </IfContainer>
            {props.children}
        </div>
    )
}

export interface OrdineLineLineSeparatorProps{
    label: string
}
export function OrdineLineLineSeparator(props: OrdineLineLineSeparatorProps){
    return (
        <span className={"lineSeparator printable"}>
            <ResponsiveLabel content={props.label} type={"large"} alignment={"left"} labelType={"separatorLabel"}/>
        </span>
    )
}

export interface MiniaturaProps{
    url: string,
    title?: string,
    headDownload?: string,
    downloadImage?: boolean,
}
export function Miniatura(props: MiniaturaProps){
    const [data, setData] = useState<string>();

    useEffect(() => {
        const image = new Image();
        image.addEventListener('load', () => {
            const canvas = document.createElement('canvas') as HTMLCanvasElement;
            canvas.width = image.width;
            canvas.height = image.height;
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);
            setData(canvas.toDataURL());
        });
        image.crossOrigin="anonymous"
        image.src = props.url;
    }, [props.url]);

    const scaricaImmagine = () => {
        const link = document.createElement('a');
        link.href = data;
        link.download = props.title ? `${props.headDownload ? `${props.headDownload}-` : ''}${props.title}.png` : 'metrica.png';
        link.click();
    }

    return (
        <IfContainer
            condition={!!data && data.length > 0}
            elseComponent={
                <Loader/>
            }>
            <span className={"MiniaturaWrapper"}>
            <IfContainer condition={!!props.title}>
                <span className={"TitleZone"}>
                    <ResponsiveLabel content={props.title} type={"medium"} alignment={"center"}
                                     labelType={'whiteLabel'}/>
                </span>
            </IfContainer>
            <img
                alt={"Miniatura"}
                style={{
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                }}
                src={data}
            />
            <IfContainer condition={props.downloadImage}>
                <span className={"ButtonZone"}>
                    <Button
                        onClick={() => scaricaImmagine()}
                        content={"Scarica"}
                        type={"medium"}
                        alignment={"center"}
                        buttonType={"full-normal"}/>
                </span>
            </IfContainer>
        </span>
        </IfContainer>
    )
}
