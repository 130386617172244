export default class Config{
    public static TabletBkp = 800;
    public static DesktopBkp = 1200;

    public static BackEnd: string = "http://totem.ticialbum.com:4000";
    public static PrivatePath: string = `${Config.BackEnd}/priv`;
    public static PublicPath: string = `${Config.BackEnd}/public`;
    public static UserImage: string = `${Config.BackEnd}/public/UserImage`;
    public static NoMapping: string = `${Config.BackEnd}/public/NoMapping`;
}
