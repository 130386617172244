//QG 30/06/2024 MD

import {
    Group, Mesh, MeshBasicMaterial,
    Object3D, SphereGeometry,
    Vector3
} from "three";
import {toRadians} from "../Utils";
import {RenderObjectUserData} from "../ElementoConfigurabile";

export default class Pivot extends Group{
    private _mainObject: Object3D;

    private _objectOffset = new Vector3(0, 0, 0);
    private _positionVector = new Vector3(0, 0, 0);
    private _rotationVector = new Vector3(0, 0, 0);

    private _visualizzaMeshPivot = false;
    private _meshPivot: Mesh = this._createMeshPivot();

    private _contenitoreOggetto = new Group();

    constructor() {
        super();
        this.name = "Pivot";
        this._contenitoreOggetto.name = "ContenitorePivot";
        this.add(this._contenitoreOggetto);
        this.add(this._meshPivot);
    }

    public update(){
        this._mainObject.position.set(0, 0, 0);

        this._contenitoreOggetto.position.set(
            this._objectOffset.x,
            this._objectOffset.y,
            this._objectOffset.z
        )

        this.rotation.set(0, 0, 0)
        this.rotateOnWorldAxis(new Vector3(1, 0, 0), toRadians(this._rotationVector.x));
        this.rotateOnWorldAxis(new Vector3(0, 1, 0), toRadians(this._rotationVector.y));
        this.rotateOnWorldAxis(new Vector3(0, 0, 1), toRadians(this._rotationVector.z));

        this.position.set(
            this._positionVector.x,
            this._positionVector.y,
            this._positionVector.z,
        )

        this.updateMatrix();
    }

    private _createMeshPivot(): Mesh{
        const meshPivot = new Mesh(
            new SphereGeometry(),
            new MeshBasicMaterial({
                color: 0xffff00,
                depthTest: false
            }));
        meshPivot.renderOrder = 999;
        meshPivot.name = "MeshPivot";
        (meshPivot.userData as RenderObjectUserData).escludiBounding = true;
        meshPivot.position.set(0, 0, 0);
        meshPivot.visible = false;
        return meshPivot;
    }

    //beam

    public get mainObject(): Mesh {
        return this._mainObject as Mesh;
    }

    public set mainObject(value: Object3D) {
        this._mainObject && this._mainObject.removeFromParent();
        this._mainObject = value;
        this._contenitoreOggetto.add(this._mainObject);
    }

    public get visualizzaMeshPivot(): boolean {
        return this._visualizzaMeshPivot;
    }

    public set visualizzaMeshPivot(value: boolean) {
        this._visualizzaMeshPivot = value;
        this._meshPivot.visible = this._visualizzaMeshPivot;
    }

    public get objectOffset(): Vector3 {
        return this._objectOffset;
    }

    public get rotationVector(): Vector3 {
        return this._rotationVector;
    }

    public get positionVector(): Vector3 {
        return this._positionVector;
    }
}
