import React from "react";
import "./DragAndDropMultifunzionale.scss";
import SectionLayout from "../../Layout/SectionLayout/SectionLayout";
import DADMHeader, {DADMHeaderProps} from "./Components/DADMHeader";
import DADMIndex, {DADMIndexProps} from "./Components/DADMIndex";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import DADMIndexAzione, {DADMIndexAzioneProps} from "./Components/DADMIndexAzione";
import DADMCartonciniList, {DADMCartonciniListProps} from "./Components/DADMCartonciniList";

export interface DragAndDropMultifunzionaleProps
    extends
        DADMHeaderProps,
        Omit<DADMIndexProps, "quantitaFogliComplessivi" | "posizioniSelezionate" | "aggiornaPosizioniSelezionate">,
        Omit<DADMIndexAzioneProps, "aggiungiCartaExtraSelezionati">,
        DADMCartonciniListProps{
    aggiungiCartaExtra?: (immaginiSelezionate: string[], cartaExtraSelezionata: string) => void,
    modificheCartaExtra?: boolean
}
export interface DragAndDropMultifunzionaleState {
    immaginiSelezionate: string[],
}

export default class DragAndDropMultifunzionale extends React.Component<DragAndDropMultifunzionaleProps, DragAndDropMultifunzionaleState>{
    constructor(props: Readonly<DragAndDropMultifunzionaleProps> | DragAndDropMultifunzionaleProps) {
        super(props);
        this.state = {
            immaginiSelezionate: []
        }
    }

    /**
     * Aggiorna le immagini selezionate
     * @param immaginiSelezionate Immagini da selezionare
     * @private
     */
    private _aggiornaImmaginiSelezionate(immaginiSelezionate: string[]){
        this.setState({immaginiSelezionate: immaginiSelezionate});
    }

    /**
     * Gestisce il meccanismo di eliminazione dell'immagine
     * @param nomeImmagine Nome dell'immagine da eliminare
     * @private
     */
    private _eliminaImmaginiHandle(nomeImmagine: string){
        if(this.props.onDelete){
            this.setState(currentState => ({immaginiSelezionate: currentState.immaginiSelezionate.filter(immagine => immagine !== nomeImmagine)}));
            this.props.onDelete(nomeImmagine);
        }
    }

    public render() {
        return (
            <SectionLayout size={"largeRelative"}>
                <IfContainer condition={!this.props.soloVista}>
                    <DADMHeader
                        immagini={this.props.immagini}
                        indicatoreMassimoFogli={this.props.indicatoreMassimoFogli}
                        cartoncini={this.props.cartoncini}
                        onUpload={this.props.onUpload}/>
                    <IfContainer condition={this.props.modificheCartaExtra}>
                        <DADMCartonciniList
                            cartoncini={this.props.cartoncini}
                            onDeleteCartoncino={this.props.onDeleteCartoncino}/>
                    </IfContainer>
                </IfContainer>
                <DADMIndex
                    immagini={this.props.immagini}
                    immaginiLavorazioni={this.props.immaginiLavorazioni}
                    posizioniSelezionate={this.state.immaginiSelezionate}
                    soloVista={this.props.soloVista}
                    aggiornaPosizioniSelezionate={this._aggiornaImmaginiSelezionate.bind(this)}
                    quantitaFogliComplessivi={this.props.immagini.length + (this.props.cartoncini ? this.props.cartoncini.length : 0)}
                    formatoAlbumPrincipale={this.props.formatoAlbumPrincipale}
                    modificheCartaExtra={this.props.modificheCartaExtra}
                    cartaExtra={this.props.cartaExtra}
                    cartoncini={this.props.cartoncini}
                    aggiungiCartoncino={this.props.aggiungiCartoncino}
                    onDeleteCartaExtra={this.props.onDeleteCartaExtra}
                    modificheLavorazioni={this.props.modificheLavorazioni}
                    lavorazioni={this.props.lavorazioni}
                    lavorazioniConAggiunte={this.props.lavorazioniConAggiunte}
                    onDeleteLavorazione={this.props.onDeleteLavorazione}/>
                <IfContainer condition={!this.props.soloVista && this.state.immaginiSelezionate.length > 0}>
                    <DADMIndexAzione
                        immagini={this.props.immagini.filter(immagine => this.state.immaginiSelezionate.includes(immagine.name))}
                        onDelete={this._eliminaImmaginiHandle.bind(this)}
                        modificheCartaExtra={this.props.modificheCartaExtra}
                        aggiungiCartaExtraSelezionati={(cartaExtraSelezionata) => {
                            this.props.aggiungiCartaExtra && this.props.aggiungiCartaExtra(this.state.immaginiSelezionate, cartaExtraSelezionata);
                            this.setState({immaginiSelezionate: []});
                        }}
                        modificheLavorazioni={this.props.modificheLavorazioni}
                        aggiungiLavorazione={(nomeImmagine, nomeLavorazione, datiAggiuntiviLavorazione) => {
                            const esito =
                                this.props.aggiungiLavorazione &&
                                this.props.aggiungiLavorazione(nomeImmagine, nomeLavorazione, datiAggiuntiviLavorazione);
                            esito && this.setState({immaginiSelezionate: []});
                            return esito;
                        }}/>
                </IfContainer>
            </SectionLayout>
        )
    }
}
