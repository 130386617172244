import React, {Fragment} from "react";
import {FilterRiepilogoBox, RiepilogoBox} from "../../Pages/SitoInterno/Configuratore/Commons/RiepilogoDatoModel";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import {
    OrdineLineLineSeparator,
    OrdineLineRecap,
    OrdineLineTopSeparator
} from "../../Widgets/Ordine/OrdineLineComponents";

export interface RendererDataProps {
    data: RiepilogoBox,
    backgroundColor?: string,
}

export interface RendererDataState {
    data: RiepilogoBox
}

export default class RendererData extends React.Component<RendererDataProps, RendererDataState>{
    constructor(props: Readonly<RendererDataProps> | RendererDataProps) {
        super(props);
        this.state = {
            data: undefined
        }
    }

    /**
     * Filtra i dati di input
     * @private
     */
    private _filterInput(){
        if(this.props.data){
            const clone = JSON.parse(JSON.stringify(this.props.data));
            FilterRiepilogoBox(clone);
            this.setState({data: clone})
        }
    }

    public componentDidMount() {
        this._filterInput()
    }

    public componentDidUpdate(prevProps: Readonly<RendererDataProps>, prevState: Readonly<RendererDataState>, snapshot?: any) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this._filterInput();
        }
    }

    public render() {
        return (
            this.state.data && this.state.data.riepiloghiInternalBox.length > 0 && (
                <OrdineLineTopSeparator label={this.props.data.title} backgroundColor={this.props.backgroundColor}>{
                    this.props.data.riepiloghiInternalBox.map((riepilogoInternal, internalIndex) => (
                        riepilogoInternal && riepilogoInternal.riepilogoData && riepilogoInternal.riepilogoData.length > 0 &&
                        <Fragment key={`RiepilogoInternal-${internalIndex}`}>
                            <IfContainer condition={internalIndex !== 0}>
                                <OrdineLineLineSeparator label={riepilogoInternal.title}/>
                            </IfContainer>
                            {
                                riepilogoInternal.riepilogoData && riepilogoInternal.riepilogoData.filter(riepilogoData => riepilogoData.infoLabel).map((riepilogoData, index) => (
                                    <OrdineLineRecap key={`OrdineLineRecap-${index}`} content={riepilogoData.infoLabel}/>
                                ))
                            }
                        </Fragment>
                    ))
                }</OrdineLineTopSeparator>
            )
        );
    }
}
