import Config from "../Static/Config";
import {
    AmministratoreContextResponseModel,
    DownloadMaterialePostBodyInterface,
    FotografoContextResponseModel,
    Rest
} from "tici_commons";

export default class AmministrazioneService{
    public static async MaterialeOrdineScaricato(idOrdine: number): Promise<boolean>{
        let esito = false;

        try{
            const endPoint = `${Config.PrivatePath}/DownloadOrdine/${idOrdine}`;
            const response = await Rest.Get<boolean>(endPoint);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Non é stato possibile recuperare lo stato di download del materiale", String(e));
        }

        return esito;
    }

    public static async ImpostaStatoScaricatoMaterialeOrdine(idOrdine: number): Promise<boolean>{
        let esito = false;

        try{
            const body: DownloadMaterialePostBodyInterface = {
                idOrdine
            }
            const endPoint = `${Config.PrivatePath}/DownloadOrdine`;
            const response = await Rest.Post<boolean>(endPoint, undefined, body);
            if(response && response.statusCode === 200)
                esito = response.response;
        }catch (e){
            console.error("Errore durante il settaggio dello stato di Scaricato dell'ordine", String(e));
        }

        return esito;
    }

    public static async GetAmministrazioneContext(): Promise<AmministratoreContextResponseModel | undefined>{
        let esito = undefined;

        try {
            const response = await Rest.Get<AmministratoreContextResponseModel>(`${Config.PrivatePath}/AmministrazioneContext`);
            if(response && response.statusCode === 200)
                esito = response.response
        }catch (e){
            console.error("Non è stato possibile recuperare il contesto del fotografo", String(e));
        }

        return esito;
    }
}
