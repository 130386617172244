//QG 30/06/2024 MD

import {ModelloConfigurabile} from "../../../../../Primary/GestioneModelli/ModelloConfigurabile";
import SezioneConfigurabile from "../../../../../Primary/GestioneModelli/SezioneConfigurabile";
import React, {Fragment} from "react";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {Animazione} from "tici_commons";
import {CheckLine} from "../../../../../Widgets/Configuratore/CComponents";
import Button from "../../../../../Core/Buttons/Button";
import {InputContainer, TripleLine} from "../ConfiguratoreModelliComponents";
import ElementSelect from "../../../../../Primary/Selects/ElementSelect/ElementSelect";
import DefaultInput from "../../../../../Core/DefaultInput/DefaultInput";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";

export interface ConfiguratoreAnimazioniProps{
    modelloConfigurabile: ModelloConfigurabile,
    sezioniConfigurabili: SezioneConfigurabile[],
    updateRenderView: () => void
}

export interface ConfiguratoreAnimazioniState{
    animazioneSelezionata: string,
    animazioneT0: boolean,
    animazioneInvertita: boolean,
}

export default class ConfiguraAnimazioni extends React.Component<ConfiguratoreAnimazioniProps, ConfiguratoreAnimazioniState>{
    constructor(props: Readonly<ConfiguratoreAnimazioniProps> | ConfiguratoreAnimazioniProps) {
        super(props);
        this.state = {
            animazioneSelezionata: '',
            animazioneT0: true,
            animazioneInvertita: false
        }
    }

    private _setAnimazioneVuota(statoCheckbox: boolean){
        if(statoCheckbox){
            (this.props.modelloConfigurabile.configurazioneModello.animazioni as any)[this.state.animazioneSelezionata] = {
                frames: this.props.sezioniConfigurabili.map(sezione => ({
                    nomeSezione: sezione.configurazioneSezione.nomeReale,
                    stato0: {posizione: {x: 0, y: 0, z: 0}, rotazione: {x: 0, y: 0, z: 0}},
                    stato1: {posizione: {x: 0, y: 0, z: 0}, rotazione: {x: 0, y: 0, z: 0}},
                })),
                durata: 1000
            };
        }else {
            (this.props.modelloConfigurabile.configurazioneModello.animazioni as any)[this.state.animazioneSelezionata] = false;
        }
        this.props.updateRenderView && this.props.updateRenderView();
    }

    private get _animazioneSelezionata(): Animazione | false{
        let esito: Animazione | false = false;

        if(this.props.modelloConfigurabile)
            esito = (this.props.modelloConfigurabile.configurazioneModello.animazioni as any)[this.state.animazioneSelezionata];

        return esito;
    }

    private get _listaAnimazioni(): string[]{
        const esito: string[] = [];

        if(this.props.modelloConfigurabile)
            esito.push(...Object.keys(this.props.modelloConfigurabile.configurazioneModello.animazioni));

        return esito;
    }

    private _sezioneSelezionaAnimazione(){
        return (
            <CategorySectionLayout categoryLabel={"Editor animazioni"} size={"largeRelative"}>
                <InputContainer label={"Animazione"}>
                    <ElementSelect
                        label={"Seleziona"}
                        elements={this._listaAnimazioni.map(animazione => ({label: animazione}))}
                        value={this.state.animazioneSelezionata}
                        onChange={v => this.setState({animazioneSelezionata: v})}/>
                </InputContainer>
                <IfContainer condition={!!this.state.animazioneSelezionata}>
                    <CheckLine
                        label={"Abilita animazione"}
                        checkType={"checkbox"}
                        reduce={true}
                        checked={!!this._animazioneSelezionata}
                        onChange={v => this._setAnimazioneVuota(v)}/>
                </IfContainer>
            </CategorySectionLayout>
        )
    }

    private _sezioneGestioneConfigurazioneSezione(){
        return (
            <CategorySectionLayout categoryLabel={this.state.animazioneSelezionata} size={"largeRelative"}>
                <CheckLine
                    label={`Configura frame finale`}
                    checkType={"checkbox"}
                    reduce={true}
                    checked={!this.state.animazioneT0}
                    onChange={v => this.setState({animazioneT0: !v})}/>
                <InputContainer label={"Durata animazione (ms)"}>
                    <DefaultInput
                        value={(this._animazioneSelezionata as Animazione).durata.toString()}
                        type={"number"}
                        step={100}
                        min={0}
                        onChange={v => {
                            (this._animazioneSelezionata as Animazione).durata = parseFloat(v);
                            this.props.updateRenderView && this.props.updateRenderView();
                        }}/>
                </InputContainer>
                <CheckLine
                    label={`Animazione invertita`}
                    checkType={"checkbox"}
                    reduce={true}
                    checked={this.state.animazioneInvertita}
                    onChange={animazioneInvertita => this.setState({animazioneInvertita})}/>
                <Button
                    content={"Avvia animazione"}
                    buttonType={"full-normal"}
                    type={"medium"}
                    onClick={() => this.props.modelloConfigurabile.avviaAnimazione(this.state.animazioneSelezionata, this.state.animazioneInvertita)}/>
            </CategorySectionLayout>
        )
    }

    private _sezioneConfiguraStato(){
        return (
            this.props.sezioniConfigurabili.map(sezioneConfigurabile => {
                let esito = undefined;

                const animazione = this._animazioneSelezionata as Animazione;
                const stateFrame = animazione.frames.find(frame => frame.nomeSezione === sezioneConfigurabile.configurazioneSezione.nomeReale);
                if(stateFrame){
                    const stato = this.state.animazioneT0 ? stateFrame.stato0 : stateFrame.stato1;

                    sezioneConfigurabile.animationPivot.rotationVector.set(
                        stato.rotazione.x,
                        stato.rotazione.y,
                        stato.rotazione.z
                    );

                    sezioneConfigurabile.animationPivot.positionVector.set(
                        stato.posizione.x,
                        stato.posizione.y,
                        stato.posizione.z,
                    )

                    sezioneConfigurabile.update();

                    esito = (
                        <CategorySectionLayout
                            key={`Stato-${sezioneConfigurabile.configurazioneSezione.nomeReale}-${this.state.animazioneT0 ? 't0' : 't1'}`}
                            categoryLabel={`${sezioneConfigurabile.configurazioneSezione.nomeReale} frame ${this.state.animazioneT0 ? 'iniziale' : 'finale'}`}
                            size={"largeRelative"}>
                            <TripleLine
                                label={"Rotazione (X, Y, Z)"}
                                xValue={stato.rotazione.x}
                                yValue={stato.rotazione.y}
                                zValue={stato.rotazione.z}
                                onChangeValue={(x, y, z) => {
                                    stato.rotazione = {x, y, z};
                                    this.props.updateRenderView && this.props.updateRenderView();
                                    sezioneConfigurabile.update();
                                }}
                                gradi={true}/>
                            <TripleLine
                                label={"Posizione (X, Y, Z)"}
                                xValue={stato.posizione.x}
                                yValue={stato.posizione.y}
                                zValue={stato.posizione.z}
                                onChangeValue={(x, y, z) => {
                                    stato.posizione = {x, y, z};
                                    this.props.updateRenderView && this.props.updateRenderView();
                                    sezioneConfigurabile.update();
                                }}/>
                        </CategorySectionLayout>
                    )
                }

                return esito;
            })
        )
    }

    public render(){
        return (
            <Fragment>
                {this._sezioneSelezionaAnimazione()}
                {this._animazioneSelezionata && this._sezioneGestioneConfigurazioneSezione()}
                {this._animazioneSelezionata && this._sezioneConfiguraStato()}
            </Fragment>
        )
    }
}
