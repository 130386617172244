//QG 30/06/2024 MD

import './SelettoreImmagine.scss';
import React from "react";
import SezioneConfigurabile from "../../../GestioneModelli/SezioneConfigurabile";
import {ConfigurazioneModelloInterface, ConfigurazioneSezioneInterface, InformazioneImmagineModel} from "tici_commons";
import {NavigatorImageFormatProps, NavigatorStorageMethods} from "../TiciNavigator/TiciNavigator";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import ImageService from "../../../../Servicies/ImageService";
import {ModelloConfigurabile} from "../../../GestioneModelli/ModelloConfigurabile";
import Button from "../../../../Core/Buttons/Button";
import EditorImmagini from "../EditorImmagini/EditorImmagini";
import {imageTypeSupported} from "../../../../Utils/CommonFunctions";
import SectionLayout from "../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../Core/ResponsiveLabel/ResponsiveLabel";
import DefaultInput from "../../../../Core/DefaultInput/DefaultInput";

export interface SelettoreImmagineProps extends NavigatorStorageMethods, NavigatorImageFormatProps{
    sezioneConfigurabile: SezioneConfigurabile,
    modelloConfigurabile: ModelloConfigurabile,
    configurazioneModello: ConfigurazioneModelloInterface,
    configurazioneSezione: ConfigurazioneSezioneInterface,
    tipoImmagine?: 'plex' | 'tela' | 'immagine',
    informazioneImmagine?: InformazioneImmagineModel,
    onInformazioneImmagineChange?: (nomeImmagine: string, nomeMetricheImmagine: string, nomePosizionamentoImmagine: string, posizioneXImmagine: number, posizioneYImmagine: number, scalaImmagine: number) => void,
    onInformazioneImmagineDelete?: () => void
}

export interface SelettoreImmagineState{
    zoomImmagine: number,
    spostamentoXImmagine: number,
    spostamentoYImmagine: number
}

export default class SelettoreImmagine extends React.Component<SelettoreImmagineProps, SelettoreImmagineState>{
    private _editorImmaigniRef = React.createRef<EditorImmagini>();

    constructor(props: Readonly<SelettoreImmagineProps> | SelettoreImmagineProps) {
        super(props);
        this.state = {
            zoomImmagine: 1,
            spostamentoXImmagine: 0,
            spostamentoYImmagine: 0
        }
    }

    public componentDidMount() {
        if(!this.props.informazioneImmagine || this.props.informazioneImmagine.tipoImmagine !== this.props.tipoImmagine)
            this.props.eliminaImmagine(this._categoriaImmagine);
        else if(this.props.informazioneImmagine.tipoImmagine === this.props.tipoImmagine){
            this.setState({
                spostamentoXImmagine: this.props.informazioneImmagine.posizioneXImmagine,
                spostamentoYImmagine: this.props.informazioneImmagine.posizioneYImmagine,
                zoomImmagine: this.props.informazioneImmagine.scalaImmagine
            })
        }
    }

    private get _categoriaImmagine(): string{
        return `${this.props.configurazioneSezione.nomeReale}-${this.props.tipoImmagine}-immagine`;
    }

    private get _immagineCaricata(): boolean{
        return this.props.contaImmagini && this.props.contaImmagini(this._categoriaImmagine) > 0;
    }

    private get _nomeImmagineCaricata(): string{
        return (this.props.recuperaImmagine && this.props.recuperaImmagine(this._categoriaImmagine)?.name) || '';
    }

    private get _immagineCaricataData(): string{
        return this.props.recuperaImmagine && this.props.recuperaImmagine(this._categoriaImmagine)?.url();
    }

    public render() {
        return (
            <div className={"SelettoreImmagineContainer"}>
                <span className={"InputFieldContainer"}>
                    <SectionLayout size={"largeRelative"}>
                        <ResponsiveLabel content={"Carica un immagine:"} type={"medium"} alignment={"left"}/>
                        <DefaultInput
                            type={'file'}
                            value={this._nomeImmagineCaricata}
                            onFileUpload={(fileName, fileType, file) => {
                                if(imageTypeSupported(fileType)) {
                                    this.props.eliminaImmagine && this.props.eliminaImmagine(this._categoriaImmagine);
                                    this.props.caricaImmagine && this.props.caricaImmagine(this._categoriaImmagine, fileName, file);
                                }
                            }}/>
                    </SectionLayout>
                    <IfContainer condition={this._immagineCaricata}>
                        <span
                            style={{backgroundImage: `url(${ImageService.getImage('xIcon')})`}}
                            className={"ButtonElimina"}
                            onClick={() => {
                                this.props.eliminaImmagine(this._categoriaImmagine);
                                this.props.onInformazioneImmagineDelete && this.props.onInformazioneImmagineDelete();
                            }}/>
                    </IfContainer>
                </span>
                <IfContainer condition={this._immagineCaricata}>
                    <EditorImmagini
                        ref={this._editorImmaigniRef}
                        nomeManager={this.props.nomeManager}
                        configurazioneModello={this.props.configurazioneModello}
                        configurazioneSezione={this.props.configurazioneSezione}
                        modelloConfigurabile={this.props.modelloConfigurabile}
                        immagineCaricataData={this._immagineCaricataData}
                        mostraComandi={true}
                        posizioneXImmagine={this.state.spostamentoXImmagine}
                        posizioneYImmagine={this.state.spostamentoYImmagine}
                        zoomImmagine={this.state.zoomImmagine}
                        onCambioConfigurazioniImmagine={
                            (posizioneXImmagine, posizioneYImmagine, zoomImmagine) => {
                                this.setState((oldState) => ({
                                    spostamentoXImmagine: posizioneXImmagine === undefined ? oldState.spostamentoXImmagine : posizioneXImmagine,
                                    spostamentoYImmagine: posizioneYImmagine === undefined ? oldState.spostamentoYImmagine : posizioneYImmagine,
                                    zoomImmagine: zoomImmagine === undefined ? oldState.zoomImmagine : zoomImmagine
                                }))
                            }
                        }
                        modalitaVisualizzazioneAlternativa={this.props.modalitaVisualizzazioneAlternativa}/>
                    <Button
                        content={"Conferma"}
                        type={"medium"}
                        buttonType={'full-normal'}
                        onClick={() => {
                            this.props.onInformazioneImmagineChange && this.props.onInformazioneImmagineChange(
                                this._nomeImmagineCaricata,
                                this._editorImmaigniRef.current.generaMascheraPosizionamento(8000),
                                this._editorImmaigniRef.current.generaMascheraSezione(1280),
                                this.state.spostamentoXImmagine,
                                this.state.spostamentoYImmagine,
                                this.state.zoomImmagine
                            );
                        }}/>
                </IfContainer>
            </div>
        );
    }
}
