import React, {Fragment} from "react";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import LabelLoader from "../../../Primary/LabelLoader/LabelLoader";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import CategorySwitcherElement
    from "../../../Primary/CategorySwitcher/CategorySwirtcherElement/CategorySwitcherElement";
import ImageService from "../../../Servicies/ImageService";
import CategorySwitcher from "../../../Primary/CategorySwitcher/CategorySwitcher/CategorySwitcher";
import {RecapElement, SelectLine, TextLine} from "../../../Widgets/Configuratore/CComponents";
import AnalyticsRotteService from "../../../Servicies/AnalyticsRotteService";
import {RouteDataGetType} from "tici_commons";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import {fromMsToTime} from "../../../Utils/CommonFunctions";

export interface AnalyticsRotteState {
    isLoading: boolean,

    listaRotte: string[],
    rottaSelezionata: string,

    tipoAnalitica: RouteDataGetType,
    analitiche: {[key: string]: any}[],

    filtroRotte: string
}

export default class AnalyticsRotte extends React.Component<{}, AnalyticsRotteState>{
    constructor(props: Readonly<{}> | {}) {
        super(props);
        this.state = {
            isLoading: true,

            listaRotte: [],
            rottaSelezionata: "",

            tipoAnalitica: "Debug",
            analitiche: [],

            filtroRotte: ""
        }
    }

    public async componentDidMount() {
        this.setState({listaRotte: await AnalyticsRotteService.GetRotte(), isLoading: false});
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<AnalyticsRotteState>, snapshot?: any) {
        if(this.state.tipoAnalitica !== prevState.tipoAnalitica || this.state.rottaSelezionata !== prevState.rottaSelezionata)
            this._caricaAnalitiche();
    }

    /**
     * Carica le analytiche dal server
     * @private
     */
    private async _caricaAnalitiche() {
        if(this.state.rottaSelezionata){
            this.setState({isLoading: true});
            this.setState({analitiche: await AnalyticsRotteService.GetData(this.state.rottaSelezionata, this.state.tipoAnalitica), isLoading: false});
        }
    }

    /**
     * Restituisce la lista filtrata delle analitiche
     * @private
     */
    private _listaFiltrataAnalitiche(): {[key: string]: any}[]{
        return this.state.analitiche.filter(analitica => {
            const searchString = Object.values(analitica).join(' ');
            return searchString.includes(this.state.filtroRotte);
        })
    }

    public render() {
        return (
            <Fragment>
                <CategorySwitcher>
                    <CategorySwitcherElement
                        elementIndex={0}
                        icon={ImageService.getImage("listIcon")}
                        grow={true}
                        label={"Analitiche di debug"}
                        selected={this.state.tipoAnalitica === "Debug"}
                        onClick={() => {
                            this.setState({tipoAnalitica: "Debug"})
                        }}/>
                    <CategorySwitcherElement
                        elementIndex={1}
                        icon={ImageService.getImage("listIcon")}
                        grow={true}
                        label={"Analitiche di telematica"}
                        selected={this.state.tipoAnalitica === "Telematica"}
                        onClick={() => {
                            this.setState({tipoAnalitica: "Telematica"})
                        }}/>
                    <CategorySwitcherElement
                        elementIndex={2}
                        icon={ImageService.getImage("listIcon")}
                        grow={true}
                        label={"Analitiche di informazione"}
                        selected={this.state.tipoAnalitica === "Info"}
                        onClick={() => {
                            this.setState({tipoAnalitica: "Info"})
                        }}/>
                </CategorySwitcher>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={<LabelLoader label={"Caricamento delle analitiche"}/>}>
                    <SectionLayout size={"mediumRelative"} center={true}>
                        <SelectLine
                            label={"Rotte"}
                            placeHolder={"Seleziona"}
                            elements={this.state.listaRotte.map(rotta => ({label: rotta}))}
                            value={this.state.rottaSelezionata}
                            onChange={v => this.setState({rottaSelezionata: v})}/>
                        <TextLine
                            label={"Filtro generale"}
                            value={this.state.filtroRotte}
                            onChange={v => this.setState({filtroRotte: v})}/>
                        <SectionLayout size={"largeRelative"}>
                            <IfContainer
                                condition={ this._listaFiltrataAnalitiche().length > 0}
                                elseComponent={<ResponsiveLabel content={"Nessuna analitica presente"} type={"large"} alignment={"center"}/>}>
                                {
                                    this._listaFiltrataAnalitiche().map(
                                        (analitica, index) => (
                                            <SectionLayout key={`Analitica-${index}`} size={"largeRelative"} showBorder={true} addPadding={true} lightBackground={true}>
                                                {
                                                    Object.keys(analitica).map((chiave, cIndex) => (
                                                        <RecapElement key={`Analitica-${index}-${cIndex}`} messageType={"recap"} value={`${chiave}: ${JSON.stringify(analitica[chiave])}`}/>
                                                    ))
                                                }
                                                {
                                                    analitica.timestampChiamata && analitica.timestampRisoluzione &&
                                                    <Fragment>
                                                        <RecapElement value={`Tempo esecuzione: ${fromMsToTime(parseInt(analitica.timestampRisoluzione) - parseInt(analitica.timestampChiamata))}`} messageType={"recap"}/>
                                                        <RecapElement value={`Tempo esecuzione ms: ${parseInt(analitica.timestampRisoluzione) - parseInt(analitica.timestampChiamata)} ms`} messageType={"recap"}/>
                                                    </Fragment>
                                                }
                                            </SectionLayout>
                                        )
                                    )
                                }
                            </IfContainer>
                        </SectionLayout>
                    </SectionLayout>
                </IfContainer>
            </Fragment>
        );
    }
}
