//QG 30/06/2024 MD

import React from "react";
import {ModelloConfigurabile} from "../../../../../Primary/GestioneModelli/ModelloConfigurabile";
import {ConfigurazioneModelloInterface} from "tici_commons";

export interface ConfiguraModelloTopLevelProps{
    modelloConfigurabile: ModelloConfigurabile
}

export interface ConfiguraModelloTopLevelState{
    modelloConfigurabile: ModelloConfigurabile
}

export default class ConfiguraModelloTopLevel<P extends ConfiguraModelloTopLevelProps = ConfiguraModelloTopLevelProps, S extends ConfiguraModelloTopLevelState = ConfiguraModelloTopLevelState> extends React.Component<P, S>{
    constructor(props: Readonly<P> | P) {
        super(props);
        this.state = {
            modelloConfigurabile: this.props.modelloConfigurabile
        }as any
    }

    public componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
        if(prevProps.modelloConfigurabile !== this.props.modelloConfigurabile){
            this.setState({modelloConfigurabile: this.props.modelloConfigurabile});
        }
    }

    protected ImpostaValoreConfigurazioneModello<T extends keyof ConfigurazioneModelloInterface>(chiaveValore: T, valore: ConfigurazioneModelloInterface[T], updateCamera = false){
        const modelloConfigurabile = this.state.modelloConfigurabile;
        modelloConfigurabile.configurazioneModello[chiaveValore] = valore;
        modelloConfigurabile.update(updateCamera);
        this.setState({modelloConfigurabile});
    }
}
