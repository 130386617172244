import "./MultiSelettore.scss";
import React from "react"
import {StorageData} from "../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import SingoloSelettoreImmagine from "./SingoloSelettoreImmagine";
import {SortFogliWithStorageData} from "../../Pages/SitoInterno/Configuratore/Commons/Utils";
import PlaceholderColorato from "./PlaceholderColorato";

export interface PlaceholderInterface{
    colore: string,
    posizione: number,
    formato: string
}

export interface OverlayImmagineInterface{
    nomeImmagine: string,
    overlayElement: React.ReactElement
}

export interface MultiSelettoreProps {
    immagini: (StorageData | StorageData[])[],
    placeholder?: PlaceholderInterface[],
    posizioniEscluse?: string[],
    posizioniSelezionate?: string[],
    onChange?: (posizioniSelezionate: string[]) => void,
    overlayImmagini?: OverlayImmagineInterface[];
}

export default class MultiSelettore extends React.Component<MultiSelettoreProps, {}>{
    /**
     * Genera l'array di rendering
     * @private
     */
    private _generaRenderArray(): {immagine?: StorageData | StorageData[], placeholder?: PlaceholderInterface}[]{
        let esito: {immagine?: StorageData | StorageData[], placeholder?: PlaceholderInterface}[] = [];

        if(this.props.immagini){
            esito.push(...this.props.immagini.sort(SortFogliWithStorageData).map(immagine => ({immagine})));
        }

        if(this.props.placeholder){
            for(const placeholder of this.props.placeholder){
                const arrayPos = placeholder.posizione - 1;
                const firstPart = esito.slice(0, arrayPos);
                const secondPart = esito.slice(arrayPos);
                esito = [...firstPart, {placeholder: placeholder}, ...secondPart];
            }
        }

        return esito;
    }

    public render() {
        return (
            <div className={"MultiSelettoreContainer"}>
                {
                    this._generaRenderArray().map((render, index) => {
                        const immaginePrincipale = render.immagine ?
                            ( Array.isArray(render.immagine) ? render.immagine[0] : render.immagine ) :
                            undefined;

                        return immaginePrincipale ?
                            <SingoloSelettoreImmagine
                                key={`Immagine-${index}`}
                                immagine={render.immagine}
                                order={index}
                                selezionato={this.props.posizioniSelezionate && this.props.posizioniSelezionate.includes(immaginePrincipale.name)}
                                escluso={this.props.posizioniEscluse && this.props.posizioniEscluse.includes(immaginePrincipale.name)}
                                overlaySelettore={this.props.overlayImmagini?.find(overlay => overlay.nomeImmagine === immaginePrincipale.name)?.overlayElement}
                                onClick={() => {
                                    if(this.props.onChange){
                                        if(this.props.posizioniSelezionate.includes(immaginePrincipale.name))
                                            this.props.onChange(this.props.posizioniSelezionate.filter(value => value !== immaginePrincipale.name))
                                        else this.props.onChange([immaginePrincipale.name, ...this.props.posizioniSelezionate])
                                    }
                                }}/> :
                            <PlaceholderColorato
                                key={`Placeholder-${index}`}
                                colore={render.placeholder.colore}
                                formato={render.placeholder.formato}
                                order={index}/>
                    })
                }
            </div>
        );
    }
}
