import React from "react";
import Button from "../../../../../Core/Buttons/Button";
import SezioneRequiredProps from "../../Commons/SezioneRiquiredProps";
import {FotolibroContextInterface} from "../../Fotolibro/Fotolibro";
import {
    StorageDataMapper,
    StorageManagerStatus
} from "../../Storage/StorageManager";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import TextArea from "../../../../../Core/TextArea/TextArea";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import {ImmagineMiniatura} from "../../../../../Widgets/Configuratore/CComponents";
import RenderizzatoreRiepilogo from "./RenderizzatoreRiepilogo";
import InformazioniBase from "../InformazioniBase/InformazioniBase";
import Rilegatura from "../Rilegatura/Rilegatura";
import Copertina from "../Copertina/Copertina";
import Custodia from "../Custodia/Custodia";
import Accessori from "../Accessori/Accessori";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import LocalStorageService from "../../../../../Servicies/LocalStorageService";
import {RiepilogoBox, RiepilogoData, RiepilogoInternalBox} from "../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../SezioneGenerica";
import {FotografoContextResponseModel, FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import DragAndDropMultifunzionale from "../../../../../Primary/DragAndDropMultifunzionale/DragAndDropMultifunzionale";
import TopLevelComponentStorage from "../../../../../Core/Arch/TopLevelComponentStorage";
import RedirectService from "../../../../../Servicies/RedirectService";
import FotografoService from "../../../../../Servicies/FotografoService";
import RiepilogoServiceNWS from "../../../../../Servicies/RiepilogoServiceNWS";
import {
    BackgroundOrdineManagerContext,
    BackgroundOrdineManagerContextInterface
} from "../../../../../Primary/BackgroundOrdineManager/BackgroundOrdineManager";


export interface RiepilogoProps {
    fotolibro: FotolibroModel
    datBase64?: string
}

export interface RiepilogoState {
    fotografoContext: FotografoContextResponseModel;
    hasError: boolean,
    sectionHeight: number,
}

export default class Riepilogo extends SezioneGenerica<"informazioniBase", RiepilogoProps, RiepilogoState>{
    private _element = React.createRef<any>();
    private _sectionRef = React.createRef<SectionLayout>();
    private _sizeLoop: number;

    constructor(props: RiepilogoProps & SezioneRequiredProps<"informazioniBase"> & { data: FotolibroContextInterface["informazioniBase"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            fotografoContext: undefined,
            hasError: false,
            sectionHeight: 0,
        }
        FotografoService.GetFotografoContext().then(v => this.setState({fotografoContext: v as FotografoContextResponseModel}));
    }

    public componentDidMount() {
        this._sizeLoop = window.setInterval(() => {
            if(this._sectionRef.current && (this.props.fotolibro.informazioniBase.stampaFlag || this.props.fotolibro.informazioniBase.rilegaturaFlag)){
                const height = this._sectionRef.current.containerHeight;
                if(height !== this.state.sectionHeight)
                    this.setState({sectionHeight: height});
            }
        }, 100);
    }

    public componentWillUnmount() {
        window.clearInterval(this._sizeLoop);
    }

    /**
     * Genera l'immagine dell'indice
     * @param imageWidth Larghezza dell'immagine
     * @private
     */
    private async _generaImmagineIndice(imageWidth = 1300): Promise<string | undefined>{
        return new Promise<string>(resolve => {
            if(this._element && this._element.current){
                TopLevelComponentStorage.GetTopLevel('loadingWindow').showLoadingWindow("Generazione dell'indice", "Stiamo generando l'indice");
                const element = this._element.current.cloneNode(true) as Element;
                element.setAttribute('style', `
                    width: 100% !important;
                    height: 100% !important;
                `);

                const styles = document.querySelectorAll('style');
                for(const style of styles){
                    element.append(style.cloneNode(true));
                }
                let customStyleText = "";
                for(const sheet of document.styleSheets){
                    for(const rule of sheet.cssRules){
                        customStyleText = `${customStyleText} ${rule.cssText}`;
                    }
                }
                customStyleText = `${customStyleText} .nascostoPerIndice {display: none}`
                if(customStyleText.length !== 0){
                    const customStyle = document.createElement('style') as HTMLStyleElement;
                    customStyle.innerHTML = customStyleText;
                    element.append(customStyle);
                }

                const serializer = new XMLSerializer();
                const imageString = serializer.serializeToString(element);
                const encodedElement = encodeURIComponent(imageString);
                const dataUrl = `data:image/svg+xml;charset=utf-8,${encodedElement}`;

                const canvas = document.createElement("CANVAS") as HTMLCanvasElement;
                const ctx = canvas.getContext('2d');
                const aspectRatio = this._element.current.getBoundingClientRect().height / this._element.current.getBoundingClientRect().width;
                canvas.width = imageWidth;
                canvas.height = canvas.width * aspectRatio;

                const image = new Image();
                image.width = canvas.width;
                image.height = canvas.height;
                image.addEventListener("load", () => {
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                    const imageData = canvas.toDataURL('image/png');
                    resolve(imageData);
                    TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow()
                })
                image.addEventListener('error', (e) => {
                    resolve(undefined);
                    TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
                    console.error("Errore caricamento immagine:", e);
                });
                image.src = dataUrl;
            }else {
                resolve(undefined);
                TopLevelComponentStorage.GetTopLevel('loadingWindow').hideLoadingWindow();
            }
        });
    }

    /**
     * Effettua il download del riepilogo
     * @private
     */
    private async _scaricaIndice(){
        const data = await this._generaImmagineIndice();
        if(data){
            const link = document.createElement('a');
            link.download = `indice.png`;
            link.href = data;
            link.click();
        }
    }

    /**
     * Effettua l'invio dell'ordine sul server
     * @param backgroundContext Contesto per l'invio di ordini in background
     * @private
     */
    private async _inviaOrdine(backgroundContext: BackgroundOrdineManagerContextInterface){
        let datBase64 = this.props.fotolibro.informazioniBase.rilegaturaFlag ? this.props.datBase64 : undefined;

        let indiceBase64 = undefined;
        if(this.props.fotolibro.informazioniBase.stampaFlag || ( this.props.fotolibro.informazioniBase.rilegaturaFlag && this.CountImmagini(StorageDataMapper.StampeRilegatura) > 0) )
            indiceBase64 = await this._generaImmagineIndice();
        await RiepilogoServiceNWS.InviaOrdine(backgroundContext, this.props.fotolibro, this.props.storage, indiceBase64, datBase64);
    }

    /**
     * Salva per dopo l'ordine attuale
     * @private
     */
    private _salvaPerDopo(){
        const salvaPedDopoAttuale = LocalStorageService.SalvaPerDopoData;
        if(salvaPedDopoAttuale){
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                'Conferma salvataggio',
                "Risulta presente già un salvataggio, sovrascriverlo?",
                'DoubleButton',
                () => {
                    LocalStorageService.SalvaPerDopoData = this.props.fotolibro;
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                        "Salvataggio",
                        "I dati dell'ordine corrente sono stati salvati",
                        'SingleButton',
                        () => RedirectService.GoToHomepage()
                    );
                },
                () => TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow()
            )
        }else{
            LocalStorageService.SalvaPerDopoData = this.props.fotolibro;
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Salvataggio",
                "I dati dell'ordine corrente sono stati salvati",
                'SingleButton',
                () => RedirectService.GoToHomepage()
            );
        }
    }

    render() {
        return (
            <CategorySectionLayout categoryLabel={"Riepilogo"} size={"largeRelative"} center={true}>
                <RenderizzatoreRiepilogo
                    riepiloghi={[
                        new InformazioniBase(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, false) as RiepilogoBox,
                        this.$.tipoFotolibro !== 'accessori' &&
                        this.$.tipoFotolibro !== 'tradizionale' &&
                        this.$.tipoFotolibro !== 'blocco_book' &&
                        new Rilegatura(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, false) as RiepilogoBox,
                        this.$.tipoFotolibro !== 'accessori' && new Copertina(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, false) as RiepilogoBox,
                        new Custodia(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, false) as RiepilogoBox,
                        ...(new Accessori(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, false) as RiepilogoBox[])
                    ]}
                    errorEmitter={status => {
                        this.setState({hasError: status});
                    }}/>
                <IfContainer condition={!!LocalStorageService.CopertinaImmagineGenerata || !!LocalStorageService.CustodiaImmagineGenerata}>
                    <Responsive2Col>
                        <ImmagineMiniatura immagineData={LocalStorageService.CopertinaImmagineGenerata}/>
                        <IfContainer condition={this.props.fotolibro.informazioniBase.custodiaFlag}>
                            <ImmagineMiniatura immagineData={LocalStorageService.CustodiaImmagineGenerata}/>
                        </IfContainer>
                    </Responsive2Col>
                </IfContainer>
                <SectionLayout size={"largeRelative"}>
                    <IfContainer condition={this.props.fotolibro.informazioniBase.stampaFlag || this.props.fotolibro.informazioniBase.rilegaturaFlag}>
                        <IfContainer condition={this.props.fotolibro.informazioniBase.rilegaturaFlag}>
                            <ResponsiveLabel
                                content={"Carica l'indice delle foto. Attenzione! Le foto non saranno inviate sul server"}
                                type={"medium"}
                                alignment={"center"}/>
                        </IfContainer>
                        <svg style={{width: '100%', height: `${this.state.sectionHeight}px`}} ref={this._element}>
                            <foreignObject width="100%" height="100%" >
                                <SectionLayout ref={this._sectionRef} size={"largeRelative"} lightBackground={true} addPadding={false}>
                                    <ResponsiveLabel
                                        content={`Index: ${this.props.fotolibro.informazioniBase.nomeProgetto} di ${this.state.fotografoContext ? `${this.state.fotografoContext.nome} ${this.state.fotografoContext.cognome}, ${this.state.fotografoContext.nomeStudio}` : ''}`}
                                        type={"large"}
                                        alignment={"left"}
                                        labelType={"separatorLabel"}/>
                                    <DragAndDropMultifunzionale
                                        immagini={this.ImmaginiDaCategoria( this.props.fotolibro.informazioniBase.stampaFlag ? StorageDataMapper.StampeFogli : StorageDataMapper.StampeRilegatura)}
                                        immaginiLavorazioni={this.ImmaginiLavorazioniAssociateImmagini(this.ImmaginiDaCategoria( this.props.fotolibro.informazioniBase.stampaFlag ? StorageDataMapper.StampeFogli : StorageDataMapper.StampeRilegatura))}
                                        onUpload={file => this.UplodaImmagine(StorageDataMapper.StampeRilegatura, file.name, file)}
                                        onDelete={immagine => this.DeleteImmagine(StorageDataMapper.StampeRilegatura, immagine)}
                                        soloVista={this.props.fotolibro.informazioniBase.stampaFlag}
                                        modificheLavorazioni={this.props.fotolibro.informazioniBase.stampaFlag}
                                        modificheCartaExtra={this.props.fotolibro.informazioniBase.stampaFlag}
                                        cartaExtra={this.props.fotolibro.informazioniBase.lavorazioniCartaExtra}
                                        cartoncini={this.props.fotolibro.informazioniBase.lavorazioniCartoncini}
                                        lavorazioni={this.props.fotolibro.informazioniBase.lavorazioniLavorazione}
                                        lavorazioniConAggiunte={this.props.fotolibro.informazioniBase.lavorazioniConAggiunte}
                                        formatoAlbumPrincipale={this.props.fotolibro.informazioniBase.formatoAlbum}/>
                                </SectionLayout>
                            </foreignObject>
                        </svg>
                        <Button
                            content={"Scarica indice"}
                            type={"large"}
                            buttonType={'full-normal'}
                            onClick={() => this._scaricaIndice()}/>
                    </IfContainer>
                    <ResponsiveLabel
                        content={"Note"}
                        type={"large"}
                        alignment={"left"}
                        labelType={"recapLabel"}/>
                    <TextArea
                        maxLenght={300}
                        value={this.$.note}
                        onChange={v => this.$.note = v}/>
                </SectionLayout>
                <BackgroundOrdineManagerContext.Consumer>{
                    backgroundContext => (
                        <Button
                            buttonType={"ordine"}
                            disabled={this.state.hasError}
                            content={"Invia l'ordine"}
                            type={"medium"}
                            onClick={() => this._inviaOrdine(backgroundContext)}/>
                    )
                }</BackgroundOrdineManagerContext.Consumer>
            </CategorySectionLayout>
        )
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return undefined;
    }
}
