import React, {Fragment} from "react";
import {
    StorageData,
    StorageManagerContext,
    StorageManagerStatus
} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import MultiSelettore from "../../MultiSelettore/MultiSelettore";
import SectionLayout from "../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {SelectLine, TextLine} from "../../../Widgets/Configuratore/CComponents";
import {CartaAlternativa, ImmagineAmmessaEnum, Lavorazioni} from "tici_commons";
import Responsive2Col from "../../../Layout/Responsive2Col/Responsive2Col";
import Button from "../../../Core/Buttons/Button";
import ImagePreviewOverlay from "../../ImagePreviewOverlay/ImagePreviewOverlay";
import Modello from "../../../DatabaseData/Modello";
import ManagerRenderizzatoreModelli from "../../ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import SezioneConfigurabile from "../../GestioneModelli/SezioneConfigurabile";
import {imageTypeSupported} from "../../../Utils/CommonFunctions";

export interface DADMIndexAzioneProps{
    immagini: StorageData[],
    onDelete?: (nomeImmagine: string) => void,
    modificheCartaExtra?: boolean,
    aggiungiCartaExtraSelezionati?: (cartaExtraSelezionata: string) => void,
    modificheLavorazioni?: boolean,
    aggiungiLavorazione?: (nomeImmagine: string, nomeLavorazione: string, datiAggiuntiviLavorazione?: string[]) => boolean
}

export interface DADMIndexAzioneState{
    updateIndexNumber: number,

    cartaExtraSelezionata: string,
    lavorazioneSelezionata: string,
    nomeImmagineOverlay: string,
    sezioniSecondarie: SezioneConfigurabile[]
}

export default class DADMIndexAzione extends React.Component<DADMIndexAzioneProps, DADMIndexAzioneState>{
    private _sezionePrincipale: SezioneConfigurabile;
    private _tempStorageData: StorageData[];

    constructor(props: Readonly<DADMIndexAzioneProps> | DADMIndexAzioneProps) {
        super(props);
        this.state = {
            updateIndexNumber: 0,
            cartaExtraSelezionata: "",
            lavorazioneSelezionata: "",
            nomeImmagineOverlay: "",
            sezioniSecondarie: undefined
        }
    }

    public componentDidUpdate(prevProps: Readonly<DADMIndexAzioneProps>) {
        if(prevProps.immagini.length !== this.props.immagini.length){
            this.setState({lavorazioneSelezionata: "", cartaExtraSelezionata: "", sezioniSecondarie: undefined})
        }
    }

    /**
     * Gestisce l'aggiunta di carta extra
     * @private
     */
    private _aggiungiCartaExtraHandle(){
        this.props.aggiungiCartaExtraSelezionati && this.props.aggiungiCartaExtraSelezionati(this.state.cartaExtraSelezionata);
        this.setState({cartaExtraSelezionata: ""});
    }

    /**
     * Gestisce la rimozione dei fogli
     * @private
     */
    private _rimuoviFogliHandler(){
        if(this.props.onDelete){
            for(const immagine of this.props.immagini){
                this.props.onDelete(immagine.name);
            }
        }
        this.setState({cartaExtraSelezionata: ""});
    }

    /**
     * Controlla che il modello per la lavorazione selezionata esista oppure no
     * @private
     */
    private _modelloEsiste(): boolean{
        return Modello.ModelExist(this.state.lavorazioneSelezionata);
    }

    /**
     * Aggiunge le lavorazioni
     * @private
     */
    private _aggiungiLavorazioneHandle(){
        const context = this.context as StorageManagerStatus;

        if(
            this.props.aggiungiLavorazione &&
            this.props.aggiungiLavorazione(
                this.props.immagini[0].name,
                this.state.lavorazioneSelezionata,
                this._tempStorageData ? this._tempStorageData.map(storageData => storageData.name) : undefined
            )
        ){
            this.setState({lavorazioneSelezionata: '', sezioniSecondarie: []});
            this._sezionePrincipale = undefined;
            if(this._tempStorageData){
                for(const tempData of this._tempStorageData){
                    context.SetStorageData(tempData.category, tempData.name, tempData.file);
                }
            }
            this._tempStorageData = undefined;
        }
    }

    private _inizializzaVisualizzazioneLavorazione(sezioniConfigurabili: SezioneConfigurabile[]){
        this._tempStorageData = [];

        const sezioniImmagini = sezioniConfigurabili.filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.immagineAmmessa === ImmagineAmmessaEnum.IMMAGINE);
        this._sezionePrincipale = sezioniImmagini
            .find(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.sezioneImmaginePrincipale);
        const sezioniSecondarie = sezioniImmagini
            .filter(sezioneConfigurabile => !sezioneConfigurabile.configurazioneSezione.sezioneImmaginePrincipale);

        this.setState({ sezioniSecondarie });
        const context = this.context as StorageManagerStatus;
        if(context){
            for(const sezioneSecondaria of sezioniSecondarie){
                const category = this._storageDataCatery(sezioneSecondaria);
                const _sezioneSecondariaData = context.GetStorageData(category)[0];
                if(_sezioneSecondariaData){
                    this._tempStorageData.push(_sezioneSecondariaData);
                    sezioneSecondaria.impostaImmagineSezione(_sezioneSecondariaData);
                }
            }
        }

        if(this._sezionePrincipale)
            this._sezionePrincipale.impostaImmagineSezione(this.props.immagini[0]);

        this._forceUpdate();
    }

    private _forceUpdate(){
        this.setState(currentState => ({updateIndexNumber: currentState.updateIndexNumber + 1}));
    }

    private _storageDataCatery(sezioneSecondaria: SezioneConfigurabile): string{
        return `Lavorazione-${this.props.immagini[0].name}-${sezioneSecondaria.configurazioneSezione.nomeReale}`;
    }

    private _storageDataFileName(sezioneSecondaria: SezioneConfigurabile): string{
        let name = '';

        const foundStorageData = this._tempStorageData.find(storageData => storageData.category === this._storageDataCatery(sezioneSecondaria));
        if(foundStorageData){
            name = foundStorageData.name;
        }

        return name;
    }

    private _deleteTempStorageData(sezioneSecondaria: SezioneConfigurabile){
        const category = this._storageDataCatery(sezioneSecondaria);
        this._tempStorageData = this._tempStorageData.filter(storageData => storageData.category !== category);
        this._forceUpdate();
    }

    private _saveTempStorageData(sezioneSecondaria: SezioneConfigurabile, fileName: string, file: File){
        return new Promise<StorageData>(resolve => {
            const category = this._storageDataCatery(sezioneSecondaria);
            const storageData = new StorageData(category, fileName, file, undefined, () => {
                resolve(storageData);
                this._forceUpdate();
            })
        });
    }

    public render() {
        return (
            <SectionLayout
                size={"largeRelative"}
                addPadding={true}
                showBorder={true}>
                <ResponsiveLabel content={"Selezione corrente"} type={"medium"} alignment={"center"}/>
                <MultiSelettore
                    immagini={this.props.immagini}
                    posizioniSelezionate={[]}
                    onChange={selezione => this.setState({nomeImmagineOverlay: selezione[0]})}/>
                <IfContainer condition={this.props.modificheCartaExtra}>
                    <SelectLine
                        label={"Seleziona carta extra"}
                        placeHolder={"Seleziona"}
                        elements={CartaAlternativa.filter(carta => !carta.cartoncinoFlag).map(carta => ({label: carta.nomeCarta}))}
                        value={this.state.cartaExtraSelezionata}
                        onChange={v => this.setState({cartaExtraSelezionata: v, lavorazioneSelezionata: "", sezioniSecondarie: undefined})}/>
                </IfContainer>
                {this.props.modificheLavorazioni && this.props.immagini.length === 1 && <Fragment>
                    <SelectLine
                        label={"Seleziona lavorazione"}
                        placeHolder={"Seleziona"}
                        elements={Lavorazioni.map(v => ({label: v}))}
                        value={this.state.lavorazioneSelezionata}
                        onChange={v => {
                            this.setState({
                                lavorazioneSelezionata: v,
                                sezioniSecondarie: undefined,
                                cartaExtraSelezionata: ""
                            });
                        }}/>
                    <IfContainer condition={this._modelloEsiste()}>
                        <ManagerRenderizzatoreModelli
                            nomeManager={`Lavorazione-${this.props.immagini[0].name}`}
                            aspectRatioCanvas={"2 / 1"}
                            nomeModelloSelezionato={this.state.lavorazioneSelezionata}
                            intercettaEventiClick={false}
                            onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => {
                                this._inizializzaVisualizzazioneLavorazione(sezioniConfigurabili);
                            }}
                        />
                        {
                            this.state.sezioniSecondarie && this.state.sezioniSecondarie.map(sezioneSecondaria => (
                                <TextLine
                                    label={sezioneSecondaria.configurazioneSezione.nomeVisualizzato}
                                    type={'file'}
                                    value={this._storageDataFileName(sezioneSecondaria)}
                                    onFileUpload={(fileName, fileType, file) => {
                                        if(imageTypeSupported(fileType)){
                                            this._deleteTempStorageData(sezioneSecondaria);
                                            this._saveTempStorageData(sezioneSecondaria, fileName, file).then(storageData => {
                                                sezioneSecondaria && sezioneSecondaria.impostaImmagineSezione(storageData);
                                                this._tempStorageData.push(storageData);
                                            });
                                        }
                                    }}/>
                            ))
                        }
                    </IfContainer>
                </Fragment>}
                <IfContainer
                    condition={
                        !!this.state.cartaExtraSelezionata ||
                        !!this.state.lavorazioneSelezionata
                    }
                    elseComponent={
                        <Button
                            content={this.props.immagini.length > 1 ? "Elimina immagini" : "Elimina immagine"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._rimuoviFogliHandler()}/>
                    }>
                    <Responsive2Col>
                        <Button
                            content={!!this.state.cartaExtraSelezionata || this.props.immagini.length > 1 ? "Aggiungi carta extra" : "Aggiungi lavorazione"}
                            type={"medium"}
                            disabled={this.state.sezioniSecondarie && this._tempStorageData.length < this.state.sezioniSecondarie.length}
                            buttonType={"full-normal"}
                            onClick={() => !!this.state.cartaExtraSelezionata || this.props.immagini.length > 1 ? this._aggiungiCartaExtraHandle() : this._aggiungiLavorazioneHandle()}/>
                        <Button
                            content={this.props.immagini.length > 1 ? "Elimina immagini" : "Elimina immagine"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this._rimuoviFogliHandler()}/>
                    </Responsive2Col>
                </IfContainer>
                <IfContainer condition={!!this.state.nomeImmagineOverlay}>
                    <ImagePreviewOverlay
                        nomeImmagine={this.state.nomeImmagineOverlay}
                        sorgenteImmagine={this.props.immagini.find(immagine => immagine.name === this.state.nomeImmagineOverlay)?.url()}
                        onCloseClick={() => this.setState({nomeImmagineOverlay: ""})}/>
                </IfContainer>
            </SectionLayout>
        );
    }
}

DADMIndexAzione.contextType = StorageManagerContext;
