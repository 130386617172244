import React from "react";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import VSLoader from "../../../Primary/VSLoader/VSLoader";
import ContainerLayout from "../../../Layout/ContainerLayout/ContainerLayout";

export default class OrdiniLoader extends React.Component<{}, {}>{
    public render() {
        return (
            <ContainerLayout size={"largeRelative"} gap={false}>
                <ResponsiveLabel content={"Caricamento degli ordini"} type={"medium"} alignment={"center"}/>
                <VSLoader gapMobile={100} gapTablet={100} gapDesktop={100}/>
            </ContainerLayout>
        );
    }
}
