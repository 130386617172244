import "./OrdineLine.scss";
import {AmministratoreContextResponseModel, DettaglioOrdineModel, UserContextResponseModel} from "tici_commons";
import IfContainer from "../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../Core/ResponsiveLabel/ResponsiveLabel";
import StopLight from "../StopLight/StopLight";
import ChatIcon from "../ChatIcon/ChatIcon";

import React from "react";
import Ordine from "../Ordine/Ordine";
import UserService from "../../Servicies/UserService";
import ImageService from "../../Servicies/ImageService";
import AmministrazioneService from "../../Servicies/AmministrazioneService";

export interface OrdineLineProps{
    ordine: DettaglioOrdineModel;
    isOpen: boolean;
    amministrazioneContext: AmministratoreContextResponseModel
    onClick?: (idOrdine: number) => void;
    updateOrdini?: () => void;
}

export interface OrdineLineState {
    userContext: UserContextResponseModel,
    materialeOrdineScaricato: boolean
}

export default class OrdineLine extends React.Component<OrdineLineProps, OrdineLineState>{
    constructor(props: Readonly<OrdineLineProps> | OrdineLineProps) {
        super(props);
        this.state = {
            userContext: undefined,
            materialeOrdineScaricato: false
        }

        UserService.GetUserContext().then((response) => {
            this.setState({userContext: response});
        });
    }

    public async componentDidMount() {
        const materialeOrdineScaricato = await AmministrazioneService.MaterialeOrdineScaricato(this.props.ordine.id);
        this.setState({
            materialeOrdineScaricato
        })
    }

    public render() {
        return (
            <IfContainer condition={!!this.props.ordine}>
                <div className={"OrdineLineContainer"}>
                    <span className={"Header"} onClick={() => {this.props.onClick && this.props.onClick(this.props.ordine.id)}}>
                        <span className={"BasicInfoLine"}>
                            <span className={'InfoOrdineZone'}>
                                <ResponsiveLabel content={`${this.props.ordine.id}`} type={"xmedium"} alignment={"left"} labelType={'whiteLabel'}/>
                                <StopLight lightNumber={this.props.ordine.statoErrore ? 2 : this.props.ordine.statoConferma ? 3 : 1}/>
                                <ResponsiveLabel
                                    content={`${this.props.ordine.dataCreazione.split('-').reverse().join('/')}`}
                                    type={"medium"}
                                    alignment={"center"}
                                    labelType={"whiteLabel"}/>
                            </span>
                            <span className={'IconZone'}>
                                {this.state.userContext && <ChatIcon ordineId={this.props.ordine.id}/>}
                                <IfContainer condition={this.state.materialeOrdineScaricato}>
                                    <img
                                        alt={"AzureEyeIcon"}
                                        className={"icon"}
                                        src={ImageService.getImage('storeIcon')}/>
                                </IfContainer>
                                <IfContainer condition={this.props.ordine.statoErrore === 1}>
                                    <img alt={"AlertIcon"} className={"icon"} src={ImageService.getImage('alertIcon')}/>
                                </IfContainer>
                                <IfContainer
                                    condition={this.props.isOpen}
                                    elseComponent={<img alt={"EyeIcon"} className={"iconLarge"} src={ImageService.getImage('eyeIcon')}/>}>
                                    <img alt={"AzureEyeIcon"} className={"iconLarge"} src={ImageService.getImage('azureEyeIcon')}/>
                                </IfContainer>
                            </span>
                        </span>
                        <span className={"InfoLine"}>
                            <ResponsiveLabel
                                content={`"${this.props.ordine.nomeOrdine}"`}
                                type={"large"}
                                alignment={"center"}
                                labelType={"whiteLabel"}
                                italic={true}/>
                            <ResponsiveLabel
                                content={`di`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={`${this.props.ordine.nomeFotografo} ${this.props.ordine.cognomeFotografo}`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                            <ResponsiveLabel
                                content={`${this.props.ordine.emailFotografo}`}
                                type={"medium"}
                                alignment={"center"}
                                labelType={"whiteLabel"}/>
                        </span>
                    </span>
                    <IfContainer condition={this.props.isOpen}>
                        <span className={`Body`}>
                            <Ordine
                                key={`Ordine-Line-${this.props.ordine.id}`}
                                ordine={this.props.ordine}
                                vistaAmministrazione={this.state?.userContext?.tipoUtente === 'amministrazione'}
                                tipoAmministrazione={this.props?.amministrazioneContext?.tipoAmministrazione}
                                updateOrdini={this.props.updateOrdini}
                                materialeOrdineScaricato={this.state.materialeOrdineScaricato}
                                updateStatoDownload={() => this.setState({materialeOrdineScaricato: true})}/>
                        </span>
                    </IfContainer>
                </div>
            </IfContainer>
        );
    }
}
