import {StorageData, StorageDataMapper, StorageManagerStatus} from "../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import {FotolibroModel} from "tici_commons";

export default class OrganizzatoreImmaginiService {
    public static OrganizzaImmaginiAlbumPrincipale(storage: StorageManagerStatus, fotoLibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage && fotoLibro && fotoLibro.informazioniBase.stampaFlag){
            const immagini = storage.GetStorageData(StorageDataMapper.StampeFogli);

            const mappaCartaExtra = new Map(fotoLibro.informazioniBase.lavorazioniCartaExtra);
            const mappaLavorazioni = new Map(fotoLibro.informazioniBase.lavorazioniLavorazione);
            const mappaLavorazioniConAggiunte = new Map(fotoLibro.informazioniBase.lavorazioniConAggiunte);
            for(const immagine of immagini){
                let immagineName = immagine.name;
                if(mappaLavorazioni.has(immagine.name) || mappaLavorazioniConAggiunte.has(immagine.name)) {
                    if(mappaLavorazioni.has(immagine.name)){
                        immagineName = `${immagine.name.split('.')[0]}-(Lavorazione ${mappaLavorazioni.get(immagine.name)}).${immagine.name.split('.')[1]}`;
                    }else{
                        for(const immagineLavorazione of storage.GetStorage()){
                            if(immagineLavorazione.category.startsWith(`Lavorazione-${immagine.name}`)){
                                //esito.push([`AlbumPrincipale/Lavorazione-${immagine.name.split('.')[0]}/${mappaLavorazioniConAggiunte.get(immagine.name).nomeLavorazione.replaceAll(' ', '')}`, immagineLavorazione]);
                                //-> Da eliminare in caso di rollback quanto giù
                                immagineLavorazione.name = `File aggiuntivo lavorazione per ${immagine.name.split('.')[0]}-${immagineLavorazione.name.split('.')[0]}.${immagineLavorazione.name.split('.').pop()}`;
                                esito.push([`AlbumPrincipale`, immagineLavorazione]);
                            }
                        }
                    }
                }
                if(mappaCartaExtra.has(immagine.name)) {
                    const cartaExtra = mappaCartaExtra.get(immagine.name);
                    //immagine.name = immagineName;
                    immagine.name = `${immagineName.split('.')[0]}-(Carta extra ${cartaExtra}).${immagineName.split('.').pop()}`;
                    //esito.push([`AlbumPrincipale/CartaExtra-${cartaExtra}`, immagine]);
                    esito.push([`AlbumPrincipale`, immagine]);
                }else {
                    immagine.name = immagineName;
                    //esito.push([`AlbumPrincipale/CartaPrincipale`, immagine])
                    esito.push([`AlbumPrincipale`, immagine])
                }
            }
        }

        return esito;
    }

    public static OrganizzaImmaginiAlbumetti(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage && fotolibro && fotolibro.informazioniBase.stampaFlag){
            //Recupero delle informazioni per album ridotto
            if(fotolibro.informazioniBase.albumRidottoFlag){
                let albumRidottoIndex = 0;
                for(const albumRidotto of fotolibro.informazioniBase.albumRidottoInformazioni){
                    if(
                        (!albumRidotto.albumRidottoStesseStampeDelGrandeFlag && !albumRidotto.albumRidottoStesseStampeDelPrimoFlag) ||
                        (!albumRidotto.albumRidottoStesseStampeDelPrimoFlag && albumRidottoIndex === 0)
                    ){
                        const immagini = storage.GetStorageData(`AlbumRidotto${albumRidottoIndex}StampeFogli`);
                        for(const immagine of immagini){
                            esito.push([`Albumetti/AlbumRidotto-${albumRidottoIndex + 1}`, immagine]);
                        }
                    }
                    albumRidottoIndex++;
                }
            }
        }

        return esito;
    }

    public static OrganizzaImmaginiRilegatura(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(fotolibro.rilegatura.coloreFodera.toLowerCase().includes('grafica')){
            let immagini = storage.GetStorageData(StorageDataMapper.ImmagineFoderaInizio);
            if(immagini.length > 0) {
                immagini[0].name = `ImmagineFoderaInizio.${immagini[0].name.split('.')[1]}`
                esito.push(['Rilegatura/ImmagineFodera', immagini[0]]);
            }
            immagini = storage.GetStorageData(StorageDataMapper.ImmagineFoderaFine);
            if(immagini.length > 0) {
                immagini[0].name = `ImmagineFoderaFine.${immagini[0].name.split('.')[1]}`
                esito.push(['Rilegatura/ImmagineFodera', immagini[0]]);
            }
        }

        if(fotolibro.rilegatura.logoFoderaFineFlag || fotolibro.rilegatura.logoFoderaInizioFlag){
            const immagini = storage.GetStorageData(StorageDataMapper.LogoFodera);
            if(immagini.length > 0)
                esito.push(['Rilegatura/ImmagineLogo', immagini[0]]);
        }

        return esito;
    }

    public static OrganizzaImmaginiCopertina(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage){
            const immaginiCopertina = storage.GetStorage().filter(data => data.category.toLowerCase().startsWith("copertina-"));
            for(const immagine of immaginiCopertina) {
                immagine.name = `${immagine.category}.${immagine.name.split('.')[1]}`;
                esito.push(["Copertina/AlbumPrincipale", immagine]);
            }

            if(fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.albumRidottoQuantita > 0){
                const immaginiCopertinaRidotto = storage.GetStorage().filter(data => data.category.toLowerCase().startsWith('copertinaridotto-'));
                for(const immagine of immaginiCopertinaRidotto) {
                    immagine.name = `${immagine.category}.${immagine.name.split('.')[1]}`;
                    esito.push(["Copertina/AlbumRidotto", immagine]);
                }
            }
        }

        return esito;
    }

    public static OrganizzaImmaginiCofanetto(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage){
            if(fotolibro.informazioniBase.custodiaFlag){
                const immaginiCofanetto = storage.GetStorage().filter(data => data.category.toLowerCase().startsWith('custodia'));
                for(const immagine of immaginiCofanetto) {
                    immagine.name = `${immagine.category}.${immagine.name.split('.')[1]}`;
                    esito.push(["Cofanetto/AlbumPrincipale", immagine]);
                }
                if(fotolibro.informazioniBase.albumRidottoQuantita > 0 && fotolibro.custodia.cofanettoPiccoloFlag){
                    const immaginiCofanettoRidotto = storage.GetStorage().filter(data => data.category.toLowerCase().startsWith('custodiaridotto'));
                    for(const immagine of immaginiCofanettoRidotto) {
                        immagine.name = `${immagine.category}.${immagine.name.split('.')[1]}`;
                        esito.push(["Cofanetto/Ridotto", immagine]);
                    }
                }
            }
        }

        return esito;
    }

    public static OrganizzaImmaginiAccessori(storage: StorageManagerStatus, fotolibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        let index = 0;
        for(const accessorio of fotolibro.accessori.accessori){
            const immaginiAccessorio = storage.GetStorage().filter(storageData => storageData.category.includes(`Accessorio-${index}`));
            if(immaginiAccessorio.length > 0){
                for(const immagine of immaginiAccessorio){
                    immagine.name = immagine.category.replaceAll(`Accessorio-${index}-`, '').trim() + "." + immagine.name;
                    esito.push([`Accessori/Acessorio-${accessorio[0]}-${index}`, immagine]);
                }
            }
            index++;
        }

        return esito;
    }

    public static OrganizzaImmagini(storage: StorageManagerStatus, fotoLibro: FotolibroModel): [string, StorageData][]{
        const esito: [string, StorageData][] = [];

        if(storage){
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiAlbumPrincipale(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiAlbumetti(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiRilegatura(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiCopertina(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiCofanetto(storage, fotoLibro));
            esito.push(...OrganizzatoreImmaginiService.OrganizzaImmaginiAccessori(storage, fotoLibro));
        }

        console.log(esito);

        return esito;
    }
}
