import React from "react";
import {
    CheckLine,
    SelectLine,
} from "../../../../../../Widgets/Configuratore/CComponents";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import Modello from "../../../../../../DatabaseData/Modello";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneCustodia from "./SezioneCustodia";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";
import ManagerRenderizzatoreModelli
    from "../../../../../../Primary/ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import SezioneConfigurabile from "../../../../../../Primary/GestioneModelli/SezioneConfigurabile";
import {FotolibroContextInterface} from "../../../Fotolibro/Fotolibro";
import SezioneRequiredProps from "../../../Commons/SezioneRiquiredProps";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {
    GeneraListaSezioniObbligatorieCompilate,
    GeneraMessaggioSezioniNonCompilate,
    GeneraRiepilogoSezioni
} from "../../../Commons/Utils";
import Formati from "../../../../../../DatabaseData/Formati";

export interface SezioneCofanettoPiccoloProps{
    modalitaConfiguratore?: boolean,
    formatoAlbumPrincipale: string,
}

export interface SezioneCofanettoPiccoloState{
    sezioniObbligatorie: string[],
    sezioniConfigurabili: SezioneConfigurabile[],
}

export default class SezioneCofanettoPiccolo extends SezioneGenerica<"custodia", SezioneCofanettoPiccoloProps, SezioneCofanettoPiccoloState>{
    public static ErrorMessages = {
        ImmagineTelaError: "L'immagine tela non è stata caricata"
    }

    constructor(props: SezioneCofanettoPiccoloProps & SezioneRequiredProps<"custodia"> & {
        data: FotolibroContextInterface["custodia"];
        storage: StorageManagerStatus
    }, context: any) {
        super(props, context);
        this.state = {
            sezioniObbligatorie: [],
            sezioniConfigurabili: []
        }
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        const sezioniObbligatorie = GeneraListaSezioniObbligatorieCompilate(fotolibro.custodia.sezioniCustodiaCofanettoPiccolo, LocalStorageService.SezioniObbligatorieCustodiaRidotti);

        return GenerateRiepilogoInternalBox(
            'Custodie piccole',
            ...GenerateGroup(
                fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.albumRidottoQuantita > 0 && fotolibro.custodia.cofanettoPiccoloFlag,
                GenerateRiepilogoData(
                    `Modello custodia: ${fotolibro.custodia.modelloCofanettoPiccolo}`,
                    SezioneCustodia.ErrorMessages.ModelloCustodiaError,
                    fotolibro.custodia.modelloCofanettoPiccolo.length === 0
                ),
                GenerateRiepilogoData(
                    undefined,
                    "Alcune sezioni non sono state compilate",
                    true,
                    storage && sezioniObbligatorie.length < LocalStorageService.SezioniObbligatorieCustodiaRidotti.length
                ),
                ...GeneraRiepilogoSezioni(fotolibro.custodia.sezioniCustodiaCofanettoPiccolo)
            )
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Album ridotti"} size={"largeRelative"} center={true}>
                <CheckLine
                    label={"Custodie piccole"}
                    checkType={"checkbox"}
                    onChange={v => this.$.cofanettoPiccoloFlag = v}
                    checked={this.$.cofanettoPiccoloFlag}
                    reduce={true}/>
                <IfContainer condition={this.$.cofanettoPiccoloFlag}>
                    <SelectLine
                        label={"Modello custodia"}
                        placeHolder={"Seleziona"}
                        errorMessage={SezioneCustodia.ErrorMessages.ModelloCustodiaError}
                        showError={!this.$.modelloCofanettoPiccolo}
                        elements={Modello.GetModelli("custodia", undefined, true)}
                        onChange={v => this.$.modelloCofanettoPiccolo = v}
                        value={this.$.modelloCofanettoPiccolo}/>
                    <IfContainer condition={!!GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCustodiaCofanettoPiccolo)}>
                        <ResponsiveLabel
                            content={GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCustodiaCofanettoPiccolo)}
                            type={"medium"}
                            alignment={"left"}
                            labelType={"errorLabel"}/>
                    </IfContainer>
                    <ManagerRenderizzatoreModelli
                        intercettaEventiClick={true}
                        nomeManager={"CustodiaRidotto"}
                        datiSezioni={this.$.sezioniCustodiaCofanettoPiccolo}
                        aspectRatioModello={Formati.GetAspectRatioFromFormatoString(this.props.formatoAlbumPrincipale)}
                        nomeModelloSelezionato={this.$.modelloCofanettoPiccolo}
                        onDatiSezioniChange={sezioniCustodia => this.$.sezioniCustodiaCofanettoPiccolo = sezioniCustodia}
                        visualizzaPulsanteDiApertura={true}
                        onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => {
                            const sezioniObbligatorie: string[] = [];
                            sezioniConfigurabili.forEach(sezioneConfigurabile => {
                                sezioneConfigurabile.aggiornaVisualizzazioneModello(this.$.sezioniCustodiaCofanettoPiccolo, this.props.storage.GetStorage());
                                if(sezioneConfigurabile.configurazioneSezione.sezioneObbligatoriaFlag)
                                    sezioniObbligatorie.push(sezioneConfigurabile.configurazioneSezione.nomeReale);
                            });
                            LocalStorageService.SezioniObbligatorieCustodiaRidotti = sezioniObbligatorie;
                            this.setState({sezioniObbligatorie, sezioniConfigurabili});
                        }}
                    />
                </IfContainer>
            </CategorySectionLayout>
        );
    }
}
