//QG 30/06/2024 MD

import React, {Fragment} from "react";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import {CheckLine} from "../../../../Widgets/Configuratore/CComponents";
import Button from "../../../../Core/Buttons/Button";
import CategorySectionLayout from "../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {ModelloConfigurabile} from "../../../../Primary/GestioneModelli/ModelloConfigurabile";
import {InputContainer} from "./ConfiguratoreModelliComponents";
import ElementSelect from "../../../../Primary/Selects/ElementSelect/ElementSelect";

export interface ConfiguratoreModelliModalitaProps{
    modalitaAlternativaModello: boolean,
    visualizzaBoxRiproduzioneAnimazione: boolean,
    modelloConfigurabile: ModelloConfigurabile,
    onModalitaVisualizzazioneAlternativaChange: (visualizzazioneAlternativa: boolean) => void
}

export interface ConfiguratoreModelliModalitaState{
    animazioneSelezionata: string,
    animazioneInvertita: boolean
}

export default class ConfiguratoreModelliModalita extends React.Component<ConfiguratoreModelliModalitaProps, ConfiguratoreModelliModalitaState>{
    public constructor(props: ConfiguratoreModelliModalitaProps, context: any) {
        super(props, context);
        this.state = {
            animazioneSelezionata: '',
            animazioneInvertita: false
        }
    }

    private get _listaAnimazioni(): string[]{
        const esito: string[] = [];

        if(this.props.modelloConfigurabile) {
            for(const key of Object.keys(this.props.modelloConfigurabile.configurazioneModello.animazioni)){
                (this.props.modelloConfigurabile.configurazioneModello.animazioni as any)[key] && esito.push(key);
            }
        }

        return esito;
    }

    public render() {
        return (
            <Fragment>
                <CategorySectionLayout categoryLabel={"Modalità"} size={"largeRelative"}>
                    <CheckLine
                        label={"Modalita alternativa"}
                        checkType={"checkbox"}
                        checked={this.props.modalitaAlternativaModello}
                        reduce={true}
                        onChange={v => this.props.onModalitaVisualizzazioneAlternativaChange && this.props.onModalitaVisualizzazioneAlternativaChange(v)}/>
                    <Button
                        content={"Reset camera"}
                        type={"medium"}
                        buttonType={"full-normal"}
                        onClick={() => this.props.modelloConfigurabile.updateCamera()}/>
                </CategorySectionLayout>
                <IfContainer condition={this.props.visualizzaBoxRiproduzioneAnimazione}>
                    <CategorySectionLayout categoryLabel={"Animazione"} size={"largeRelative"}>
                        <InputContainer label={'Seleziona animazione'}>
                            <ElementSelect
                                label={"Seleziona"}
                                elements={this._listaAnimazioni.map(animazione => ({label: animazione}))}
                                value={this.state.animazioneSelezionata}
                                onChange={animazioneSelezionata => this.setState({animazioneSelezionata})}/>
                        </InputContainer>
                        <IfContainer condition={!!this.state.animazioneSelezionata}>
                            <CheckLine
                                label={"Animazione invertita"}
                                checkType={"checkbox"}
                                checked={this.state.animazioneInvertita}
                                reduce={true}
                                onChange={animazioneInvertita => this.setState({animazioneInvertita})}/>
                            <Button
                                content={"Avvia animazione"}
                                buttonType={"full-normal"}
                                type={"medium"}
                                onClick={() => this.props.modelloConfigurabile.avviaAnimazione(this.state.animazioneSelezionata, this.state.animazioneInvertita)}/>
                        </IfContainer>
                    </CategorySectionLayout>
                </IfContainer>
            </Fragment>
        );
    }
}
