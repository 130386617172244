import React from "react";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {TextLine} from "../../../../../Widgets/Configuratore/CComponents";
import {
    CaricaModelloManualmente,
    CaricaModelloResponseInterface
} from "../../../../../Primary/GestioneModelli/GestioneModelliCommonFunction";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import {ConfigurazioneGestore3DBridgeContext} from "../../Bridge/ConfigurazioneGestore3DBridge";

export interface CaricaModelloProps{
    children?: any,

    configurazioneDaBridge: boolean,
    nomeModelloCaricato: string,
    onModelloUpload?: (nomeModello: string, modelloCaricato: CaricaModelloResponseInterface) => void,
}

export default class CaricaModello extends React.Component<CaricaModelloProps, {}>{
    private async _caricaModello3DManualmente(fileName: string, fileType: string, file: File){
        if(this.props.onModelloUpload && fileType.toLowerCase() === 'obj') {
            const modelloCaricato = await CaricaModelloManualmente(file);
            if (modelloCaricato)
                this.props.onModelloUpload(fileName, modelloCaricato);
        }
    }

    public render() {
        return (
            <ConfigurazioneGestore3DBridgeContext.Consumer>{
                bridge => (
                    <CategorySectionLayout
                        categoryLabel={"Informazioni configurazione"}
                        size={"largeRelative"}
                        center={true}>
                        <IfContainer
                            condition={!this.props.configurazioneDaBridge}
                            elseComponent={
                                <ResponsiveLabel content={`Modello modificato: ${bridge?.metaData?.configuratore?.configurazioneSelezionata || ''}`} type={"medium"} alignment={"left"}/>
                            }>
                            <TextLine
                                label={"Carica modello 3D"}
                                type={"file"}
                                value={this.props.nomeModelloCaricato}
                                onFileUpload={(fileName, fileType, file) => {
                                    this._caricaModello3DManualmente(fileName, fileType, file)
                                }}/>
                        </IfContainer>

                        {this.props.children && this.props.children}
                    </CategorySectionLayout>
                )
            }</ConfigurazioneGestore3DBridgeContext.Consumer>
        );
    }
}
