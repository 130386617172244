import SezioneRequiredProps from "../../../Commons/SezioneRiquiredProps";
import React, {Fragment} from "react";
import {SelectLine} from "../../../../../../Widgets/Configuratore/CComponents";
import Modello from "../../../../../../DatabaseData/Modello";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import {
    GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import SentenceLayout from "../../../../../../Layout/SentenceLayout/SentenceLayout";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import Loader from "../../../../../../Core/Loader/Loader";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroContextInterface} from "../../../Fotolibro/Fotolibro";
import {FotolibroModel, isCatalogo, TipoFotolibroType} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import ManagerRenderizzatoreModelli
    from "../../../../../../Primary/ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import {
    GeneraListaSezioniObbligatorieCompilate,
    GeneraMessaggioSezioniNonCompilate,
    GeneraRiepilogoSezioni
} from "../../../Commons/Utils";
import SezioneConfigurabile from "../../../../../../Primary/GestioneModelli/SezioneConfigurabile";
import Formati from "../../../../../../DatabaseData/Formati";

export interface SezioneCustodiaState {
    isLoading: boolean,
    sezioniObbligatorie: string[],
    sezioniConfigurabili: SezioneConfigurabile[]
}

export interface SezioneCustodiaProps {
    tipoFotolibro: TipoFotolibroType,
    formatoAlbumPrincipale: string
}

export default class SezioneCustodia extends SezioneGenerica<"custodia", SezioneCustodiaProps, SezioneCustodiaState>{
    constructor(props: SezioneCustodiaProps & SezioneRequiredProps<"custodia"> & { data: FotolibroContextInterface["custodia"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            isLoading: false,
            sezioniObbligatorie: [],
            sezioniConfigurabili: []
        }
    }

    public static ErrorMessages = {
        ModelloCustodiaError: "Il modello della custodia non è stato selezionato"
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        const sezioniObbligatorie = GeneraListaSezioniObbligatorieCompilate(fotolibro.custodia.sezioniCustodia, LocalStorageService.SezioniObbligatorieCustodia);

        return GenerateRiepilogoInternalBox(
            'Custodia',
            GenerateRiepilogoData(
                `Modello custodia: ${fotolibro.custodia.modelloCustodia}`,
                SezioneCustodia.ErrorMessages.ModelloCustodiaError,
                fotolibro.custodia.modelloCustodia.length === 0
            ),
            GenerateRiepilogoData(
                undefined,
                "Alcune sezioni non sono state compilate",
                true,
                storage && sezioniObbligatorie.length < LocalStorageService.SezioniObbligatorieCustodia.length
            ),
            ...GeneraRiepilogoSezioni(fotolibro.custodia.sezioniCustodia)
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Custodia"} size={"largeRelative"} center={true}>
                <IfContainer
                    condition={!this.state.isLoading}
                    elseComponent={
                        <Fragment>
                            <SentenceLayout alignment={"center"}>
                                <ResponsiveLabel content={"Controllo vincoli"} type={"medium"} alignment={"left"}/>
                                <Loader/>
                            </SentenceLayout>
                        </Fragment>
                    }>
                    <SelectLine
                        label={"Modello custodia"}
                        placeHolder={"Seleziona"}
                        errorMessage={"Bisogna selezionare il modello della custodia"}
                        showError={!this.$.modelloCustodia.length}
                        elements={Modello.GetModelli("custodia", this.props.formatoAlbumPrincipale)}
                        onChange={v => {
                            this.$.modelloCustodia = v;
                            this.$.sezioniCustodia = [];
                            const immaginiCustodia = this.props.storage.GetStorage().filter(storageData => storageData.category.toLowerCase().startsWith('custodia'));
                            for(const immagineCustodia of immaginiCustodia) {
                                this.props.storage.DeleteStorageData(immagineCustodia.category, immagineCustodia.name);
                            }
                        }}
                        value={this.$.modelloCustodia}
                        disabled={isCatalogo(this.props.tipoFotolibro)}/>
                </IfContainer>
                <IfContainer condition={!!GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCustodia)}>
                    <ResponsiveLabel
                        content={GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCustodia)}
                        type={"medium"}
                        alignment={"left"}
                        labelType={"errorLabel"}/>
                </IfContainer>
                <ManagerRenderizzatoreModelli
                    intercettaEventiClick={true}
                    nomeManager={"Custodia"}
                    aspectRatioModello={Formati.GetAspectRatioFromFormatoString(this.props.formatoAlbumPrincipale)}
                    datiSezioni={this.$.sezioniCustodia}
                    nomeModelloSelezionato={this.$.modelloCustodia}
                    visualizzaPulsanteDiApertura={true}
                    onDatiSezioniChange={sezioniCopertina => this.$.sezioniCustodia = sezioniCopertina}
                    onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => {
                        const sezioniObbligatorie: string[] = [];
                        sezioniConfigurabili.forEach(sezioneConfigurabile => {
                            sezioneConfigurabile.aggiornaVisualizzazioneModello(this.$.sezioniCustodia, this.props.storage.GetStorage());
                            if(sezioneConfigurabile.configurazioneSezione.sezioneObbligatoriaFlag)
                                sezioniObbligatorie.push(sezioneConfigurabile.configurazioneSezione.nomeReale);
                        });
                        LocalStorageService.SezioniObbligatorieCustodia = sezioniObbligatorie;
                        this.setState({sezioniObbligatorie, sezioniConfigurabili});
                    }}/>
            </CategorySectionLayout>
        );
    }
}
