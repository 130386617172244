//QG 30/06/2024 MD

import './TiciNavigator.scss';

import React, {Fragment} from "react";
import SezioneConfigurabile from "../../../GestioneModelli/SezioneConfigurabile";
import {
    ImmagineAmmessaEnum, InformazioneImmagineModel, InformazioneUvModel,
    NomeSezioneInformazioneType,
    SezioneGenericaModel,
    TipoSezioneConfiguratoreType
} from "tici_commons";
import {ModelloConfigurabile} from "../../../GestioneModelli/ModelloConfigurabile";
import IfContainer from "../../../../Layout/IfContainer/IfContainer";
import SelettoreDatoPrincipale from "../SelettoreDatoPrincipale/SelettoreDatoPrincipale";
import {createPortal} from "react-dom";
import MenuRadiale, {MenuRadialeItem} from "../MenuRadiale/MenuRadiale";
import Config from "../../../../Static/Config";
import SelettoreImmagine from "../SelettoreImmagine/SelettoreImmagine";
import {StorageData} from "../../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import SelettoreUv from "../SelettoreUv/SelettoreUv";
import AutoRefreshComponent from "../../../../Core/Arch/AutoRefreshComponent";
import Materiali from "../../../../DatabaseData/Materiali";

export interface NavigatorStorageMethods{
    caricaImmagine?: (nomeCategoria: string, nomeImmagine: string, fileImmagine: File) => void,
    recuperaImmagine?: (nomeCategoria: string, nomeImmagine?: string) => StorageData | undefined,
    eliminaImmagine?: (nomeCategoria: string, nomeImmagine?: string, aggiungiPrefissoManager?: boolean) => void,
    contaImmagini?: (nomeCategoria: string) => number,
    recuperaStorage?: () => StorageData[]
}

export interface NavigatorImageFormatProps{
    nomeManager: string,
    modalitaVisualizzazioneAlternativa?: boolean,
}

export interface TiciNavigatorProps extends NavigatorStorageMethods, NavigatorImageFormatProps{
    modelloConfigurabile: ModelloConfigurabile,
    sezioneConfigurabile: SezioneConfigurabile,
    posizioneXAperturaNavigator: number,
    posizioneYAperturaNavigator: number,
    onCloseNavigator: () => void,
    datiSezioni?: NomeSezioneInformazioneType[],
    onDatiSezioniChange?: (nuoveInformazioni: NomeSezioneInformazioneType[]) => void,
}

export interface TiciNavigatorState {
    larghezzaSchermo: number,
    editorVisualizzato: string,
    posizioneXNavigator: number,
    posizioneYNavigator: number,

    posizioneXContainer: number,
    posizioneYContainer: number,
    mouseHoverContentContainer: boolean,
    mouseClickHoverContainer: boolean,
}

export default class TiciNavigator extends AutoRefreshComponent<TiciNavigatorProps, TiciNavigatorState>{
    private _currentMouseX: number;
    private _currentMouseY: number;
    private _containerRef = React.createRef<HTMLDivElement>();

    constructor(props: Readonly<TiciNavigatorProps> | TiciNavigatorProps) {
        super(props);
        this.state = {
            larghezzaSchermo: 0,
            editorVisualizzato: undefined,
            posizioneXNavigator: 0,
            posizioneYNavigator: 0,

            posizioneXContainer: 50,
            posizioneYContainer: 50,
            mouseHoverContentContainer: false,
            mouseClickHoverContainer: false,
        }
        this.Delay = 10;
    }

    private _recuperaTipoSezione(): TipoSezioneConfiguratoreType{
        let esito: TipoSezioneConfiguratoreType = "sezioneMateriale";

        if(this.props.sezioneConfigurabile.configurazioneSezione.sezioneLegnoFlag)
            esito = "sezioneLegno";
        if(this.props.sezioneConfigurabile.configurazioneSezione.sezionePlexFlag)
            esito = "sezionePlex";
        if(this.props.sezioneConfigurabile.configurazioneSezione.sezioneColoreBiancoNeroFlag)
            esito = "sezioneColore";
        if(this.props.sezioneConfigurabile.configurazioneSezione.livelloTrasparenza > 0)
            esito = "sezioneTrasparenza";
        if(this.props.sezioneConfigurabile.configurazioneSezione.singolaImmagineName)
            esito = "sezioneSingolaImmagine";

        return esito;
    }

    private _recuperaDatoSezioneCorrente(): SezioneGenericaModel{
        let esito: SezioneGenericaModel = {
            tipoSezione: this._recuperaTipoSezione(),
            informazione: "",
            nomeVisualizzatoSezione: this.props.sezioneConfigurabile.configurazioneSezione.nomeVisualizzato
        }

        if(this.props.datiSezioni && this.props.datiSezioni.length > 0) {
            const targetDatoSezione = this.props.datiSezioni.find(datoSezione => datoSezione[0] === this.props.sezioneConfigurabile.configurazioneSezione.nomeReale);
            if(targetDatoSezione)
                esito = targetDatoSezione[1];
        }

        return esito;
    }

    public componentDidMount() {
        super.componentDidMount();
        this._updateLarghezzaSchermoCallback();
        this.setState({posizioneXNavigator: this.props.posizioneXAperturaNavigator, posizioneYNavigator: this.props.posizioneYAperturaNavigator});
        window.addEventListener('resize', this._updateLarghezzaSchermoCallback.bind(this));
        window.addEventListener('mousemove', this._updatePosizioneContainerCallback.bind(this));
    }

    private _cycleAggiornaPosizioneNavigator = () => {
        if(this._containerRef.current){
            const boundingClient = this._containerRef.current.getBoundingClientRect();
            this.setState({
                posizioneXNavigator: boundingClient.x,
                posizioneYNavigator: boundingClient.y
            })
        }
    }

    public componentDidUpdate(prevProps: Readonly<TiciNavigatorProps>) {
        if(prevProps.posizioneXAperturaNavigator !== this.props.posizioneXAperturaNavigator || prevProps.posizioneYAperturaNavigator !== this.props.posizioneYAperturaNavigator){
            this.setState({
                posizioneXNavigator: this.props.posizioneXAperturaNavigator,
                posizioneYNavigator: this.props.posizioneYAperturaNavigator
            })
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this._updateLarghezzaSchermoCallback.bind(this));
        window.removeEventListener('mousemove', this._updatePosizioneContainerCallback.bind(this));
    }

    private _updateLarghezzaSchermoCallback(){
        this.setState({larghezzaSchermo: window.innerWidth});
    }

    private _updatePosizioneContainerCallback(mouseEvent: MouseEvent){
        const diffX = mouseEvent.x - (this._currentMouseX || 0);
        const diffY = mouseEvent.y - (this._currentMouseY || 0);
        this._currentMouseX = mouseEvent.x;
        this._currentMouseY = mouseEvent.y;

        if(this.state.mouseClickHoverContainer) {
            const spostamentoPercentualeX = (diffX / window.innerWidth) * 100;
            const spostamentoPercentualeY = (diffY / window.innerHeight) * 100;

            this.setState(curState => ({
                posizioneXContainer: curState.posizioneXContainer + spostamentoPercentualeX,
                posizioneYContainer: curState.posizioneYContainer + spostamentoPercentualeY
            }));
        }
    }

    private _recuperaValoreCorrente(): string{
        return this._recuperaDatoSezioneCorrente().informazione;
    }

    private _recuperaInformazioniImmagine(): InformazioneImmagineModel {
        return this._recuperaDatoSezioneCorrente().informazioneImmagine;
    }

    private _recuperaInformazioniUv(): InformazioneUvModel {
        return this._recuperaDatoSezioneCorrente().informazioneUv;
    }

    private _aggiornaDatoSezione(datoSezione: SezioneGenericaModel){
        const datiSezione = this.props.datiSezioni || [];
        const nomeRealeSezione = this.props.sezioneConfigurabile.configurazioneSezione.nomeReale;

        const indexDatoSezione = datiSezione.findIndex(datoSezione => datoSezione[0] === nomeRealeSezione);
        if(indexDatoSezione === -1)
            datiSezione.push([nomeRealeSezione, datoSezione]);
        else datiSezione[indexDatoSezione] = [nomeRealeSezione, datoSezione];

        this._aggiornaApparenzaSezione(datiSezione);
        this.props.onDatiSezioniChange && this.props.onDatiSezioniChange(datiSezione);
    }

    private _settaValorePrincipaleSezione(nuovoValore: string, tipoSezione: TipoSezioneConfiguratoreType){
        const datoSezione = this._recuperaDatoSezioneCorrente();
        datoSezione.informazione = nuovoValore;
        datoSezione.tipoSezione = tipoSezione;
        this._aggiornaDatoSezione(datoSezione);
    }

    private _settaInformazioniImmagineSezione(nomeImmagine: string, nomeMetricheImmagine: string, nomePosizionamentoImmagine: string, posizioneXImmagine: number, posizioneYImmagine: number, scalaImmagine: number){
        const datoSezione = this._recuperaDatoSezioneCorrente();
        datoSezione.informazioneImmagine = {
            nomeImmagine,
            nomeMetricheImmagine,
            nomePosizionamentoImmagine,
            posizioneXImmagine,
            posizioneYImmagine,
            scalaImmagine,
            tipoImmagine: this.state.editorVisualizzato as 'plex' | 'tela'
        };
        this._cancellaInformazioniUvSezione();
        this._aggiornaDatoSezione(datoSezione);
    }

    private _cancellaInformazioniImmagineSezione(){
        const datoSezione = this._recuperaDatoSezioneCorrente();
        if(this.props.recuperaStorage){
            const categorieImmagini = this.props.recuperaStorage()
                .filter(storageData => storageData.category.includes(this.props.sezioneConfigurabile.configurazioneSezione.nomeReale) && storageData.category.includes('immagine'))
                .map(storageData => storageData.category);
            for(const categoriaImmagine of categorieImmagini){
                this.props.eliminaImmagine && this.props.eliminaImmagine(categoriaImmagine, undefined, false);
            }
            console.log("Storage:", this.props.recuperaStorage());
        }
        datoSezione.informazioneImmagine = undefined;
        this._aggiornaDatoSezione(datoSezione);
    }

    private _settaInformazioniUvSezione(nomeUv: string, nomeMetricheUv: string, nomePosizionamentoUv: string, posizioneXUv: number, posizioneYUv: number, rotazioneZUv: number, scalaUv: number){
        const datoSezione = this._recuperaDatoSezioneCorrente();
        datoSezione.informazioneUv = {
            nomeUv,
            nomeMetricheUv,
            nomePosizionamentoUv,
            posizioneXUv,
            posizioneYUv,
            rotazioneZUv,
            scalaUv
        }
        this._cancellaInformazioniImmagineSezione();
        this._aggiornaDatoSezione(datoSezione);
    }

    private _cancellaInformazioniUvSezione(){
        const datoSezione = this._recuperaDatoSezioneCorrente();
        datoSezione.informazioneUv = undefined;
        this.props.eliminaImmagine && this.props.eliminaImmagine(`${this.props.sezioneConfigurabile.configurazioneSezione.nomeReale}-uv`);
        this._aggiornaDatoSezione(datoSezione);
    }

    private _aggiornaApparenzaSezione(datiSezioni: NomeSezioneInformazioneType[]){
        this.props.sezioneConfigurabile.aggiornaVisualizzazioneModello(datiSezioni, this.props.recuperaStorage());
    }

    private _gestisciEventoMenuRadiale(elemento: string){
        switch (elemento){
            case 'close':
                this.props.onCloseNavigator && this.props.onCloseNavigator();
                break;
            default:
                this.setState({editorVisualizzato: elemento});
                break;
        }
    }

    private _inserisciUvMenuRadiale(itemMenuRadiale: MenuRadialeItem[]){
        itemMenuRadiale.push({key: 'uv', value: 'UV'});
    }

    private _inserisciItemImmaginiMenuRadiale(itemMenuRadiale: MenuRadialeItem[]){
        if(this.props.sezioneConfigurabile.configurazioneSezione.immagineAmmessa !== ImmagineAmmessaEnum.NO_IMMAGINI){
            switch (this.props.sezioneConfigurabile.configurazioneSezione.immagineAmmessa){
                case ImmagineAmmessaEnum.TELA_PLEX:
                    itemMenuRadiale.push({key: 'tela', value: 'Tela'});
                    itemMenuRadiale.push({key: 'plex', value: 'Plex'});
                    break;
                case ImmagineAmmessaEnum.TELA:
                    itemMenuRadiale.push({key: 'tela', value: 'Tela'});
                    break;
                case ImmagineAmmessaEnum.PLEX:
                    itemMenuRadiale.push({key: 'plex', value: 'Plex'});
                    break;
            }
        }
    }

    private _menuRadiale(){
        const itemMenuRadiale: MenuRadialeItem[] = [];
        const tipoSezione = this._recuperaTipoSezione();

        let configurazioneSpecialeApplicata = false;
        if(
            this.props.sezioneConfigurabile.configurazioneSezione.sezioneAmmetteUvFlag &&
            this.props.sezioneConfigurabile.configurazioneSezione.singolaImmagineName
        ){
            this._inserisciUvMenuRadiale(itemMenuRadiale);
            configurazioneSpecialeApplicata = true;
        }

        if(
            !configurazioneSpecialeApplicata &&
            this.props.sezioneConfigurabile.configurazioneSezione.livelloTrasparenza > 0
        ){
            if(this.props.sezioneConfigurabile.configurazioneSezione.sezioneAmmetteUvFlag){
                this._inserisciUvMenuRadiale(itemMenuRadiale);
            }
            this._inserisciItemImmaginiMenuRadiale(itemMenuRadiale);
            configurazioneSpecialeApplicata = true;
        }

        if(!configurazioneSpecialeApplicata){
            switch (tipoSezione){
                case "sezioneMateriale": {
                    const datoSezione = this._recuperaDatoSezioneCorrente();
                    if(!datoSezione.informazioneImmagine)
                        itemMenuRadiale.push({key: 'base', value: "Materiale"});
                    if(!datoSezione.informazione)
                        this._inserisciItemImmaginiMenuRadiale(itemMenuRadiale);
                    if(this.props.sezioneConfigurabile.configurazioneSezione.sezioneAmmetteUvFlag){
                        const datoSezione = this._recuperaDatoSezioneCorrente();
                        if(datoSezione.informazione && Materiali.CanSupportUv(datoSezione.informazione))
                            this._inserisciUvMenuRadiale(itemMenuRadiale);
                    }
                    break;
                }
                case "sezioneLegno":
                case "sezionePlex":
                case "sezioneColore": {
                    const mapperValore = {
                        'sezioneLegno': 'Legno',
                        'sezionePlex': 'Plex',
                        'sezioneColore': 'Colori'
                    }
                    itemMenuRadiale.push({key: 'base', value: mapperValore[tipoSezione]});
                    if(this.props.sezioneConfigurabile.configurazioneSezione.sezioneAmmetteUvFlag){
                        const datoSezione = this._recuperaDatoSezioneCorrente();
                        datoSezione.informazione && this._inserisciUvMenuRadiale(itemMenuRadiale);
                    }
                    break;
                }
            }
        }

        const configurazioneModello = this.props.modelloConfigurabile.configurazioneModello;
        const configurazioneSezione = this.props.sezioneConfigurabile.configurazioneSezione;
        return (
            <Fragment>
                <MenuRadiale
                    titoloMenuRadiale={"TiciNavigator"}
                    sottotitoloMenuRadiale={this.props.sezioneConfigurabile && this.props.sezioneConfigurabile.configurazioneSezione.nomeVisualizzato}
                    elementiMenuRadiale={[
                        {key: 'close', value: 'Chiudi'},
                        ...itemMenuRadiale
                    ]}
                    onClickElement={(elemento) => this._gestisciEventoMenuRadiale(elemento)}
                    posizioneXApertura={this.state.posizioneXNavigator}
                    posizioneYApertura={this.state.posizioneYNavigator}/>
                <IfContainer condition={this.state.editorVisualizzato !== undefined}>
                    <div
                        className={"ContentContainer"}
                        ref={this._containerRef}
                        style={{top: `${this.state.posizioneYContainer}%`, left: `${this.state.posizioneXContainer}%`, cursor: this.state.mouseClickHoverContainer ? 'grabbing': 'grab'}}
                        onMouseEnter={() => this.setState({mouseHoverContentContainer: true})}
                        onMouseLeave={() => this.setState({mouseHoverContentContainer: false})}
                        onMouseDown={() => this.setState(curState => ({mouseClickHoverContainer: curState.mouseHoverContentContainer}))}
                        onMouseUp={() => this.setState({mouseClickHoverContainer: false})}>
                        <span className={"ToolSpostamento"}/>
                        <IfContainer condition={this.state.editorVisualizzato === 'base'}>
                            <SelettoreDatoPrincipale
                                sezioneConfigurabile={this.props.sezioneConfigurabile}
                                configurazioneModello={configurazioneModello}
                                configurazioneSezione={configurazioneSezione}
                                valoreCorrente={this._recuperaValoreCorrente()}
                                datiSezioni={this.props.datiSezioni}
                                onDatoPrincipaleChange={this._settaValorePrincipaleSezione.bind(this)}/>
                        </IfContainer>
                        <IfContainer condition={this.state.editorVisualizzato === 'tela' || this.state.editorVisualizzato === 'plex'}>
                            <SelettoreImmagine
                                nomeManager={this.props.nomeManager}
                                modelloConfigurabile={this.props.modelloConfigurabile}
                                sezioneConfigurabile={this.props.sezioneConfigurabile}
                                configurazioneModello={configurazioneModello}
                                configurazioneSezione={configurazioneSezione}
                                recuperaImmagine={this.props.recuperaImmagine}
                                eliminaImmagine={this.props.eliminaImmagine}
                                caricaImmagine={this.props.caricaImmagine}
                                contaImmagini={this.props.contaImmagini}
                                tipoImmagine={this.state.editorVisualizzato as 'tela' | 'plex'}
                                informazioneImmagine={this._recuperaInformazioniImmagine()}
                                onInformazioneImmagineChange={this._settaInformazioniImmagineSezione.bind(this)}
                                onInformazioneImmagineDelete={this._cancellaInformazioniImmagineSezione.bind(this)}
                                modalitaVisualizzazioneAlternativa={this.props.modalitaVisualizzazioneAlternativa}/>
                        </IfContainer>
                        <IfContainer condition={this.state.editorVisualizzato === 'uv'}>
                            <SelettoreUv
                                nomeManager={this.props.nomeManager}
                                modelloConfigurabile={this.props.modelloConfigurabile}
                                sezioneConfigurabile={this.props.sezioneConfigurabile}
                                configurazioneModello={configurazioneModello}
                                configurazioneSezione={configurazioneSezione}
                                recuperaImmagine={this.props.recuperaImmagine}
                                eliminaImmagine={this.props.eliminaImmagine}
                                caricaImmagine={this.props.caricaImmagine}
                                contaImmagini={this.props.contaImmagini}
                                informazioneUv={this._recuperaInformazioniUv()}
                                onInformazioneUvChange={this._settaInformazioniUvSezione.bind(this)}
                                onInformazioneUvDelete={this._cancellaInformazioniUvSezione.bind(this)}
                                modalitaVisualizzazioneAlternativa={this.props.modalitaVisualizzazioneAlternativa}/>
                        </IfContainer>
                    </div>
                </IfContainer>
            </Fragment>
        )
    }

    public render() {
        return (
            createPortal(
                <Fragment>{
                    this.state.larghezzaSchermo < Config.TabletBkp ?
                        <div className={"TiciNavigatorContainer"}>{this._menuRadiale()}</div> : this._menuRadiale()
                }</Fragment>,
                window.document.body
            )
        );
    }
}
