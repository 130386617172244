//QG 30/06/2024 MD

import React from "react";
import {ModelloConfigurabile} from "../../../../../Primary/GestioneModelli/ModelloConfigurabile";
import SezioneConfigurabile from "../../../../../Primary/GestioneModelli/SezioneConfigurabile";
import {ConfigurazioneSezioneInterface, ImmagineAmmessaEnum} from "tici_commons";

export interface ConfiguraSezioneTopLevelProps {
    modelloConfigurabile: ModelloConfigurabile,
    sezioniConfigurabili: SezioneConfigurabile[]
}

export interface ConfiguraSezioneTopLevelState {
    sezioneSelezionata: string,
    sezioneConfigurabileSelezionata: SezioneConfigurabile
}

export default class ConfiguraSezioneTopLevel<P extends ConfiguraSezioneTopLevelProps = ConfiguraSezioneTopLevelProps, S extends ConfiguraSezioneTopLevelState= ConfiguraSezioneTopLevelState> extends React.Component<P, S>{
    constructor(props: Readonly<P> | (P)) {
        super(props);
        this.state = {
            sezioneSelezionata: '',
            sezioneConfigurabileSelezionata: undefined
        } as any;
    }

    protected SetStatePromise(newState: Pick<S, keyof ConfiguraSezioneTopLevelState>): Promise<void>{
        return new Promise(resolve => {
            this.setState(newState, () => resolve());
        });
    }

    protected ImpostaValoreConfigurazioneSezione<T extends keyof ConfigurazioneSezioneInterface>(chiaveValore: T, valore: ConfigurazioneSezioneInterface[T], resetFlag = false){
        if(this.state.sezioneSelezionata){
            const sezione = this.props.sezioniConfigurabili.find(sezione => sezione.configurazioneSezione.nomeReale === this.state.sezioneSelezionata);

            if(sezione){
                const altreSezioni = this.props.sezioniConfigurabili.filter(sezioneTarget => sezioneTarget.configurazioneSezione.nomeReale !== sezione.configurazioneSezione.nomeReale);
                if(resetFlag){
                    sezione.configurazioneSezione.sezioneNonVisibileFlag = false;
                    sezione.configurazioneSezione.sezioneLegnoFlag = false;
                    sezione.configurazioneSezione.sezionePlexFlag = false;
                    sezione.configurazioneSezione.sezioneColoreFlag = false;
                    sezione.configurazioneSezione.singolaImmagineName = "";
                    sezione.configurazioneSezione.singolaImmagineBase64 = "";
                }

                if(chiaveValore === 'immagineAmmessa' && valore !== ImmagineAmmessaEnum.IMMAGINE)
                    sezione.configurazioneSezione.sezioneImmaginePrincipale = false;
                if(chiaveValore === 'sezioneImmaginePrincipale' && valore === true)
                    altreSezioni.forEach(sezioneTarget => sezioneTarget.configurazioneSezione.sezioneImmaginePrincipale = false);
                if(chiaveValore === 'targetRidimensione' && valore === true) {
                    altreSezioni.forEach(sezioneTarget => sezioneTarget.configurazioneSezione.targetRidimensione = false);
                    sezione.configurazioneSezione['sezioneRidimensionabile'] = true;
                }

                sezione.configurazioneSezione[chiaveValore] = valore;
                sezione.update();
                this.setState({sezioneConfigurabileSelezionata: sezione});
            }
        }
    }

    protected RecuperaListaGruppiVincolanti(): string[]{
        return Array.from(
            new Set(this.props.sezioniConfigurabili
                .filter(sezione => sezione.configurazioneSezione.nomeGruppo.length !== 0)
                .map(sezione => sezione.configurazioneSezione.nomeGruppo))
        );
    }

    protected ListaSezioniConfigurabili(): {label: string}[]{
        return this.props.sezioniConfigurabili.map(sezione => ({label: sezione.configurazioneSezione.nomeReale}));
    }

    protected ListaSezioniTopLevel(escludiSelezionata = false): {label: string}[] {
        const esito: string[] = [];
        for(const sezioneTarget of this.props.sezioniConfigurabili){
            let contenuta = false;
            this.props.sezioniConfigurabili.forEach(sezioneConfigurabile => {
                if(sezioneConfigurabile.configurazioneSezione.sezioniContenute.includes(sezioneTarget.configurazioneSezione.nomeReale))
                    contenuta = true;
            })
            if(!contenuta && !sezioneTarget.configurazioneSezione.sezioneNonVisibileFlag)
                esito.push(sezioneTarget.configurazioneSezione.nomeReale);
        }

        return esito.filter(sezioneTopLevel => escludiSelezionata ? sezioneTopLevel !== this.state.sezioneSelezionata : true).map(sezioneTopLevel => ({label: sezioneTopLevel}));
    }

    protected async SelezionaSezioneConfigurabile(nomeSezione: string, mostraSezioneSuperficie = false, mostraGiuntiCollegamento = false){
        if(this.state.sezioneConfigurabileSelezionata) {
            const sezioneConfigurabile = this.state.sezioneConfigurabileSelezionata;
            sezioneConfigurabile.resetVisualizzazioni();
        }
        const nuovaSezioneSelezionata = this.props.sezioniConfigurabili.find(sezione => sezione.configurazioneSezione.nomeReale === nomeSezione);
        if(nuovaSezioneSelezionata){
            nuovaSezioneSelezionata.elementoSelezionatoPerModifica = true;
            nuovaSezioneSelezionata.visualizzaSezioneSuperficieInModifica = mostraSezioneSuperficie;
            nuovaSezioneSelezionata.visualizzaGiuntiCollegamento = mostraGiuntiCollegamento;
            nuovaSezioneSelezionata.update();

            await this.SetStatePromise({
                sezioneConfigurabileSelezionata: nuovaSezioneSelezionata,
                sezioneSelezionata: nomeSezione
            })
        }
    }
}
