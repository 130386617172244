import React, {Fragment} from "react";
import MultiSelettore, {OverlayImmagineInterface, PlaceholderInterface} from "../../MultiSelettore/MultiSelettore";
import {StorageData} from "../../../Pages/SitoInterno/Configuratore/Storage/StorageManager";
import IfContainer from "../../../Layout/IfContainer/IfContainer";
import {SelectLine, TextLine} from "../../../Widgets/Configuratore/CComponents";
import {CartaAlternativa, LavorazioneConAggiunteModel} from "tici_commons";
import Button from "../../../Core/Buttons/Button";
import ResponsiveLabel from "../../../Core/ResponsiveLabel/ResponsiveLabel";
import Responsive2Col from "../../../Layout/Responsive2Col/Responsive2Col";
import DADMOverlay from "./DADMOverlay";

export interface DADMIndexProps {
    immagini: StorageData[],
    immaginiLavorazioni?: StorageData[],
    soloVista?: boolean
    posizioniSelezionate?: string[],
    aggiornaPosizioniSelezionate?: (posizioni: string[]) => void,
    modificheCartaExtra?: boolean,
    modificheLavorazioni?: boolean,
    cartoncini?: [number, string][],
    cartaExtra?: [string, string][],
    lavorazioni?: [string, string][],
    lavorazioniConAggiunte?: [string, LavorazioneConAggiunteModel][],
    quantitaFogliComplessivi?: number,
    formatoAlbumPrincipale?: string,
    aggiungiCartoncino?: (posizioneCartoncino: number, nomeCartoncino: string) => void,
    onDeleteCartaExtra?: (nomeFoglio: string) => void,
    onDeleteLavorazione?: (nomeFoglio: string) => void
}

export interface DADMIndexState {
    cartoncinoSelezionato: string,
    cartoncinoPosizioneSelezionata: number,
}

export default class DADMIndex extends React.Component<DADMIndexProps, DADMIndexState>{
    constructor(props: Readonly<DADMIndexProps> | DADMIndexProps) {
        super(props);
        this.state = {
            cartoncinoSelezionato: "",
            cartoncinoPosizioneSelezionata: 1
        }
    }

    /**
     * Recupera il colore del cartonicino
     * @param nomeCartoncino Nome del cartoncino
     * @private
     */
    private _getColoreCartoncino(nomeCartoncino: string): string{
        let esito = 'black';

        const cartoncino = CartaAlternativa.find(cartaExtra => cartaExtra.nomeCarta === nomeCartoncino);
        if(cartoncino){
            esito = cartoncino.coloreRgb;
        }

        return esito;
    }

    /**
     * Genera i vari placeholder per i cartoncini
     * @private
     */
    private _generaPlaceHolderSelettore(): PlaceholderInterface[]{
        const esito: PlaceholderInterface[] = [];

        if(this.props.modificheCartaExtra){
            for(const cartoncino of this.props.cartoncini){
                esito.push({
                    colore: this._getColoreCartoncino(cartoncino[1]),
                    posizione: cartoncino[0],
                    formato: this.props.formatoAlbumPrincipale
                });
            }

            if(this.state.cartoncinoSelezionato){
                esito.push({
                    colore: this._getColoreCartoncino(this.state.cartoncinoSelezionato),
                    posizione: this.state.cartoncinoPosizioneSelezionata,
                    formato: this.props.formatoAlbumPrincipale
                })
            }

        }

        return esito;
    }

    /**
     * Gestisce l'evento di aggiunta del cartoncino
     * @private
     */
    private _aggiungiCartoncinoHandler(){
        if(this.state.cartoncinoSelezionato && this.props.aggiungiCartoncino){
            this.props.aggiungiCartoncino(this.state.cartoncinoPosizioneSelezionata, this.state.cartoncinoSelezionato);
            this.setState({cartoncinoPosizioneSelezionata: 1, cartoncinoSelezionato: ""})
        }
    }

    /**
     * Genera l'array per il controllo dell'esclusione
     * @private
     */
    private _generaArrayControlloEsclusione(): string[]{
        const cartaExtraArray = this.props.cartaExtra && this.props.modificheCartaExtra ? this.props.cartaExtra.map(cartaExtra => cartaExtra[0]) : [];

        let lavorazioniArray = this.props.lavorazioni && this.props.modificheLavorazioni ? this.props.lavorazioni.map(lavorazione => lavorazione[0]) : [];
        lavorazioniArray.push(...(this.props.lavorazioniConAggiunte && this.props.modificheLavorazioni ? this.props.lavorazioniConAggiunte.map(lavorazioneConAggiunte => lavorazioneConAggiunte[0]) : []));
        lavorazioniArray = Array.from(new Set(lavorazioniArray));

        let esito: string[] = [];
        if(this.props.modificheCartaExtra && !this.props.modificheLavorazioni){
            esito = cartaExtraArray;
        }else if(this.props.modificheLavorazioni && !this.props.modificheCartaExtra){
            esito = lavorazioniArray;
        }else if(this.props.modificheLavorazioni && this.props.modificheCartaExtra){
            esito = cartaExtraArray.filter(cartaExtra => !!lavorazioniArray.find(lavorazione => lavorazione === cartaExtra));
        }

        return esito;
    }

    /**
     * Effettua la generazione degli overlay per le immagini
     * @private
     */
    private _generaOverlayImmagini(): OverlayImmagineInterface[]{
        let esito: OverlayImmagineInterface[] = [];

        let cartaExtraArray = this.props.modificheCartaExtra && this.props.cartaExtra ? this.props.cartaExtra : [];
        let lavorazioniArray: [string, string][] = [];
        if(this.props.modificheLavorazioni){
            const lavorazioni: [string, string][] = this.props.lavorazioni ? this.props.lavorazioni.map(lavorazione => [lavorazione[0], lavorazione[1]]) : [];
            const lavorazioniConAggiunte: [string, string][] = this.props.lavorazioniConAggiunte ? this.props.lavorazioniConAggiunte.map(lavorazione => [lavorazione[0], lavorazione[1].nomeLavorazione]) : [];
            lavorazioniArray.push(...lavorazioni, ...lavorazioniConAggiunte);
        }

        if(this.props.modificheLavorazioni || this.props.modificheCartaExtra){
            for(const immagine of this.props.immagini){
                const cartaExtra = cartaExtraArray.find(cartaExtra => cartaExtra[0] === immagine.name)?.[1];
                const lavorazione = lavorazioniArray.find(lavorazione => lavorazione[0] === immagine.name)?.[1];

                if(cartaExtra || lavorazione){
                    esito.push(
                        {
                            nomeImmagine: immagine.name,
                            overlayElement: <DADMOverlay
                                nomeFoglio={immagine.name}
                                cartaExtra={cartaExtra}
                                lavorazione={lavorazione}
                                nascondiEliminazione={this.props.soloVista}
                                onDeleteCartaExtra={this.props.onDeleteCartaExtra}
                                onDeleteLavorazione={this.props.onDeleteLavorazione}/>
                        }
                    )
                }
            }
        }

        return esito;
    }

    private _SezioneGestioneCartoncini(){
        return (
            <Fragment>
                <ResponsiveLabel
                    content={"Gestisci cartoncini"}
                    type={"medium"}
                    alignment={"center"}/>
                <SelectLine
                    label={"Seleziona cartoncino"}
                    placeHolder={"Seleziona"}
                    elements={CartaAlternativa.filter(cartaExtra => cartaExtra.cartoncinoFlag).map(cartaExtra => ({label: cartaExtra.nomeCarta}))}
                    value={this.state.cartoncinoSelezionato}
                    onChange={v => this.setState({cartoncinoSelezionato: v})}/>
                <IfContainer condition={!!this.state.cartoncinoSelezionato}>
                    <TextLine
                        label={"Posizione cartoncino"}
                        type={"number"}
                        min={1}
                        max={this.props.quantitaFogliComplessivi ? this.props.quantitaFogliComplessivi + 1 : 1}
                        value={this.state.cartoncinoPosizioneSelezionata.toString()}
                        onChange={v => {this.setState({cartoncinoPosizioneSelezionata: isNaN(parseInt(v)) ? 1 : parseInt(v)})}}/>
                    <Responsive2Col>
                        <Button
                            content={"Inserisci cartoncino"}
                            type={"medium"}
                            buttonType={"full-normal"}
                            onClick={this._aggiungiCartoncinoHandler.bind(this)}/>
                        <Button
                            content={"Annulla"}
                            type={"medium"}
                            buttonType={"full-normal-negative"}
                            onClick={() => this.setState({cartoncinoSelezionato: "", cartoncinoPosizioneSelezionata: 1})}/>
                    </Responsive2Col>
                </IfContainer>
            </Fragment>
        )
    }

    public get immaginiIntegrateConLavorazioni(): (StorageData | StorageData[])[]{
        const esito: (StorageData | StorageData[])[] = [];

        for(const immagine of this.props.immagini){
            let immagineIntegrata: StorageData | StorageData[] = immagine;
            if(this.props.immaginiLavorazioni){
                const immagineAssociate: StorageData[] = this.props.immaginiLavorazioni.filter(immagineLavorazione => immagineLavorazione.category.includes(immagine.name));
                if(immagineAssociate && immagineAssociate.length > 0)
                    immagineIntegrata = [immagine, ...immagineAssociate];
            }
            esito.push(immagineIntegrata);
        }

        return esito
    }

    public render() {
        const arrayControlloEsclusione = this._generaArrayControlloEsclusione();

        return (
            <Fragment>
                <MultiSelettore
                    immagini={this.immaginiIntegrateConLavorazioni}
                    posizioniSelezionate={this.props.posizioniSelezionate}
                    onChange={posizioniSelezionate => {
                        !this.props.soloVista && this.props.aggiornaPosizioniSelezionate && this.props.aggiornaPosizioniSelezionate(posizioniSelezionate);
                    }}
                    posizioniEscluse={this.props.immagini.filter(immagine => arrayControlloEsclusione.includes(immagine.name)).map(immagine => immagine.name)}
                    placeholder={this._generaPlaceHolderSelettore()}
                    overlayImmagini={this._generaOverlayImmagini()}/>
                <IfContainer condition={!this.props.soloVista && this.props.modificheCartaExtra && this.props.immagini.length > 0}>
                    {this._SezioneGestioneCartoncini()}
                </IfContainer>
            </Fragment>
        );
    }
}
