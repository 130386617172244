//QG 30/06/2024 MD

import {
    ConfigurazioneModelloInterface,
    ConfigurazioneSezioneInterface
    , SezioneGenericaModel,
    Triple
} from "tici_commons";
import {
    Intersection,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    SphereGeometry,
    Vector3,
} from "three";
import ElementoRenderizzabile from "./ElementoRenderizzabile";
import {ModelloConfigurabile} from "./ModelloConfigurabile";
import {RenderObjectUserData} from "./ElementoConfigurabile";

export default class SezioneConfigurabile extends ElementoRenderizzabile{
    private _sezioniConfigurabili: SezioneConfigurabile[];

    private _meshGiuntiCollegamento: Mesh[] = [];
    private _visualizzaGiuntiCollegamento = false;

    constructor(
        renderObject: Object3D,
        configurazioneSezione: ConfigurazioneSezioneInterface,
        configurazioneModello: ConfigurazioneModelloInterface,
        modelloConfigurabileContenitore: ModelloConfigurabile) {
        super(configurazioneSezione.nomeReale, renderObject, configurazioneSezione, configurazioneModello, modelloConfigurabileContenitore);
    }

    public update(){
        this.animationPivot.objectOffset.set(
            this.configurazioneSezione.offsetGiuntoRotazione.x,
            this.configurazioneSezione.offsetGiuntoRotazione.y,
            this.configurazioneSezione.offsetGiuntoRotazione.z * this.scalaAsseZ,
        );
        
        if(this.modalitaSupportoVisivo){
            this._rimuoviGiuntiCollegamento();
            this._visualizzaGiuntiCollegamento && this._aggiungiGiuntiCollegamento();
        }

        super.update(); //Facciamo prima per dare la possibilità di calcolare le nuove posizioni prima dell'aggiornamento
        this._aggiornaGiuntiCollegamento();
    }

    //region Gestione Giunti Collegamento

    private _createMeshCollegamento(position: Triple): Mesh{
        const meshCollegamento = new Mesh(
            new SphereGeometry(),
            new MeshBasicMaterial({
                color: 0x0000ff,
                depthTest: false
            }));
        meshCollegamento.renderOrder = 999;
        (meshCollegamento.userData as RenderObjectUserData).escludiBounding = true;
        meshCollegamento.position.set(position.x, position.y, position.z);
        return meshCollegamento
    }

    private _rimuoviGiuntiCollegamento(){
        for(const meshGiunto of this._meshGiuntiCollegamento)
            meshGiunto.removeFromParent();
        this._meshGiuntiCollegamento = [];
    }

    private _aggiungiGiuntiCollegamento(){
        for(const giunto of this.configurazioneSezione.sezioniCollegate) {
            const mesh = this._createMeshCollegamento(giunto.giunto)
            this._meshGiuntiCollegamento.push(mesh);
        }
        if(this.renderObject && this._meshGiuntiCollegamento.length !== 0) {
            this.renderObject.add(...this._meshGiuntiCollegamento);
        }
    }

    private _aggiornaGiuntiCollegamento(){
        if(this._sezioniConfigurabili && this.configurazioneSezione.sezioniCollegate.length > 0){
            for(const collegamento of this.configurazioneSezione.sezioniCollegate){
                const sezioneCollegata =
                    this._sezioniConfigurabili.find(sezione => sezione.configurazioneSezione.nomeReale === collegamento.sezione);
                if(sezioneCollegata && this.renderObject && sezioneCollegata.parent){
                    const worldPositionGiunto = this.renderObject.localToWorld(new Vector3(collegamento.giunto.x, collegamento.giunto.y, collegamento.giunto.z));
                    const localPositionSezione = sezioneCollegata.parent.worldToLocal(worldPositionGiunto.clone());

                    sezioneCollegata.materialeInput = this.materialeRenderObject;
                    sezioneCollegata.movimentoPivot.rotationVector.set(
                        this.movimentoPivot.rotationVector.x + this.animationPivot.rotationVector.x,
                        this.movimentoPivot.rotationVector.y + this.animationPivot.rotationVector.y,
                        this.movimentoPivot.rotationVector.z + this.animationPivot.rotationVector.z
                    );
                    sezioneCollegata.movimentoPivot.positionVector.set(localPositionSezione.x, localPositionSezione.y, localPositionSezione.z);
                    sezioneCollegata.update();
                }
            }
        }
    }

    //endregion

    //region UtilityPosizionamentoGiuntoRotazione

    public calcolaPosizioneGiuntoRotazioneUtility(asse: 'X' | 'Y' | 'Z', posizionamento: 1 | 2 | 3): number{
        let esito = 0;

        const mesh = this.renderObject as Mesh;
        mesh.geometry.computeBoundingBox();
        const boundingBox = mesh.geometry.boundingBox;

        const size = boundingBox.getSize(new Vector3());
        const center = boundingBox.getCenter(new Vector3());

        let targetSize = 0;
        let targetCenter  = 0;
        switch(asse){
            case "X":
                targetSize = size.x;
                targetCenter = center.x;
                break;
            case "Y":
                targetSize = size.y;
                targetCenter = center.y;
                break;
            case "Z":
                targetSize = size.z;
                targetCenter = center.z;
                break;
        }

        switch (posizionamento){
            case 1:
                esito = -targetCenter + (targetSize * 0.5);
                break;
            case 2:
                esito = -targetCenter;
                break;
            case 3:
                esito = -targetCenter - (targetSize * 0.5);
                break;
        }

        return esito;
    }

    //endregion

    public resetVisualizzazioni(){
        this._visualizzaGiuntiCollegamento = false;
        super.resetVisualizzazioni();
    }

    public onCollisionWithMouseDetection(collision: Intersection) {
        if(!this.configurazioneSezione.nomeSezioneRiferimentoInformazioni)
            super.onCollisionWithMouseDetection(collision);
    }


    public aggiornaInformazioneBase(datoSezione: SezioneGenericaModel) {
        super.aggiornaInformazioneBase(datoSezione);
        this._sezioniConfigurabili
            .filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeSezioneRiferimentoInformazioni === this.configurazioneSezione.nomeReale)
            .forEach(sezioneConfigurabile => {
                sezioneConfigurabile.datiSezioneCollegati = true;
                sezioneConfigurabile.aggiornaInformazioneBase(datoSezione);
            });
    }

    //region Beam

    public get uuidCollisionList(): string[]{
        const uuidSezioniConRiferimento = this._sezioniConfigurabili
            .filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeSezioneRiferimentoInformazioni === this.configurazioneSezione.nomeReale)
            .map(sezioneConfigurabile => sezioneConfigurabile.renderObject.uuid);
        return [...uuidSezioniConRiferimento, ...super.uuidCollisionList];
    }

    public get elementiSelezionatiConElemento(): Object3D[]{
        return this._sezioniConfigurabili
            .filter(sezioneConfigurabile => sezioneConfigurabile.configurazioneSezione.nomeSezioneRiferimentoInformazioni === this.configurazioneSezione.nomeReale)
            .map(sezioneConfigurabile => sezioneConfigurabile.renderObject);
    }

    public set sezioniConfigurabili(sezioniConfigurabili: SezioneConfigurabile[]){
        this._sezioniConfigurabili = sezioniConfigurabili;
    }

    public get meshGiuntiCollegamento(): Mesh[] {
        return this._meshGiuntiCollegamento;
    }

    public get visualizzaGiuntiCollegamento(): boolean {
        return this._visualizzaGiuntiCollegamento;
    }

    public set visualizzaGiuntiCollegamento(value: boolean) {
        this._visualizzaGiuntiCollegamento = value;
    }

    public get sezioniConfigurabili(): SezioneConfigurabile[] {
        return this._sezioniConfigurabili;
    }

//endregion
}
