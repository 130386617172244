import React from "react";
import {StorageManagerStatus} from "../../../Storage/StorageManager";
import IfContainer from "../../../../../../Layout/IfContainer/IfContainer";
import {
    GenerateGroup, GenerateRiepilogoData,
    GenerateRiepilogoInternalBox,
    RiepilogoBox,
    RiepilogoData,
    RiepilogoInternalBox
} from "../../../Commons/RiepilogoDatoModel";
import SezioneGenerica from "../../SezioneGenerica";
import {FotolibroModel} from "tici_commons";
import CategorySectionLayout from "../../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import ResponsiveLabel from "../../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import LocalStorageService from "../../../../../../Servicies/LocalStorageService";
import ManagerRenderizzatoreModelli from "../../../../../../Primary/ManagerRenderizzatoreModelli/ManagerRenderizzatoreModelli";
import SezioneConfigurabile from "../../../../../../Primary/GestioneModelli/SezioneConfigurabile";
import SezioneRequiredProps from "../../../Commons/SezioneRiquiredProps";
import {FotolibroContextInterface} from "../../../Fotolibro/Fotolibro";
import {
    GeneraListaSezioniObbligatorieCompilate,
    GeneraMessaggioSezioniNonCompilate,
    GeneraRiepilogoSezioni
} from "../../../Commons/Utils";
import Formati from "../../../../../../DatabaseData/Formati";

export interface SezioneAlbumRidottoCopertinaState {
    sezioniObbligatorie: string[],
    sezioniConfigurabili: SezioneConfigurabile[],
}

export interface SezioneAlbumRidottoCopertinaProps {
    orientamentoAlbumPrincipale: string,
    formatoAlbumPrincipale: string
}

export default class SezioneAlbumRidottoCopertina extends SezioneGenerica<"copertina", SezioneAlbumRidottoCopertinaProps, SezioneAlbumRidottoCopertinaState>{
    public constructor(props: SezioneAlbumRidottoCopertinaProps & SezioneRequiredProps<"copertina"> & {
        data: FotolibroContextInterface["copertina"];
        storage: StorageManagerStatus
    }, context: any) {
        super(props, context);
        this.state = {
            sezioniObbligatorie: [],
            sezioniConfigurabili: []
        }
    }

    generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        const sezioniObbligatorie = GeneraListaSezioniObbligatorieCompilate(fotolibro.copertina.sezioniCopertinaAlbumRidotto, LocalStorageService.SezioniObbligatorieCopertinaRidotti);

        return GenerateRiepilogoInternalBox(
            'Copertina album ridotto',
            ...GenerateGroup(
                fotolibro.informazioniBase.albumRidottoFlag && fotolibro.informazioniBase.albumRidottoQuantita > 0,
                GenerateRiepilogoData(
                    undefined,
                    "Alcune sezioni non sono state compilate",
                    true,
                    storage && sezioniObbligatorie.length < LocalStorageService.SezioniObbligatorieCopertinaRidotti.length
                ),
                ...GeneraRiepilogoSezioni(fotolibro.copertina.sezioniCopertinaAlbumRidotto)
            )
        );
    }

    public render() {
        return (
            <CategorySectionLayout categoryLabel={"Album ridotto"} size={"largeRelative"} center={true}>
                <IfContainer condition={!!GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCopertinaAlbumRidotto)}>
                    <ResponsiveLabel
                        content={GeneraMessaggioSezioniNonCompilate(this.state.sezioniConfigurabili, this.$.sezioniCopertinaAlbumRidotto)}
                        type={"medium"}
                        alignment={"left"}
                        labelType={"errorLabel"}/>
                </IfContainer>
                <ManagerRenderizzatoreModelli
                    modalitaVisualizzazioneAlternativa={this.props.orientamentoAlbumPrincipale.toLowerCase() === 'panoramico'}
                    intercettaModificaVisualizzazione={true}
                    intercettaEventiClick={true}
                    aspectRatioModello={Formati.GetAspectRatioFromFormatoString(this.props.formatoAlbumPrincipale)}
                    nomeManager={"CopertinaRidotto"}
                    datiSezioni={this.$.sezioniCopertinaAlbumRidotto}
                    nomeModelloSelezionato={"1000"}
                    onDatiSezioniChange={sezioniCopertina => this.$.sezioniCopertinaAlbumRidotto = sezioniCopertina}
                    onModelloInizializzato={(modelloConfigurabile, sezioniConfigurabili) => {
                        const sezioniObbligatorie: string[] = [];
                        sezioniConfigurabili.forEach(sezioneConfigurabile => {
                            sezioneConfigurabile.aggiornaVisualizzazioneModello(this.$.sezioniCopertinaAlbumRidotto, this.props.storage.GetStorage());
                            if(sezioneConfigurabile.configurazioneSezione.sezioneObbligatoriaFlag)
                                sezioniObbligatorie.push(sezioneConfigurabile.configurazioneSezione.nomeReale);
                        });
                        LocalStorageService.SezioniObbligatorieCopertinaRidotti = sezioniObbligatorie;
                        this.setState({sezioniObbligatorie, sezioniConfigurabili});
                    }}
                />
            </CategorySectionLayout>
        );
    }
}
