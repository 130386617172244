import React, {Fragment} from "react";
import SectionLayout from "../../../../../Layout/SectionLayout/SectionLayout";
import ResponsiveLabel from "../../../../../Core/ResponsiveLabel/ResponsiveLabel";
import IfContainer from "../../../../../Layout/IfContainer/IfContainer";
import Button from "../../../../../Core/Buttons/Button";
import {FotolibroContextInterface} from "../../Fotolibro/Fotolibro";
import {ConfigurazioneService, } from "../../../../../Servicies/ConfigurazioneService";
import {FotolibroModel, TipoConfigurazioneEnum} from "tici_commons";
import {ImmagineMiniatura} from "../../../../../Widgets/Configuratore/CComponents";
import InformazioniBase from "../InformazioniBase/InformazioniBase";
import Copertina from "../Copertina/Copertina";
import Custodia from "../Custodia/Custodia";
import Accessori from "../Accessori/Accessori";
import RenderizzatoreRiepilogo from "./RenderizzatoreRiepilogo";
import {StorageManagerStatus} from "../../Storage/StorageManager";
import Responsive2Col from "../../../../../Layout/Responsive2Col/Responsive2Col";
import LocalStorageService from "../../../../../Servicies/LocalStorageService";
import TopLevelComponentStorage from "../../../../../Core/Arch/TopLevelComponentStorage";
import RedirectService from "../../../../../Servicies/RedirectService";
import {RiepilogoBox, RiepilogoData, RiepilogoInternalBox} from "../../Commons/RiepilogoDatoModel";
import SezioneCoordinato, {CoordinatoData} from "./SezioneCoordinato";
import Rilegatura from "../Rilegatura/Rilegatura";
import SezioneGenerica from "../SezioneGenerica";
import SezioneRequiredProps from "../../Commons/SezioneRiquiredProps";
import UserService from "../../../../../Servicies/UserService";
import CategorySectionLayout from "../../../../../Layout/CategorySectionLayout/CategorySectionLayout";
import {filtraFotolibro} from "../../../../../Utils/CommonFunctions";

export interface RiepilogoConfiguratoreTiciProps{
    fotolibro: FotolibroModel,
    idConfigurazioneAperta?: number,
    datiCoordinatoAperto?: CoordinatoData
}

export interface RiepilogoConfiguratoreTiciState{
    coordinatoData: CoordinatoData,
    superUser: boolean
}

export default class RiepilogoConfiguratoreTici extends SezioneGenerica<"informazioniBase", RiepilogoConfiguratoreTiciProps, RiepilogoConfiguratoreTiciState>{
    constructor(props: RiepilogoConfiguratoreTiciProps & SezioneRequiredProps<"informazioniBase"> & { data: FotolibroContextInterface["informazioniBase"]; storage: StorageManagerStatus }) {
        super(props);
        this.state = {
            coordinatoData: {
                modalitaCoordinato: false,
                tipologiaCoordinato: 1,
                vincoliFormato: [],
                ...(this.props.datiCoordinatoAperto?? {})
            },
            superUser: false
        }
        UserService.IsSuperUser().then(response => this.setState({superUser: response}));
    }

    public componentDidUpdate(prevProps: Readonly<RiepilogoConfiguratoreTiciProps>, prevState: Readonly<RiepilogoConfiguratoreTiciState>, snapshot?: any) {
        if(JSON.stringify(prevProps.datiCoordinatoAperto) !== JSON.stringify(this.props.datiCoordinatoAperto))
            this.setState(prevState => ({coordinatoData: {...prevState.coordinatoData, ...this.props.datiCoordinatoAperto}}));
    }

    public componentDidMount() {
        this.setState(prevState => ({coordinatoData: {...prevState.coordinatoData, ...this.props.datiCoordinatoAperto}}));
    }

    /**
     * Salva la configurazione sul database
     * @param configurazionePersonale Descrive se la configurazione è personale oppure no
     * @private
     */
    private async _salvaConfigurazione(configurazionePersonale = true){
        const salvaConfigurazione = async () => {
            TopLevelComponentStorage.GetTopLevel('loadingWindow')
                .showLoadingWindow("Salvataggio", "Stiamo salvando questa configurazione");

            const esito = await ConfigurazioneService.AddConfigurazione(
                this.state.coordinatoData.modalitaCoordinato ? this.state.coordinatoData.tipologiaCoordinato : (configurazionePersonale ? TipoConfigurazioneEnum.Utente : TipoConfigurazioneEnum.Cliente),
                this.props.fotolibro.informazioniBase.nomeProgetto,
                {...filtraFotolibro(this.props.fotolibro), currentSection: undefined} as any,
                this.state.coordinatoData
            );
            TopLevelComponentStorage.GetTopLevel('loadingWindow')
                .hideLoadingWindow();
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Salvataggio",
                esito ? "Configurazione salvata correttamente" : "Non è stato possibile salvare la configurazione",
                "SingleButton",
                () => RedirectService.GoToConfigurazioniSalvate()
            )
        }

        if(this.props.idConfigurazioneAperta !== undefined) {
            TopLevelComponentStorage.GetTopLevel('confirmWindow').showConfirmWindow(
                "Sovrascrittura",
                "Stai sovrascrivendo questa configurazione esistente?",
                "DoubleButton",
                async () => {
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                    await ConfigurazioneService.DeleteConfigurazione(this.props.idConfigurazioneAperta);
                    salvaConfigurazione();
                },
                () => {
                    TopLevelComponentStorage.GetTopLevel('confirmWindow').hideConfirmWindow();
                    salvaConfigurazione();
                }
            )
        }else salvaConfigurazione();
    }

    private _SezioneTitolo(){
        return (
            <SectionLayout size={"mediumRelative"} center={true}>
                <ResponsiveLabel
                    content={"Salva configurazione"}
                    type={"large"}
                    alignment={"center"}/>
                <ResponsiveLabel
                    content={"Le informazioni salvate verranno riportate esattamente al momento del caricamento così come gli errori visualizzati"}
                    type={"medium"}
                    alignment={"center"}/>
            </SectionLayout>
        )
    }

    private _SezioneCoordinato(){
        return(
            <IfContainer condition={
                this.$.custodiaFlag &&
                this.props.fotolibro.custodia.modelloCustodia.length !== 0 &&
                this.state.superUser
            }>
                <SezioneCoordinato
                    fotolibro={this.props.fotolibro}
                    coordinatoData={this.state.coordinatoData}
                    updateCoordinatoData={coordinatoData => this.setState({coordinatoData: coordinatoData})}/>
            </IfContainer>
        )
    }

    private _SezioneInvio(){
        return (
            <SectionLayout size={"largeRelative"} center={true}>
                <IfContainer condition={this.$.nomeProgetto.length === 0}>
                    <ResponsiveLabel
                        content={"Bisogna inserire il nome della configurazione del progetto"}
                        type={"medium"}
                        labelType={"errorLabel"}
                        alignment={"center"}/>
                </IfContainer>
                <Responsive2Col>
                    <Button
                        content={"Salva configurazione personale"}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        disabled={this.$.nomeProgetto.length === 0}
                        onClick={() => {
                            this._salvaConfigurazione();
                        }}/>
                    <Button
                        content={"Salva configurazione cliente"}
                        type={"large"}
                        alignment={"center"}
                        buttonType={"full-normal"}
                        disabled={this.$.nomeProgetto.length === 0}
                        onClick={() => {
                            this._salvaConfigurazione(false);
                        }}/>
                </Responsive2Col>

            </SectionLayout>
        )
    }

    public render() {
        return (
            <Fragment>
                {this._SezioneCoordinato()}
                <CategorySectionLayout categoryLabel={"Riepilogo configurazione"} size={"largeRelative"} center={true}>
                    {this._SezioneTitolo()}
                    <RenderizzatoreRiepilogo
                        modalitaConfiguratore={true}
                        riepiloghi={[
                            new InformazioniBase(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, true) as RiepilogoBox,
                            new Rilegatura(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, true) as RiepilogoBox,
                            new Copertina(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, true) as RiepilogoBox,
                            new Custodia(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, true) as RiepilogoBox,
                            ...(new Accessori(undefined).generaRiepilogo(this.props.fotolibro, this.props.storage, false) as RiepilogoBox[])
                        ]}/>
                    <IfContainer condition={!!LocalStorageService.CustodiaImmagineGenerata || !!LocalStorageService.CopertinaImmagineGenerata}>
                        <Responsive2Col>
                            <ImmagineMiniatura immagineData={LocalStorageService.CopertinaImmagineGenerata}/>
                            <IfContainer condition={this.props.fotolibro.informazioniBase.custodiaFlag}>
                                <ImmagineMiniatura immagineData={LocalStorageService.CustodiaImmagineGenerata}/>
                            </IfContainer>
                        </Responsive2Col>
                    </IfContainer>
                    {this._SezioneInvio()}
                </CategorySectionLayout>
            </Fragment>
        );
    }

    public generaRiepilogo(fotolibro: FotolibroModel, storage: StorageManagerStatus, modalitaConfiguratore: boolean): RiepilogoBox | RiepilogoBox[] | RiepilogoInternalBox | RiepilogoInternalBox[] | RiepilogoData[] {
        return undefined;
    }
}
