//QG 30/06/2024 MD

import React from "react";
import {SelectLine} from "../../../../Widgets/Configuratore/CComponents";

export type ConfiguratoreModelliMenuType =
    "Configura dimensionamento" |
    "Configura modello" |
    "Configura vista" |
    "Configura sezioni" |
    "Configura giunti" |
    "Configura animazioni" |
    "Test"

export interface ConfiguratoreModelliMenuProps {
    selectedCategory: ConfiguratoreModelliMenuType,
    onChangeCategory: (newCategory: ConfiguratoreModelliMenuType) => void
}

export default class ConfiguratoreModelliMenu extends React.Component<ConfiguratoreModelliMenuProps, any>{
    public render() {
        return (
            <SelectLine
                label={"Selezione configuratore"}
                placeHolder={"Seleziona"}
                elements={[
                    {label: 'Configura dimensionamento'},
                    {label: 'Configura modello'},
                    {label: 'Configura vista'},
                    {label: 'Configura sezioni'},
                    {label: 'Configura giunti'},
                    {label: 'Configura animazioni'},
                    {label: 'Test'},
                ]}
                value={this.props.selectedCategory}
                onChange={v => this.props.onChangeCategory && this.props.onChangeCategory(v as ConfiguratoreModelliMenuType)}/>
        )
    }
}
